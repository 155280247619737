@import "../../../styles/breakpoints";

.EasyChoose {
  height: 43px;
  margin-bottom: 40px;
  position: relative;
  @media (min-width: $breakpoint-tablet) {
    height: initial;
  }
  &.maximizedOnMobile {
    & .desktop {
      max-height: initial;
      overflow: initial;
      z-index: 2;
    }
    & .content {
      border-bottom: none;
      height: fit-content;
      background-color: var(--background-ternary);
      @media (min-width: $breakpoint-tablet) {
        background-color: initial;
        height: initial;
        border-bottom: 1px solid var(--easyChoose-border);
      }
    }
    & .active {
      border: none;
      @media (min-width: $breakpoint-tablet) {
        border-bottom: 3px solid var(--easyChoose-border-active);
      }
    }
    & .item {
      &:hover {
        background-color: var(--background-ternary-border);
        @media (min-width: $breakpoint-tablet) {
          background-color: initial;
        }
      }
    }
  }
  &-desktop {
    position: absolute;
    left: 0;
    right: 0;
    height: fit-content;
    max-height: 43px;
    overflow: hidden;

    @media (min-width: $breakpoint-tablet) {

      position: initial;
      height: initial;
      max-height: initial;
      overflow: initial;
    }

    &-content {
      align-items: center;
      height: 43px;
      @media (min-width: $breakpoint-tablet) {
        display: flex;
        border-bottom: 1px solid var(--easyChoose-border);
        height: 51px;
      }
      @media (min-width: $breakpoint-laptop) {
        height: 51px;
      }
      @media (min-width: $breakpoint-fullHD) {
        height: 53px;
      }
      &.isMobile {
        @media (min-width: $breakpoint-tablet) {
          display: none;
        }
      }
      &.isDesktop {
        display: none;
        @media (min-width: $breakpoint-tablet) {
          display: flex;
        }
      }
      &-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        padding: 13px 10px;
        @media (min-width: $breakpoint-tablet) {
          padding-bottom: 18px;
          font-size: 18px;
          color: var(--text-opacity);
        }
        @media (min-width: $breakpoint-laptop) {
          font-size: 18px;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 20px;
        }
        &:not(:last-child) {
          @media (min-width: $breakpoint-tablet) {
            margin-right: 40px;
          }
        }

        &.active {
          border-bottom: 3px solid var(--easyChoose-border-active);
          @media (min-width: $breakpoint-tablet) {
            color: var(--text-secondary);
          }
        }

      }
    }
  }
}