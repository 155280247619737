@import "../../../styles/breakpoints";

@mixin NavigationItem {
  &-link {
    text-decoration: none;
    margin-bottom: 10px;
    padding-left: 32px;
    height: calc(61px - 18px);
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 1px solid transparent;
    border-left: 3px solid transparent;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    @media (min-width: $breakpoint-laptop) {
      padding-left: 25px;
    }
    @media (min-width: $breakpoint-fullHD) {
      height: calc(70px - 18px);
      padding-left: 31px;
    }
    &.active {
      background-color: var(--background-ternary);
      border: 1px solid var(--background-ternary-border);
      border-left: 3px solid var(--navigation-border) !important;
      & .link-text {
        color: var(--background-ternary-text);
      }
      & img {
        filter: brightness(100);
      }
    }
    &-image {
      display: flex;
      align-items: center;
      margin-right: 15px;
      width: 27px;
      height: 27px;
      justify-content: center;
      @media (min-width: $breakpoint-laptop) {
        width: 20px;
        height: 20px;
      }
      @media (min-width: $breakpoint-fullHD) {
        width: 25px;
        height: 25px;
      }
      & img {
        width: 100%;
        height: 100%;
      }
    }
    &-badge {
      margin-left: 26px;
      @media (min-width: $breakpoint-laptop) {
        margin-left: 29px;
      }
      @media (min-width: $breakpoint-fullHD) {
        margin-left: 45px;
      }
    }
    &-text {
      display: flex;
      align-items: center;
      color: var(--text-primary);
      font-weight: 500;
      font-size: 16px;
      @media (min-width: $breakpoint-tablet) {
        font-size: 18px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 16px;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 18px;
      }
    }
  }
  &:hover {
    & .link {
      background-color: var(--background-ternary);
      border: 1px solid var(--background-ternary-border);
      border-left: 3px solid transparent;
    }
    & .link-text {
      color: var(--background-ternary-text);
    }
  }
}

.NavigationItem {
  @include NavigationItem();
}
