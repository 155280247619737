@import "../../../styles/breakpoints";

.Pagination {
  height: 20px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  @media (min-width: $breakpoint-laptop) {
    justify-content: center;
  }
  &-left {
    font-size: 14px;
    line-height: 19px;
    color: var(--text-primary);
    @media (min-width: $breakpoint-tablet) {
      font-size: 18px;
    }
    @media (min-width: $breakpoint-laptop) {
      font-size: 12px;
      position: absolute;
      left: 0;
    }
    @media (min-width: $breakpoint-fullHD) {
      font-size: 16px;
    }
  }
  &-right {
    display: flex;
    text-align: center;
    justify-content: center;
    @media (min-width: $breakpoint-laptop) {
      width: initial;
      padding-top: initial;
      justify-content: initial;
    }

    &-item {
      cursor: pointer;
      padding-left: 12px;
      padding-right: 12px;
      color: var(--text-primary);
      font-size: 14px;
      font-weight: 500;
      @media (min-width: $breakpoint-tablet) {
        font-size: 18px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 12px;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 16px;
        font-weight: 500;
      }
      &:hover {
        transform: scale(1.2);
      }
      &.active {
        color: var(--text-secondary);
      }
    }
  }
}
