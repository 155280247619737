@import "../../../styles/cards";
@import "../../../styles/breakpoints";

.BulkListPatientZones {
  & .BulkList {
    min-height: 300px;
  }
  & .BulkList-content-list-items {
    &-item {
      width: calc(25% - 10px);
      @media (min-width: $breakpoint-tablet) {
        width: calc(25% - 20px);
      }
      @media (min-width: $breakpoint-laptop) {
        width: calc(10% - 10px);
      }
      @media (min-width: $breakpoint-fullHD) {
        width: calc(10% - 20px);
      }
    }
  }
}
