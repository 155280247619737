@import "breakpoints";
@import "scrollbar";

@mixin Filter {
  position: relative;
  &.hidden {
    & .content {
      max-height: 0;
      border: none;
    }
  }
  &.active {
    & .main {
      border-bottom: 2px solid var(--filter-background);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    & .search {
      & .InputText-element {
        border-bottom: 2px solid var(--filter-background);;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    & .main {
      &:hover {
        background-color: var(--filter-background);
      }
    }
  }

  &-content {
    overflow: auto;
    position: fixed;
    z-index: 5;
    width: calc(100% - 20px);
    top: 58px;
    bottom: 0;
    transition: max-height 0.3s;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: 0;
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-top-left-radius: 0;
    right: 0;
    background-color: var(--filter-background);
    padding-left: 0;
    padding-right: 20px;
    font-size: 12px;
    border-top: 1px solid var(--filter-separator);
    @include FilterBottomSpace();
    @media (min-width: $breakpoint-laptop) {
      border-bottom: 2px solid var(--input-border);
      border-left: 2px solid var(--input-border);
      border-right: 2px solid var(--input-border);
      position: absolute;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      top: initial;
      bottom: initial;
      left: initial;
      border-top: 0;
      max-height: 410px;
      right: initial;
      border-top-left-radius: 0;
      padding-left: 21px;
      padding-right: 21px;
      margin-top: -2px;
      font-size: 12px;
      width: calc(204px - 46px);
    }
    @media (min-width: $breakpoint-fullHD) {
      width: calc(242px - 46px);
      font-size: 16px;
    }

    &-inside {
      padding-top: 30px;
      padding-bottom: 15px;

      @media (min-width: $breakpoint-laptop) {

        border-top: 2px solid var(--input-border);
        padding-bottom: 39px;
        padding-top: 26px;
        border-top: 1px solid var(--input-border);
        border-top-left-radius: 0;
      }

      &-button {
        & .Button {
          & .element {
            width: 100%;
            min-width: initial;
          }
        }
      }

      &-item {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 20px;
        @media (min-width: $breakpoint-laptop) {
          margin-left: 0;
          margin-right: 0;
        }

        &:not(:last-child) {
          margin-bottom: 28px;
        }

        &-text {
          font-size: 16px;
          @media (min-width: $breakpoint-tablet) {
            font-size: 16px;
          }
          @media (min-width: $breakpoint-laptop) {
            font-size: 12px;
          }
          @media (min-width: $breakpoint-fullHD) {
            font-size: 16px;
          }
        }

        &-arrow {
          & img {
            width: 9px;
            height: 16px;
            filter: brightness(10);
          }
        }
      }
    }
  }
}