@import "../../../styles/breakpoints";
@import "../../../styles/text";

.BuildingPlanForm {
  &-form {
    &-inputs {
      &-flex {
        display: flex;
        margin-bottom: 21px;
        @media (min-width: $breakpoint-tablet) {
          width: 500px;
        }
        & .CustomSelect {
          width: initial;
          @media (min-width: $breakpoint-tablet) {
            width: 100%;
          }
        }
        & .InputText {
          margin-right: 46px;
          & .element {
            @media (min-width: $breakpoint-tablet) {
              width: 300px;
            }
          }
        }
      }
      &-hint {
        margin-bottom: 52px;
        font-size: 14px;
        max-width: 590px;
        font-weight: 300;
        letter-spacing: 0.64px;
        line-height: 24px;
        @media (min-width: $breakpoint-fullHD) {
          font-size: 16px;
        }
        & b {
          font-weight: 500;
          color: var(--text-success);
        }
      }
      &-button {
        & .Button {
          & .element {
            justify-content: center;
            background-color: var(--button-primary-border);
          }
        }
      }
      &-files {
        & table {
          width: 100%;
          border-collapse: collapse;
          table-layout: fixed;
        }
        & tr {
          border-bottom: 1px solid rgba(125, 156, 181, 0.2);
          height: 60px;
        }
        & td {
          font-size: 16px;
          & .Button {
            & .element {
              min-width: initial;
              height: 37px;
            }
          }
          &:first-child {
            width: 130px;
            @include Truncate();
          }
          &:last-child {
            width: 230px;
            @include Truncate();
            @media (min-width: $breakpoint-fullHD) {
              width: 310px;
            }
          }
        }
      }
    }
  }

}

