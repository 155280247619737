@import "../../../styles/breakpoints";

.AddDial {
  &-content {
    &-header {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 42px;
      color: var(--text-secondary);
    }
    &-form {
      &-button {
        display: flex;
        justify-content: center;
      }
    }
  }
}