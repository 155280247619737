@import "../../../styles/breakpoints";

.QRScan {
  &-notAllowed {
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    position: relative;
    background-color: #1c2635;
    &-inside {
      top: 0;
      left: 0;
      opacity: 0.7;
      width: 100%;
      height: 100%;
      display: flex;
      overflow: hidden;
      position: absolute;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      &-items {
        &-text {
          width: 100%;
          font-size: 18px;
          color: var(--text-secondary);
        }
        &-image {
          width: 80px;
          height: 80px;
          margin: 0 auto 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          & img {
            width: 100%;
          }
        }
      }
    }
  }
}