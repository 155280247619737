@import "../../../styles/breakpoints";

.TableTitle {
  align-items: center;
  display: flex;
  padding-top: 15px;
  cursor: pointer;
  padding-bottom: 15px;
  @media (min-width: $breakpoint-tablet) {
    padding-top: 23px;
    padding-bottom: 23px;
  }
  @media (min-width: $breakpoint-laptop) {
    display: none;
  }
  &.detailShown {
      margin-bottom: 18px;
      border-bottom: 1px solid var(--table-row-border);
      @media (min-width: $breakpoint-tablet) {
        margin-bottom: 30px;
      }
  }
  &-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-left {
      &-first {
        display: flex;
        align-items: center;
        &-title {
          font-size: 14px;
          @media (min-width: $breakpoint-tablet) {
            font-size: 21px;
          }
        }
        &-state {
          margin-right: 8px;
          @media (min-width: $breakpoint-laptop) {
            margin-right: 15px;
          }
        }
      }
      &-second {
        opacity: 0.6;
        margin-top: 5px;
        &-subtitle {
          font-size: 12px;
          @media (min-width: $breakpoint-tablet) {
            font-size: 18px;
          }
        }
      }
    }
    &-right {
      & img {
        filter: brightness(100);
      }
    }
  }
  @media (min-width: $breakpoint-laptop) {
    display: none;
  }
}