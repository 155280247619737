@import "../../../styles/cards";

@keyframes ResolutionBounceAlert {
  0% { background-color: var(--background-red); }
  50% { background-color: var(--background-red-darker); }
  100% { background-color: var(--background-red); }
}

@keyframes ResolutionBounceWarning {
  0% { background-color: var(--background-orange); }
  50% { background-color: var(--background-orange-darker); }
  100% { background-color: var(--background-orange); }
}

@mixin Resolution($normalColor, $activeColor, $animation) {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  top: -18px;
  justify-content: center;
  height: 30px;
  & .inside {
    display: flex;
    height: 100%;
    align-items: center;
    background-color: $normalColor;
    border-radius: 7px;
    &:hover {
      & .active.activeTRG {
        background-color: initial;
      }
    }
    & .item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.6px;
      height: 100%;
      border-radius: 7px;
      transition: background-color 200ms ease-in-out;
      &:hover {
        background-color: $activeColor;
        animation: $animation 3s infinite ease-in-out;
      }
      &.active {
        background-color: $activeColor;
        &:hover {
          background-color: $activeColor !important;
        }
      }
      & .text {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      & .note {
        z-index: 2;
        width: 254px;
        height: 120px;
        position: absolute;
        top: 40px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--background-default);
        border: 1px solid var(--background-default-border);
        border-radius: 8px;
        & .noteAction {
          position: absolute;
          right: 10px;
          display: flex;
          align-items: center;
          bottom: 10px;
        }
        & .noteTriangle {
          z-index: 4;
          position: absolute;
          top: -10px;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid var(--background-default-border);
          & .noteTriangleSub {
            position: absolute;
            left: -10px;
            width: 0;
            height: 0;
            top: 1.5px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid var(--background-default);
          }
        }
        & .noteContent {
          width: calc(100% - 8px);
          height: calc(100% - 8px);
          & textarea {
            height: calc(100% - 20px - 30px);
            padding: 10px;
            background-color: var(--background-default);
            width: calc(100% - 20px);
            border: none;
            outline: none;
            color: var(--input-text);
            resize: none;
          }
        }
      }
    }
  }
}

.SensorState {
  @include CardFluid();
  border: 4px solid var(--background-primary);
  padding: 14px;
  &.ALERTING {
    border: 4px solid var(--background-red);
    & .imageContainer {
      background-color: var(--background-red-transparenter);
    }
  }
  &.RESOLVED {
    border: 4px solid var(--background-orange);
  }
  &-title {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 10px;
    @media (min-width: $breakpoint-tablet) {
      margin-top: 5px;
    }
    @media (min-width: $breakpoint-laptop) {
      margin-top: 0;
    }
  }
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &-first {
        width: 65px;
        height: 65px;
        background-color: var(--background-default);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        @media (min-width: $breakpoint-tablet) {
          margin-right: 20px;
        }
        &-inside {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          &-text {
            width: 100%;
            text-align: center;
          }
          &-image {
            height: 36px;
            width: 36px;
            display: flex;
            justify-content: center;
            align-items: center;

            & img {
              height: 100%;
              width: 100%;
            }
          }

          &-text {
            font-size: 12px;
          }
        }
      }
      &-second {
        &-top {
          display: flex;
          align-items: center;
          &-left {
            display: flex;
            align-items: flex-end;
            margin-bottom: 5px;
            margin-right: 10px;
            &-value {
              font-size: 28px;
              font-weight: 500;
              color: var(--background-primary-text);
              line-height: 0.9;
              margin-right: 5px;
              @media (min-width: $breakpoint-tablet) {
                font-size: 46px;
              }
              @media (min-width: $breakpoint-laptop) {
                font-size: 35px;
              }
              @media (min-width: $breakpoint-fullHD) {
                font-size: 40px;
              }
            }
            &-unit {
              font-size: 18px;
              font-weight: 500;
              color: var(--background-primary-text);
              @media (min-width: $breakpoint-tablet) {
                font-size: 25px;
              }
              @media (min-width: $breakpoint-laptop) {
                font-size: 24px;
              }
              @media (min-width: $breakpoint-fullHD) {
                font-size: 25px;
              }
            }
          }
          &-right {
            & img {
              height: 20px;
              @media (min-width: $breakpoint-tablet) {
                height: 30px;
              }
            }
          }
        }
        &-bottom {
          color: var(--parameters-placeholder);
          font-size: 12px;
          @media (min-width: $breakpoint-tablet) {
            font-size: 14px;
          }
          @media (min-width: $breakpoint-laptop) {
            font-size: 12px;
          }
          @media (min-width: $breakpoint-fullHD) {
            font-size: 12px;
          }
        }
      }
    }
    &-right {
      height: 72px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin-top: -40px;
      @media (min-width: $breakpoint-laptop) {
        margin-top: initial;
      }
      &-top {
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-end;
        @media (min-width: $breakpoint-tablet) {
          margin-bottom: 18px;
        }
        @media (min-width: $breakpoint-laptop) {
          margin-bottom: 8px;
        }
        @media (min-width: $breakpoint-fullHD) {
          margin-bottom: 15px;
        }
      }
      &-bottom {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: var(--text-primary);
        margin-top: 5px;
        &-value {
          &-item {
            font-size: 12px;
            margin-right: 5px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  &-resolution {
    &.RESOLVED {
      @include Resolution(var(--background-orange-darker), var(--background-orange), ResolutionBounceWarning)
    }
    &.ALERTING {
      @include Resolution(var(--background-red-darker), var(--background-red), ResolutionBounceAlert)
    }
  }
}
