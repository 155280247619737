@import "../../../styles/cards";
@import "../../../styles/modal";
@import "../../../styles/placeholders";

.Empty {
  &-card {
    &.card {
      @include Card();
    }
    &.fluid {
      @include CardFluid();
    }
    height: calc(100% - 22px - 28px);
    display: flex;
    justify-content: center;
    align-items: center;
    &-content {
      height: 100%;
      &-block {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &-inside {
          @include contentBlock();
        }
      }
    }
  }
  &-modal {
    @include ModalSecondary();
    &-content {
      &-block {
        margin-top: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        &-inside {
          @include contentBlock();
        }
      }
    }
  }
  &-full {
    height: 100%;
    &-content {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &-block {
       @include contentBlock();
      }
    }
  }
  &-flat {
    height: 100%;
    &-content {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &-block {
        @include contentBlock();
      }
    }
  }
}