@import "../../../styles/cards";
@import "../../../styles/parameters";

.HardwareInfo {
  @include Card();
  position: relative;
  &-content {
    &-parameters {
      @include parameters();
    }
  }
  &-functions {
    &-right {
      &-item {
        &.edit {
          display: none;
          @media (min-width: $breakpoint-tablet) {
            display: block;
          }
        }
      }
    }
  }
}