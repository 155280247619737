@import "../../../styles/breakpoints";
@import "../../../styles/cards";
@import "../../../styles/draggable";
@import "../../../styles/scrollbar";

.EasyList {
  @include Card();
  position: initial;
  &.minimized {
    & .EasyList-content-search {
      opacity: 0;
      height: 0;
    }
    & .EasyList-content-inside-sub {
      max-height: 0;
    }
    & .EasyList-content-filterFree {
      max-height: 0;
      opacity: 0;
    }
  }
  &.blue {
    background-color: var(--easy-list-blue-bkg);
    & .EasyList-content-title {
      color: var(--easy-list-blue-text);
    }
    & .EasyList-content-title-minimize {
      & img {
        filter: var(--easy-list-blue-filter);
      }
    }
    & .EasyList-content-inside-sub {
      @include ScrollBarBlue();
    }
  }
  &.orange {
    background-color: var(--easy-list-orange-bkg);
    & .EasyList-content-title {
      color: var(--easy-list-orange-text);
    }
    & .EasyList-content-title-minimize {
      & img {
        filter: var(--easy-list-orange-filter);
      }
    }
    & .EasyList-content-inside-sub {
      @include ScrollBarOrange();
    }
  }
  &.pink {
    background-color: var(--easy-list-pink-bkg);
    & .EasyList-content-title {
      color: var(--easy-list-pink-text);
    }
    & .EasyList-content-title-minimize {
      & img {
        filter: var(--easy-list-pink-filter);
      }
    }
    & .EasyList-content-inside-sub {
      @include ScrollBarPink();
    }
  }
  &-content {
    &-search {
      opacity: 1;
      height: 75px;
      transition: height 0.3s ease-in-out, opacity 0.2s ease-in-out;
    }
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 15px;
      &-minimize {
        cursor: pointer;
      }
    }
    &-inside {
      margin: -8px -13px;
      &-sub {
        transition: max-height 0.3s ease-in-out;
        margin-bottom: 21px;
        position: relative;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow: auto;
        max-height: 250px;
        @include ScrollBarBlue();

        &-item {
          @include Draggable();
        }
      }
    }
  }
}