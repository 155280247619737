@import "../../../styles/breakpoints";

.Breadcrumb {
  &-mobile {
    display: flex;
    cursor: pointer;
    @media (min-width: $breakpoint-laptop) {
      display: none;
    }
    &-text {
      color: var(--text-primary);
      font-size: 16px;
      @media (min-width: $breakpoint-fullHD) {
        font-size: 18px;
      }
    }
    &-icon {
      margin-right: 11px;
    }
  }
  &-desktop {
    display: none;
    @media (min-width: $breakpoint-laptop) {
      display: block;
    }
    &-content {
      display: flex;
      align-items: center;

      &-item {
        display: flex;
        align-items: center;

        &.link {
          & .name {
            font-weight: 500;
            cursor: pointer;
          }
        }

        &-name, &-separator {
          color: var(--text-primary);
          font-size: 16px;
          @media (min-width: $breakpoint-fullHD) {
            font-size: 18px;
          }
        }

        &-separator {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
}