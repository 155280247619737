@import "../../../styles/cards";
@import "../../../styles/parameters";


.HardwareCreateDialogScan {
  &-content {
    &-scanned {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 auto;
      @media (min-width: $breakpoint-laptop) {
        flex-wrap: nowrap;
        max-width: 1100px;
      }
      &-left {
        text-align: center;
        width: 100%;
        @media (min-width: $breakpoint-laptop) {
          width: initial;
        }
        &-image {
          width: 200px;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto 34px;

          & img {
            width: 100%;
            height: 100%;
          }
        }
        &-text {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: center;
          margin-bottom: 30px;
          &-item {
            margin-right: 15px;
          }
        }
      }
      &-right {
        display: flex;
        justify-content: center;
        width: 100%;
        @media (min-width: $breakpoint-laptop) {
          width: initial;
        }
      }
    }
    &-notScanned {
      display: flex;
      justify-content: center;
      @media (min-width: $breakpoint-laptop) {
        height: calc(100vh - 395px);
        align-items: center;
      }
      @media (min-width: $breakpoint-fullHD) {
        height: calc(100vh - 442px);
      }
      &-inside {
        &-image {
          margin-bottom: 70px;
          width: 306px;
          height: 306px;
          display: flex;
          justify-content: center;
          & img {
            width: 100%;
            height: 100%;
          }
        }
        &-button {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

