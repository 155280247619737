@import "breakpoints";

@mixin ModalNavigation {
  position: fixed;
  background-color: var(--background-secondary);
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 5;
  @media (min-width: $breakpoint-tablet) {
    width: 400px;
  }
  &-back {
    height: 86px;
    padding-left: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--profile-separator);
    margin-bottom: 20px;
    &-text {
      color: var(--text-secondary);
      font-weight: 500;
      font-size: 18px;
    }
    &-icon {
      margin-right: 19px;
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
}