@import "../../../styles/breakpoints";

.CustomSelect {
  width: 100%;
  margin-bottom: 15px;
  font-size: 12px;
  position: relative;
  @media (min-width: $breakpoint-tablet) {
    font-size: 15px;
    margin-bottom: 15px;
  }
  @media (min-width: $breakpoint-laptop) {
    font-size: 12px;
    margin-bottom: 16px;
  }
  @media (min-width: $breakpoint-fullHD) {
    font-size: 16px;
    margin-bottom: 16px;
  }
  & .error {
    margin-top: 10px;
    color: var(--text-error);
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    @media (min-width: $breakpoint-tablet) {
      font-size: 15px;
    }
    @media (min-width: $breakpoint-laptop) {
      font-size: 12px;
    }
    @media (min-width: $breakpoint-fullHD) {
      font-size: 16px;
    }
    &:before {
      content: "*";
    }
  }
  &.disabled {
    & .CustomSelect-label-select {
      cursor: not-allowed;
    }

    & .CustomSelect-label-select-element__single-value {
      color: var(--input-placeholder) !important;
    }
  }
  &.notFullWidth {
    width: 235px;
  }
  &.nok {
    & .CustomSelect-label-select-element__control {
      border: 2px solid var(--background-red) !important;
    }

    & .CustomSelect-label-select-element__indicator {
      display: none !important;
    }

    & .error {
      opacity: 1;
    }
  }
  &.ok {
    & .CustomSelect-label-select-element__control {
      border: 2px solid var(--background-green) !important;
    }
  }
  &-searchIcon, &-clearIcon {
    width: 17px;
    @media (min-width: $breakpoint-fullHD) {
      width: 21px;
    }
  }
  &-label {
    color: var(--input-label);
    &-block {
      margin-bottom: 10px;
    }
    &-select {
      width: 100%;
      position: relative;
      cursor: pointer;
      &-element {
        &__menu {
          margin-top: -0px !important;
          box-shadow: none !important;
          border-left: 2px solid var(--input-border) !important;
          border-right: 2px solid var(--input-border) !important;
          border-bottom: 2px solid var(--input-border) !important;
          border-radius: 0 0 5px 5px !important;
          width: calc(100% + 4px) !important;
          @media (min-width: $breakpoint-laptop) {
            border-radius: 0 0 5px 8px !important;
          }
          @media (min-width: $breakpoint-fullHD) {
            border-radius: 0 0 5px 10px !important;
          }
        }
        &__control {
          cursor: pointer !important;
          box-shadow: none !important;
          padding-left: 10px;
          padding-right: 32px;
          height: 38px !important;
          border: 2px solid !important;
          border-color: var(--input-border) !important;
          background-color: var(--input-background) !important;
          color: var(--input-text) !important;
          width: calc(100% + 4px) !important;
          outline: none !important;
          transition: border-bottom-color 0.3s ease-in-out, border-left-color 0.3s ease-in-out,
          border-right-color 0.3s ease-in-out, border-top-color 0.3s ease-in-out;

          @media (min-width: $breakpoint-tablet) {
            height: 57px !important;
          }
          @media (min-width: $breakpoint-laptop) {
            height: 44px !important;
          }
          @media (min-width: $breakpoint-fullHD) {
            height: 65px !important;
          }
          &:focus {
            outline: none !important;
          }
          &--menu-is-open {
            cursor: text !important;
            border-bottom: 0 !important;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
          }
        }
        &__single-value {
          color: var(--input-text) !important;
        }
        &__placeholder {
          color: var(--input-placeholder) !important;
        }
        &__menu-list {
          background-color: var(--input-background) !important;
        }
        &__option {
          color: var(--text-primary) !important;
          cursor: pointer !important;
        }
        &__option--is-focused {
          color: var(--input-placeholder) !important;
          background-color: var(--background-secondary) !important;
        }
        &__option--is-selected {
          color: var(--accent-primary-text) !important;
          background-color: var(--background-primary) !important;
        }
        &__input {
          color: var(--input-text) !important;
        }
        &__indicator {

          position: absolute !important;
          right: 5px !important;
          @media (min-width: $breakpoint-tablet) {
            right: 10px !important;
          }

          &.hidden {
            display: none !important;
          }
        }
        &__indicator-separator {
          background-color: var(--input-background) !important;
          display: none !important;
        }
      }
    }
  }
  &-validator {
    position: absolute;
    right: 5px;
    width: 17px;
    height: 38px;
    top: 0;
    @media (min-width: $breakpoint-tablet) {
      height: 57px;
      right: 10px;
    }
    @media (min-width: $breakpoint-laptop) {
      height: 44px;
      right: 21px;
    }
    @media (min-width: $breakpoint-fullHD) {
      height: 65px;
      width: 21px;
    }
    &.withLabel {
      top: 22px;
      height: 38px;
      @media (min-width: $breakpoint-tablet) {
        top: 28px;
        height: 57px;
      }
      @media (min-width: $breakpoint-laptop) {
        top: 24px;
        height: 44px;
      }
      @media (min-width: $breakpoint-fullHD) {
        top: 29px;
        height: 65px;
      }
    }
  }
}
