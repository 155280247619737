@import "../../../styles/breakpoints";
@import "../../../styles/detailLink";

.TableDetail {
  margin-bottom: 22px;
  @media (min-width: $breakpoint-laptop) {
    display: none;
  }
  &-link {
    @include DetailLink();
  }
  &-items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -15px;
    @media (min-width: $breakpoint-tablet) {
      margin-bottom: -20px;
    }
    &-item {
      width: 50%;
      margin-bottom: 15px;
      @media (min-width: $breakpoint-tablet) {
        width: 33.3333%;
        margin-bottom: 20px;
      }
      &.maxWidth {
        width: 100%;
      }
      & .title {
        color: var(--text-primary);
        font-size: 12px;
        margin-bottom: 5px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }
      & .value {
        color: var(--text-secondary);
        font-size: 14px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 20px;
        }
      }
      &-role {
        &-value {
          & .Role-title {
            margin-bottom: 0;
            font-size: 14px;
            @media (min-width: $breakpoint-tablet) {
              font-size: 20px;
            }
          }
          & .Role-icon {
            width: 15px;
            height: 15px;
            @media (min-width: $breakpoint-tablet) {
              width: 20px;
              height: 20px;
            }
            & img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      &-boolean {
        &-content {
          display: flex;
          align-items: center;
          &-image {
            height: 15px;
            width: 15px;
            display: flex;
            justify-content: center;
            align-content: center;
          }
          &-text {
            font-size: 20px;
            margin-left: 10px;
            &.yes {
              color: var(--text-success)
            }
            &.no {
              color: var(--text-error)
            }
          }
        }
      }
      &-extreme {
        &-value {
          display: flex;
          font-weight: 500;
          align-items: center;
          & img {
            height: 12px;
            margin-right: 10px;
            @media (min-width: $breakpoint-tablet) {
              height: 18px;
            }
          }
          &-item {
            margin-right: 10px;
            display: flex;
            align-items: center;
            &.min {
              color: var(--text-blue);
            }
            &.max {
              color: var(--text-red);
            }
          }
        }
      }
      &-text {
        &-value {
          &.green {
            color: var(--text-success)
          }
          &.yellow {
            color: var(--text-yellow);
          }
          &.blue {
            color: var(--text-blue);
          }
          &.pink {
            color: var(--text-purple);
          }
          &.default {
            color: var(--text-primary);
          }
        }
      }
      &-images {
        &-value {
          display: flex;
          flex-wrap: wrap;
          margin: -4px;
          &-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 27px;
            height: 27px;
            border-radius: 6px;
            background-color: var(--background-default-transparent);
            margin: 4px;
            @media (min-width: $breakpoint-tablet) {
              width: 32px;
              height: 32px;
            }
            &-image {
              width: 18px;
              height: 18px;
              @media (min-width: $breakpoint-tablet) {
                width: 20px;
                height: 20px;
              }
              & img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  &-select {
    &-item {
      & .CustomSelect {
        font-size: 14px !important;
        @media (min-width: $breakpoint-tablet) {
          font-size: 16px !important;
        }
        & .CustomSelect-label-select-element__control {
          background-color: #3f4d60 !important;
        }
        &-clearIcon {
          filter: brightness(100);
          height: 13px;
          width: 13px;
          @media (min-width: $breakpoint-tablet) {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }
}
