@import "../../../styles/breakpoints";

.FilterMain {
  cursor: pointer;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  background-color: var(--filter-background);
  border-radius: 10px;
  justify-content: center;
  border: 2px solid var(--filter-border);
  font-size: 12px;
  @media (min-width: $breakpoint-tablet) {
    width: 49px;
    height: 49px;
  }
  @media (min-width: $breakpoint-laptop) {
    border-radius: 15px;
    width: calc(204px - 46px);
    height: calc(47px - 4px);
    padding-left: 21px;
    padding-right: 21px;
    font-size: 12px;
    justify-content: space-between;
  }
  @media (min-width: $breakpoint-fullHD) {
    height: calc(57px - 4px);
    width: calc(242px - 46px);
    font-size: 16px;
  }
  &:hover {
    background-color: var(--filter-background-hover);
    border-color: var(--filter-border-hover);
  }
  &-text {
    display: none;
    @media (min-width: $breakpoint-laptop) {
      display: block;
    }
  }

  &-search {
    position: relative;
    display: none;
    @media (min-width: $breakpoint-laptop) {
      display: block;
    }
    .InputText {
      margin-bottom: 0;

      &-element {
        border-radius: 15px;
        height: calc(47px - 6px);
        width: calc(204px - 46px);
        font-size: 12px;
        border: 2px solid var(--input-border);
        padding-left: 21px;
        padding-right: 21px;
        @media (min-width: $breakpoint-fullHD) {
          height: calc(57px - 4px);
          width: calc(242px - 46px);
          font-size: 16px;
        }
      }
    }
  }
  &-mobileSearch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 1;
    left: 0;
    width: calc(100% - 20px - 20px);
    height: 59px;
    background-color: var(--filter-background);
    padding-left: 20px;
    padding-right: 20px;
    @media (min-width: $breakpoint-laptop) {
      display: none;
    }
    &-left {
      width: 100%;
      &-placeholder {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding-right: 15px;
        &-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &-input {
        & .InputText {
          margin-bottom: 0;

          & .element {
            height: 58px !important;
            border: none !important;
            font-size: 16px;
            padding-left: 0;
            padding-right: 0;

            &::placeholder {
              font-size: 16px;
            }
          }
        }

        & .InputText .image {
          right: 10px;
          top: 10px;
          @media (min-width: $breakpoint-tablet) {
            top: 0;
          }
        }
      }
    }
  }
}