@import "../../../styles/breakpoints";
@import "../../../styles/cards";

.EasyBox {
  @include Card();
  @media (min-width: $breakpoint-laptop) {
    padding: 50px 0 0 !important;
    min-height: calc(100% - 50px) !important;
  }
  &-content {
    margin-bottom: 100px;
    &-title {
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 15px;
      @media (min-width: $breakpoint-laptop) {
        padding-left: 31px;
        padding-right: 31px;
        margin-bottom: 29px;
      }
    }

    &-search {
      margin-bottom: 20px;
      padding-left: 5px;
      padding-right: 5px;
      @media (min-width: $breakpoint-laptop) {
        padding-left: 31px;
        padding-right: 31px;
      }
      & .InputText {
        & .element {
          background-color: transparent;
          border-top: none;
          border-right: none;
          border-left: none;
          border-radius: 0;
          border-bottom: 2px solid var(--input-border-search);
        }
      }
    }

    &-inside {
      &-item {
        cursor: pointer;
        @media (min-width: $breakpoint-laptop) {
          padding-left: 10px;
          padding-right: 10px;
        }
        @media (min-width: $breakpoint-fullHD) {
          padding-left: 15px;
          padding-right: 15px;
        }
        &:not(:last-child) {
          padding-bottom: 7px;
          @media (min-width: $breakpoint-fullHD) {
            padding-bottom: 8px;
          }
          @media (min-width: $breakpoint-laptop) {
            border-bottom: 1px solid rgba(125, 156, 181, 0.2);
          }
        }
        &:not(:first-child) {
          padding-top: 7px;
          @media (min-width: $breakpoint-fullHD) {
            padding-top: 8px;
          }
        }

        &-data {
          padding: 13px 23px 18px 13px;
          @media (min-width: $breakpoint-laptop) {
            padding: 19px 15px 15px;
          }
          @media (min-width: $breakpoint-fullHD) {
            padding: 19px 31px 23px;
          }
          border: 2px solid transparent;
          &.error {
            background-color: var(--easyBox-alert-bkg);
            border-radius: 8px;
            &.active {
              border: 2px solid var(--easyBox-alert-border);
            }
            & .label {
              background-color: var(--easyBox-alert-label-bkg);
            }
          }

          &.warning {
            background-color: var(--easyBox-warning-bkg);
            border-radius: 8px;
            &.active {
              border: 2px solid var(--easyBox-warning-button-border);
            }
            & .label {
              background-color: var(--easyBox-warning-label-bkg);
            }
          }

          &.silver {
            border-radius: 8px;
            &.active {
              border: 2px solid var(--easyBox-silver-button-border);
            }
            & .label {
              background-color: var(--easyBox-silver-label-bkg);
            }
          }

          &.success {
            border-radius: 8px;
            &.active {
              border: 2px solid var(--easyBox-success-button-border);
            }
            & .label {
              background-color: var(--easyBox-success-label-bkg);
            }
          }

          &-top {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            &-left {
              width: 225px;
              @media (min-width: $breakpoint-laptop) {
                width: 225px;
              }
              @media (min-width: $breakpoint-fullHD) {
                width: 238px;
              }

              &-first {
                display: flex;
                width: 100%;
                align-items: center;
                @media (min-width: $breakpoint-laptop) {
                  align-items: flex-start;
                }
                &-photo {
                  margin-right: 9px;

                  & img {
                    width: 41px;
                    height: 41px;
                  }
                }

                &-info {
                  width: 100%;

                  &-name {
                    font-size: 16px;
                  }

                  &-role {
                    margin-top: 5px;

                    & .Role-icon {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 10px;
                      height: 10px;
                      margin-right: 5px;
                      @media (min-width: $breakpoint-tablet) {
                        width: 14px;
                        height: 14px;
                      }
                      @media (min-width: $breakpoint-laptop) {
                        width: 10px;
                        height: 10px;
                      }
                      @media (min-width: $breakpoint-fullHD) {
                        width: 12px;
                        height: 12px;
                      }

                      & img {
                        height: 100%;
                      }
                    }

                    & .Role-title {
                      font-size: 12px;
                      @media (min-width: $breakpoint-tablet) {
                        font-size: 16px;
                      }
                      @media (min-width: $breakpoint-laptop) {
                        font-size: 12px;
                      }
                      @media (min-width: $breakpoint-fullHD) {
                        font-size: 14px;

                      }
                    }
                  }

                  &-sensors {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 7px;

                    &-item {
                      &-value {
                        display: flex;
                        align-items: center;
                        &-icon {
                          height: 14px;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          margin-right: 5px;

                          & img {
                            height: 100%;
                          }
                        }

                        &-number, &-unit {
                          margin-right: 5px;
                          font-size: 14px;
                          font-weight: 500;
                        }
                      }

                      &-updated {
                        margin-top: 7px;
                        font-size: 12px;
                        color: var(--text-primary);
                      }
                    }

                    &-empty {
                      font-size: 12px;
                      font-weight: 500;
                    }
                  }
                }
              }
            }

            &-right {
              &-action {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 29px;
                height: 29px;
                border-radius: 6px;
                background-color: var(--easyBox-action-bkg);
                border: 1px solid var(--easyBox-action-border);
                cursor: pointer;
                transition: background-color 300ms ease-in-out;
                @media (min-width: $breakpoint-laptop) {
                  width: 36px;
                  height: 36px;
                }
                &:not(.disabled):active {
                  background-color: var(--easyBox-action-active-bkg);
                  border-color: var(--easyBox-action-active-border);
                  & img {
                    filter: brightness(0);
                  }
                }
                &:not(.disabled):not(:active):hover {
                  background-color: var(--easyBox-action-hover-bkg);
                  border-color: var(--easyBox-action-hover-border);
                }

                &.disabled {
                  cursor: not-allowed;
                  opacity: 0.4;
                }

                & img {
                  width: 19px;
                  height: 13px;
                  @media (min-width: $breakpoint-laptop) {
                    width: 22px;
                    height: 15px;
                  }
                }
              }
            }
          }

          &-bottom {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (min-width: $breakpoint-laptop) {
              margin-top: 10px;
            }
            &-locating {
              display: flex;
              align-items: center;
              &.ACTIVE {
                color: var(--text-orange);
              }
              &.FAILED {
                color: var(--text-error);
              }
              &-text {
                line-height: 1;
                font-weight: 500;
                font-size: 12px;
              }
              &-tick {
                display: flex;
                align-items: center;
                margin-right: 5px;
                & img {
                  height: 6px;
                  width: 10px;
                }
              }
              &-spinner {
                margin-left: 5px;
              }
            }
            &-label {
              height: 21px;
              min-width: 111px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              width: fit-content;
              padding-left: 5px;
              padding-right: 5px;

              &-text {
                font-size: 12px;
                font-weight: 500;
              }

              &-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 10px;
                margin-right: 5px;
                height: 10px;

                & img {
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
