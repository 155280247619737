@import "../../../styles/cards";

.ListUsers {
  @include Card();
  position: relative;
  &-table {
    margin-bottom: 50px;
  }
  & .FunctionTable {
    & .TableColumn-column-container-image-item {
      display: flex;
      justify-content: center;
      align-items: center;
      & img {
        height: 17px;
        width: 17px;
      }
    }
    & .Table-head-row-column {
      &:nth-of-type(1) {
        width: 7%;
        @media (min-width: $breakpoint-fullHD) {
          width: 7%;
        }
      }
      &:nth-of-type(2) {
        width: 18%;
        @media (min-width: $breakpoint-fullHD) {
          width: 18%;
        }
      }
      &:nth-of-type(3) {
        width: 12%;
        @media (min-width: $breakpoint-fullHD) {
          width: 12%;
        }
      }
      &:nth-of-type(4) {
        width: 18%;
        @media (min-width: $breakpoint-fullHD) {
          width: 18%;
        }
      }
      &:nth-of-type(5) {
        width: 14%;
        @media (min-width: $breakpoint-fullHD) {
          width: 14%;
        }
      }
      &:nth-of-type(6) {
        width: 12%;
        @media (min-width: $breakpoint-fullHD) {
          width: 12%;
        }
      }
    }
  }
}