@import "../../../styles/cards";
@import "../../../styles/modal";
@import "../../../styles/placeholders";

.ErrorComponent {
  &-flat {
    @media (min-width: $breakpoint-laptop) {
      height: calc(100vh - 395px);
    }
    @media (min-width: $breakpoint-fullHD) {
      height: calc(100vh - 442px);
    }
    &-content {
      height: 100%;
      &-block {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &-inside {
          @include contentBlock();
        }
      }
    }
  }
  &-card {
    @include Card();
    height: calc(100% - 30px - 48px);
    @media (min-width: $breakpoint-tablet) {
      height: calc(100% - 30px - 48px);
    }
    @media (min-width: $breakpoint-laptop) {
      height: calc(100% - 22px - 28px);
    }
    @media (min-width: $breakpoint-fullHD) {
      height: calc(100% - 22px - 40px);
    }
    &-content {
      height: 100%;
      &-block {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &-inside {
          margin-top: -40px;
          @include contentBlock();
        }
      }
    }
  }
  &-modal {
    @include ModalSecondary();
    &-content {
      &-block {
        margin-top: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        &-inside {
          @include contentBlock();
        }
      }
    }
  }
  &-full {
    height: 100%;
    &-content {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &-block {
       @include contentBlock();
      }
    }
  }
}
