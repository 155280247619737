@import "../../../styles/cards";
@import "../../../styles/breakpoints";

.BuildingPlan {
  @include Card();
  position: relative;
  &-content {
    @media (min-width: $breakpoint-laptop) {
      padding-right: 90px;
    }
    @media (min-width: $breakpoint-fullHD) {
      padding-right: 185px;
    }
    &-main {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      @media (min-width: $breakpoint-laptop) {
        flex-wrap: nowrap;
      }
      &-form {
        margin-bottom: 46px;
        @media (min-width: $breakpoint-laptop) {
          margin-right: 133px;
          margin-bottom: 0;
        }
      }
    }
    &-building {
      display: flex;
      justify-content: center;
    }
    &-buttons {
      margin-top: 98px;
      display: flex;
      justify-content: center;
      & .Button {
        &:not(:last-child) {
          margin-right: 20px;
        }
        & .element {
          display: flex;
          justify-content: center;
        }
      }
    }
    &-error {
      margin-bottom: -80px;
      margin-top: 80px;
      display: flex;
      justify-content: center;
      color: var(--text-error);
    }
  }
}

