:root {
  --background-inverted: white;
  --background-secondary: #050F1E;
  --background-secondary-text: white;
  --background-secondary-border: #3935D833;
  --background-primary: #222F43;
  --background-primary-border: #5d6579;
  --background-primary-dark: #192431;
  --background-primary-light: rgba(34, 47, 67, 0.8);
  --background-primary-text: white;
  --background-ternary: #06152B;
  --background-ternary-text: white;
  --background-ternary-border: #3935D833;
  --background-quadrary: black;
  --background-quadrary-border: #4f4f4f;
  --background-pentary: #06152b;
  --background-pentary-border: #101b4d;
  --background-purple: #583775;
  --background-purple-transparent: #583775;
  --background-default: #2B3441;
  --background-default-transparent: #3E4D60;
  --background-default-border: #4a596e;
  --background-orange: #e2aa56;
  --background-orange-border: #F09C37;
  --background-orange-transparent: #484847;
  --background-orange-transparent-second: rgba(226, 170, 86, 0.3);
  --background-orange-from: #D48E26;
  --background-orange-to: #C8630B;
  --background-orange-darker: #9d6511;
  --background-blue: #03a9f3;
  --background-blue-dark: #1e1f43;
  --background-blue-transparent: rgba(0, 144, 255, 0.3);
  --background-blue-transparenter: rgba(0, 144, 255, 0.2);
  --background-red: #df3639;
  --background-red-border: #DF3639;
  --background-red-transparent: rgba(241, 53, 81, 0.3);
  --background-red-transparenter: rgba(241, 53, 81, 0.2);
  --background-red-from: rgba(211,65,71,1);
  --background-red-to: rgba(153,23,27,1);
  --background-red-dark: #431E2F;
  --background-red-darker: #841b1d;
  --background-green: #3CBE7D;
  --background-green-border: #3CC37F;
  --background-green-from: #1BB66C;
  --background-green-to: #1B8954;
  --background-green-transparent: #1B8954;
  --background-green-dark: #2E575B;
  --background-purple-light: rgba(88, 55, 117, 0.8);
  --background-default-light: rgba(43, 52, 65, 0.8);
  --background-orange-light: rgba(226, 170, 86, 0.1);
  --background-blue-light: rgba(0, 144, 255, 0.37);
  --background-red-light: rgba(220, 66, 69, 0.34);
  --background-green-light: rgba(60, 190, 125, 0.2);
  --background-grey: #7D9CB5;
  --background-grey-light: rgba(125, 156, 181, 0.6);
  --background-welcome-gradient: radial-gradient(circle at 50% 50%, #222f43, #07090d 345%);
  --background-silver-border: #888888;
  --background-silver-gradient: linear-gradient(137deg, rgba(141, 141, 141, 0.85) 1%, rgba(70, 70, 70, 0.85) 100%);

  --input-background: #202A39;
  --input-background-inverted: white;
  --input-border: #4A596E;
  --input-border-inverted: #cfcfcf;
  --input-border-search: #4F667C;
  --input-text: white;
  --input-label: #7D9CB5;
  --input-placeholder: #7D9CB5;
  --select-optional-background: #3f4d60;

  --button-ternary-backgroud: #71522E;
  --button-ternary-border: #e2aa56;
  --button-ternary-backgroud-hover: #9F7341;
  --button-ternary-border-hover: #EC8711;
  --button-ternary-backgroud-active: #C57C27;
  --button-ternary-border-active: #EC8711;
  --button-primary-backgroud: #1E217B;
  --button-primary-backgroud-hover: #3935d8;
  --button-primary-backgroud-active: #404cb4;
  --button-primary-border: #3935d8;
  --button-primary-border-hover: #3935d8;
  --button-primary-border-active: #3935d8;
  --button-primary-disabled: #2B3441;
  --button-primary-disabled-text: #7D9CB5;
  --button-primary-disabled-border: #4A596E;
  --button-primary-text: white;
  --button-secondary-backgroud: #2B3441;
  --button-secondary-border: #4A596E;
  --button-secondary-backgroud-active: #E4E4E4;
  --button-secondary-border-active: #4A596E;
  --button-secondary-backgroud-hover: #3E4857;
  --button-secondary-border-hover: #4A596E;
  --button-secondary-text: white;
  --button-ternary-text: white;
  --button-quadrary-backgroud: #2A317E;
  --button-quadrary-border: #6e6be8;
  --button-cancel-background: rgba(141, 142, 154, 0.2);
  --button-cancel-border: #8d8e9a;
  --button-cancel-background-hover: #3E4857;
  --button-cancel-border-hover: #8D8E9A;
  --button-cancel-background-active: #E4E4E4;
  --button-cancel-border-active: #8D8E9A;
  --button-delete-background: #461a26;
  --button-delete-border: #df3639;
  --button-delete-background-hover: #74283d;
  --button-delete-border-hover: #df3639;
  --button-delete-background-active: #df3639;
  --button-delete-border-active: #df3639;
  --button-filled-background: #3935D8;
  --button-empty-background: #06152b;
  --button-empty-border: rgba(57, 53, 216, 0.3);
  --button-remove-flat-color: #df3639;

  --filter-error: invert(45%) sepia(100%) saturate(4430%) hue-rotate(336deg) brightness(91%) contrast(88%);
  --filter-default: invert(64%) sepia(29%) saturate(327%) hue-rotate(164deg) brightness(89%) contrast(94%);
  --filter-blue: invert(67%) sepia(50%) saturate(5670%) hue-rotate(165deg) brightness(96%) contrast(100%);

  --text-inverted: black;
  --text-inverted-light: #afb0bf;
  --text-primary: #7D9CB5;
  --text-secondary: white;
  --text-error: #DC4245;
  --text-pink: #db5aee;
  --text-success: #3CBE7D;
  --navigation-border: #3A36DB;
  --navigation-minimize-bkg: #3a36db;
  --text-yellow: #e2cf56;
  --text-orange: #e2aa56;
  --text-purple: #d44ae9;
  --text-blue: #03a9f3;
  --text-red: #E25656;
  --text-default: #7D9CB5;
  --text-opacity: #7d9cb5;

  --table-order-bkg: #7d9cb5;
  --table-head-text: #7D9CB5;
  --table-divider: #4A596E;
  --table-body-text: white;
  --table-row-border: rgba(125, 156, 181, 0.2);
  --table-background: #202A39;
  --table-selected-row: #1D2839;

  --filter-background: #202A39;
  --filter-background-hover: #3e4857;
  --filter-background-active: #06152B;
  --filter-text-active: white;
  --filter-text-placeholder: #7D9CB5;
  --filter-separator: #8d8e9a;
  --filter-border: #4a596e;
  --filter-border-hover: #4a596e;

  --header-item-background: #202A39;
  --header-item-border: #4A596E;

  --parameters-placeholder: #7d9cb5;
  --parameters-divider: #404e62;

  --range-slider: #87a4bc;
  --range-thumb: #3CBE7D;
  --range-current: #3CBE7D;
  --range-hint: #7d9cb5;
  --range-bkg: rgba(6, 21, 43, 30%);
  --range-disabled: #4A596E;

  --checkbox-label: #7d9cb5;
  --checkbox-tick: #3CBE7D;
  --checkbox-bkg: rgba(34, 47, 67, 0.5);
  --checkbox-border: #4a596e;

  --letter-image-bkg: #8D8E9A;
  --letter-image-text: #272848;

  --card-patient-title: #8D8E9A;

  --calendar-background: #202A39;
  --calendar-border: #4a596e;
  --calendar-current-date-background: rgba(241, 53, 81, 0.3);
  --calendar-selected-backgroud: #3935d8;
  --calendar-weekend-backgroud: #3A4958;
  --calendar-disabled-text: #7d9cb5;
  --calendar-hover-backgroud: rgba(57, 53, 216, 0.2);

  --easy-list-blue-text: #0090ff;
  --easy-list-blue-bkg: #142C4D;
  --easy-list-blue-filter: invert(36%) sepia(24%) saturate(5586%) hue-rotate(193deg) brightness(108%) contrast(111%);
  --easy-list-orange-text: #e2aa56;
  --easy-list-orange-bkg: rgba(226, 170, 86, 0.2);
  --easy-list-orange-filter: invert(40%) sepia(90%) saturate(1786%) hue-rotate(339deg) brightness(100%) contrast(83%);
  --easy-list-pink-text: #db5aee;
  --easy-list-pink-bkg: rgba(213, 74, 233, 0.19);
  --easy-list-pink-filter: invert(43%) sepia(19%) saturate(5921%) hue-rotate(259deg) brightness(105%) contrast(87%);

  --hwTypes-blue-text: #0090ff;
  --hwTypes-blue-bkg: rgba(45, 83, 119, 0.8);
  --hwTypes-blue-border: #0290FF;
  --hwTypes-orange-text: #e2aa56;
  --hwTypes-orange-bkg: rgba(249, 185, 89, 0.22);
  --hwTypes-orange-border: #E2AA56;
  --hwTypes-green-text: #1ad598;
  --hwTypes-green-bkg: rgba(50, 97, 98, 0.8);
  --hwTypes-green-border: #1ad598;
  --hwTypes-pink-text: #db5aee;
  --hwTypes-pink-bkg: rgba(213, 74, 233, 0.19);
  --hwTypes-pink-border: rgba(213, 74, 233, 1);


  --draggable-divider: rgba(125, 156, 181, 0.2);
  --draggable-blue-active-bkg: #286298;
  --draggable-blue-inactive-bkg: #104071;
  --draggable-blue-border: #0290FF;
  --draggable-orange-active-bkg: #887049;
  --draggable-orange-inactive-bkg: #77654B;
  --draggable-orange-border: #E2AA56;
  --draggable-remove-color: #df3639;
  --draggable-detail-color: #06152b;
  --draggable-pink-active-bkg: #614066;
  --draggable-pink-inactive-bkg: #503354;
  --draggable-pink-border: rgba(213, 74, 233, 1);
  --draggable-bubble-alert: #dc4245;
  --draggable-bubble-warning: #E2AA56;

  --labelSelects-orange: rgba(136, 112, 73, 0.78);
  --labelSelects-blue: rgba(40, 98, 152, 0.78);
  --labelSelects-pink: rgba(213, 74, 233, 0.34);
  --labelSelects-border-orange: #E2AA56;
  --labelSelects-border-blue: #0290FF;
  --labelSelects-border-pink: rgba(213, 74, 233, 1);

  --cardAlert-head-border: #404E62;

  --spinner-color: #ffffff;

  --easyBox-divider: rgba(125, 156, 181, 0.2);
  --easyBox-subtitle: #7d9cb5;
  --easyBox-action-bkg: #2b3441;
  --easyBox-action-border: #7d9cb5;
  --easyBox-action-hover-bkg: #3E4857;
  --easyBox-action-hover-border: #7D9CB5;
  --easyBox-action-active-bkg: #7D9CB5;
  --easyBox-action-active-border: #2C3441;
  --easyBox-yellow: #e2aa56;
  --easyBox-blue: #0090ff;
  --easyBox-pink: #db5aee;

  --easyBox-alert-bkg: #5b3543;
  --easyBox-alert-border: #DC4245;
  --easyBox-alert-button-bkg: #64323C;
  --easyBox-alert-label-bkg: #dc4245;
  --easyBox-alert-label-border: #dc4245;

  --easyBox-warning-bkg: #e2aa5633;
  --easyBox-warning-button-bkg: #644732;
  --easyBox-warning-button-border: #E2AA56;
  --easyBox-warning-label-bkg: #9e773d;
  --easyBox-warning-label-border: #E2AA56;

  --easyBox-silver-label-bkg: #888888;
  --easyBox-silver-label-border: #888888;
  --easyBox-silver-button-border: #888888;

  --easyBox-success-label-bkg: #3cbe7d;
  --easyBox-success-label-border: #3cbe7d;
  --easyBox-success-button-border: #0090ff;


  --easyBox-label-bkg: #2b3441;
  --easyBox-label-border: #4a596e;

  --easyCalendar-interval-border: #4a596e;
  --easyCalendar-input-bkg: #2C3441;
  --easyCalendar-input-border: #4a596e;
  --easyCalendar-input-active: #3A4656;

  --animation-glow-blue-color: #0290ff;
  --animation-glow-orange-color: #E2AA56;
  --animation-glow-alert-color: #dc4245;
  --animation-glow-person-color: #7C9CB5;
  --animation-glow-pink-color: #db5aee;

  --cardUser-role-yellow: #e2aa56;
  --cardUser-role-blue: #0090ff;
  --cardUser-role-pink: #db5aee;

  --building-img-filter: invert(57%) sepia(14%) saturate(210%) hue-rotate(197deg) brightness(96%) contrast(88%);

  --spinner-warning-base: rgb(226, 170, 86);
  --spinner-warning-20: rgba(226, 170, 86, 0.2);
  --spinner-warning-50: rgba(226, 170, 86, 0.5);
  --spinner-warning-70: rgba(226, 170, 86, 0.7);
  --spinner-error-base: rgb(220, 66, 69);
  --spinner-error-20: rgba(220, 66, 69, 0.2);
  --spinner-error-50: rgba(220, 66, 69, 0.5);
  --spinner-error-70: rgba(220, 66, 69, 0.7);

  --profile-backgroud: #050f1e;
  --profile-border: #24272d;
  --profile-separator: rgba(125, 156, 181, 0.2);

  --easyChoose-border: rgba(125, 156, 181, 0.2);
  --easyChoose-border-active: #3935d8;

  --scrollbar-primary-active: #505D73;
  --scrollbar-primary: #222f43;
  --scrollbar-blue-active: rgba(0, 144, 255, 0.4);
  --scrollbar-blue: rgba(0, 144, 255, 0.3);
  --scrollbar-orange-active: rgba(226, 170, 86, 0.4);
  --scrollbar-orange: rgba(226, 170, 86, 0.3);
  --scrollbar-pink-active: rgba(219, 90, 238, 0.4);
  --scrollbar-pink: rgba(219, 90, 238, 0.3);

  --scrollbar-grey-active: #626b7e;
  --scrollbar-grey: #505D73;

  --badge-error-bkg-color: #DC4245;
  --badge-error-text-color: white;

  --call-item-bkg: #202a39;
  --call-item-bkg-hover: #2c374f;
  --call-item-border: #4a596e;
}
