@import "breakpoints";
@import "colors";
@import "fonts";
@import "swal";
@import "animations";
@import "spinner";
@import "toastify";
@import "cards";
@import "scrollbar";

body {
  padding: 0;
  margin: 0;
  background-color: var(--background-secondary);

  &.profileNavigationShown, &.dialModalShown, &.smsModalShown {
    overflow: hidden;
  }

  &:not(.mobileNavigationShown) {
    .layout-left {
      display: none;
      @media (min-width: $breakpoint-laptop) {
        display: block;
      }
    }
  }
  &.laptopNavigationHidden {
    .layout-left {
      @media (min-width: $breakpoint-laptop) {
        transform: translateX(-170px);
      }
      @media (min-width: $breakpoint-fullHD) {
        transform: translateX(-265px);
      }
    }
    .layout-right {
      margin-left: calc(268px - 168px);
      @media (min-width: $breakpoint-fullHD) {
        margin-left: calc(348px - 255px);
      }
    }

    .NavigationItem-link-badge {
      display: none;
    }

    .Navigation-logo {
      justify-content: flex-end;
      padding-right: 12px;
      &-text {
        display: none;
      }
    }
    .NavigationItem-link {
      justify-content: flex-end;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    .Navigation-header-base {
      margin-bottom: 0;
    }

    .Navigation-header-settings,
    .NavigationItem-link-text {
      display: none;
    }

    .Navigation-header-base {
      & .justInMinimized {
        display: block;
      }
    }
    .Navigation-header, .Navigation-scroll {
      padding-right: 13px;
    }
  }
}

body, html, #root, main {
  height: 100%;
}

.formError {
  color: var(--text-error);
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
}

.formErrors {
  margin-top: 10px;
  margin-bottom: 10px;
  &-item {
    color: var(--text-error);
    font-size: 14px;
    font-weight: 500;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.layout {
  display: flex;
  min-height: calc(100% - 20px);
  &-flexHeight {
    height: initial;
  }
  &-left {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
    @media (min-width: $breakpoint-tablet) {
      width: 402px;
    }
    @media (min-width: $breakpoint-laptop) {
      width: 268px;
    }
    @media (min-width: $breakpoint-fullHD) {
      width: 348px;
    }
  }
  &-right {
    width: 100%;
    margin-top: 0;
    background-color: var(--background-ternary);
    border-radius: 10px;
    margin-right: 0;
    border: 1px solid var(--background-ternary-border);
    min-height: calc(100% - 110px);
    padding: 30px 16px 60px;
    margin-left: 0;
    transition: margin-left 0.3s ease-in-out;
    @media (min-width: $breakpoint-laptop) {
      margin-left: 268px;
      margin-right: 15px;
      margin-top: 15px;
      padding-bottom: 0;
    }
    @media (min-width: $breakpoint-fullHD) {
      padding-left: 48px;
      padding-right: 48px;
      min-height: calc(100% - 140px);
      margin-left: 348px;
      padding-bottom: 0;
    }
    &-displayError {
      margin-bottom: 30px;
    }
    &-content {
      &-flex {
        display: flex;
        flex-wrap: wrap;
        @media (min-width: $breakpoint-laptop) {
          flex-wrap: nowrap;
        }
        & .layout-right-content-item {
          &:not(:last-child) {
            @media (min-width: $breakpoint-laptop) {
              margin-right: 30px;
            }
          }
        }
      }
      &.fullHeight {
        height: calc(100% - 138px);
        @media (min-width: $breakpoint-tablet) {
          height: calc(100% - 224px);
        }
        @media (min-width: $breakpoint-laptop) {
          height: calc(100% - 95px);
        }
        @media (min-width: $breakpoint-fullHD) {
          height: calc(100% - 120px);
        }
      }
      &.flex {
        display: flex;
        & .layout-right-content-item {
          &:not(:last-child) {
            @media (min-width: $breakpoint-laptop) {
              margin-right: 30px;
            }
          }
        }
      }
      &-clearfix {
        height: 30px;
        width: 100%;
      }
      &-item {
        margin-bottom: 30px;
        &.fill {
          flex-grow: 1;
        }
        &.third {
          width: 100%;
          @media (min-width: $breakpoint-laptop) {
            width: 38%;
          }
          @media (min-width: $breakpoint-fullHD) {
            width: 26.1%;
          }
        }
        &.withoutThird {
          width: 100%;
          @media (min-width: $breakpoint-laptop) {
            width: 62%;
          }
          @media (min-width: $breakpoint-fullHD) {
            width: 73.9%;
          }
        }
        &.half {
          width: 100%;
          @media (min-width: $breakpoint-laptop) {
            width: 50%;
          }
        }
        &.fullHeight {
          height: initial;
        }
        &.center {
          display: flex;
          justify-content: center;
        }
        &-card {
          @include Card();
        }
      }
    }
  }
}

.layoutCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
