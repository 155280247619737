@import "breakpoints";

@mixin InputFlex {
  @media (min-width: $breakpoint-tablet) {
    display: flex;
    justify-content: space-between;
  }

  & .InputText, & .CustomSelect {
    &:not(:last-child) {
      margin-right: 30px;
    }
    width: 100%;
    @media (min-width: $breakpoint-tablet) {
      width: calc(50% - 15px);
    }
  }
}

@mixin InputImage {
  position: absolute;
  right: 5px;
  width: 17px;
  height: 40px;
  top: 0;
  @media (min-width: $breakpoint-tablet) {
    right: 10px;
    height: 60px;
  }
  @media (min-width: $breakpoint-laptop) {
    height: 46px;
    right: 21px;
  }
  @media (min-width: $breakpoint-fullHD) {
    height: 57px;
    width: 21px;
  }
  &.withLabel {
    height: 40px;
    top: 22px;
    @media (min-width: $breakpoint-tablet) {
      height: 60px;
      top: 27px;
    }
    @media (min-width: $breakpoint-laptop) {
      height: 46px;
      top: 24px;
    }
    @media (min-width: $breakpoint-fullHD) {
      height: 65px;
      top: 29px;
    }
  }
}