@import "../../../styles/breakpoints";

.DialMenu {
  & .Empty-flat {
    margin-bottom: 30px;
  }
  &-content {
    &-header {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 42px;
      color: var(--text-secondary);
    }
    &-list {
      &-item {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--call-item-bkg);
        border: 1px solid var(--call-item-border);
        padding-left: 21px;
        padding-right: 18px;
        height: 48px;
        border-radius: 8px;
        color: transparent;
        &:hover {
          background-color: var(--call-item-bkg-hover);
        }
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        &-left {
          color: var(--text-secondary);
          font-size: 14px;
          font-weight: 500;
        }
        &-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          &-num {
            color: var(--text-secondary);
            font-size: 14px;
            font-weight: 500;
            margin-right: 20px;
          }
          &-close {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}