@import "../../../styles/breakpoints";

@mixin LeftPadding {
  padding-left: 47px;
  @media (min-width: $breakpoint-laptop) {
    padding-left: 27px;
  }
  @media (min-width: $breakpoint-fullHD) {
    padding-left: 31px;
  }
}

.Navigation {
  background-color: var(--background-secondary);
  height: 100%;
  @media (min-width: $breakpoint-laptop) {
    padding-top: 30px;
  }
  &-scroll {
    height: 33px;
    display: none;
    margin-bottom: 28px;
    @media (min-width: $breakpoint-laptop) {
      display: flex;
    }
    &-icon {
      position: absolute;
      right: -16px;
      cursor: pointer;
      background-color: var(--navigation-minimize-bkg);
      border-radius: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 17px;
      height: 17px;
      padding: 8px;
      &.revert {
        transform: rotate(180deg);
      }
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &-close {
    height: 86px;
    padding-left: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @media (min-width: $breakpoint-laptop) {
      display: none;
    }
    &-icon {
      margin-right: 19px;
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &-logo {
    display: flex;
    align-items: center;
    padding-left: 32px;
    margin-bottom: 20px;
    @media (min-width: $breakpoint-laptop) {
      padding-left: 13px;
    }
    &-text {
      color: var(--background-secondary-text);
      font-weight: 600;
      font-size: 24px;
      text-transform: uppercase;
      @media (min-width: $breakpoint-fullHD) {
        font-size: 28px;
      }
    }
    &-image {
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-header {
    padding-right: 20px;
    height: calc(100vh - 280px);
    overflow-y: auto;
    &-base {
      margin-bottom: 36px;
    }
    &-settings {
      &-title {
        color: var(--text-primary);
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 23px;
        letter-spacing: 3.2px;
        @include LeftPadding();
        @media (min-width: $breakpoint-fullHD) {
          font-size: 16px;
        }
      }
      &-info {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @include LeftPadding();
        &-left {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 18px;
          & img {
            height: 62px;
            width: 62px;
          }
        }
        &-right {
          &-name {
            color: var(--text-secondary);
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 16px;
            @media (min-width: $breakpoint-fullHD) {
              font-size: 18px;
            }
          }
        }
      }
      &-button {
        @include LeftPadding();
      }
    }
  }
}