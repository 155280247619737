@import "../../../styles/breakpoints";

.Label {
  border-radius: 6px;
  padding: 7px 14px;
  font-size: 9px;
  font-weight: 600;
  text-transform: uppercase;
  width: fit-content;
  @media (min-width: $breakpoint-fullHD) {
    font-size: 12px;
    padding: 10px 22px;
    border-radius: 8px;
  }
  &.red {
    background-color: var(--background-red-transparent);
    color: var(--text-red);
  }
}