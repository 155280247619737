@import "../../../styles/cards";
@import "../../../styles/parameters";


.AlertInfo {
  @include Card();
  position: relative;
  &-content {
    &-title {
      &-left {
        margin-bottom: 20px;
      }
    }
    &-parameters {
      @include parameters();
      justify-content: flex-start;
      &-left {

      }
      &-right {
        justify-content: left;
        &-item {
          &-content {
            &-value {
              &-limit {
                display: flex;
                align-items: center;
                margin-right: 10px;
                &-arrow {
                  margin-right: 5px;
                  & img {
                    width: 7px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

