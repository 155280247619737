@import "../../../styles/breakpoints";

.Badge {
  &-inside {
    padding: 3px 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--badge-error-bkg-color);
    color: var(--badge-error-text-color);
    border-radius: 14px;
    @media (min-width: $breakpoint-laptop) {
      padding: 4px 10px;
      border-radius: 12px;
    }
    @media (min-width: $breakpoint-fullHD) {
      padding: 5px 11px;
      border-radius: 14px;
    }
    &-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      @media (min-width: $breakpoint-laptop) {
        font-size: 13px;
        line-height: 15px;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 15px;
        line-height: 17px;
      }
    }
  }
}