@import "../../../styles/breakpoints";
@import "../LoginBlock/LoginBlock";

.ResetBlock {
  @include loginBlock();
  &-logIn {
    text-align: center;
    cursor: pointer;
    color: var(--text-inverted);
    font-size: 14px;
    font-weight: 300;
  }
}