@import "../../../styles/breakpoints";

.GoogleAuthTemplate {
  & .layout-right-content-item-card-content {
    padding-top: 5px;
    @media (min-width: $breakpoint-tablet) {
      padding-top: 7px;
    }
    @media (min-width: $breakpoint-laptop) {
      padding-top: 16px;
    }
    @media (min-width: $breakpoint-fullHD) {
      padding-top: 20px;
    }
  }
}