@import "../../../styles/breakpoints";

.TableColumn {
  &-column {
    display: none;
    @media (min-width: $breakpoint-laptop) {
      display: table-cell;
    }

    &.first {
      padding-left: 25px;
      @media (min-width: $breakpoint-fullHD) {
        padding-left: 50px;
      }
    }

    & .CustomSelect {
      margin-bottom: 0;
      width: 240px;
      padding-right: 20px;
      @media (min-width: $breakpoint-fullHD) {
        width: 250px;
      }

      &-label-select-element__indicator-separator {
        background-color: var(--select-optional-background) !important;
      }

      &-label-select-element__control {
        width: initial;
        border: none !important;
        border-radius: 8px;
        font-size: 13px !important;
        height: 24px !important;
        background-color: var(--select-optional-background) !important;
        @media (min-width: $breakpoint-fullHD) {
          font-size: 18px !important;
          height: 44px !important;
          border-radius: 12px;
        }
      }
    }

    &.dot {
      justify-content: center;
      display: none;
      @media (min-width: $breakpoint-laptop) {
        display: table-cell;
      }
      & .TableColumn-column-container {
        justify-content: flex-start;
      }
    }

    &.action {
      display: none;
      @media (min-width: $breakpoint-laptop) {
        display: table-cell;
      }
      & .TableColumn-column-container {
        justify-content: flex-end;
        padding-right: 25px;
        @media (min-width: $breakpoint-fullHD) {
          padding-right: 50px;
        }
      }
    }

    &-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: var(--table-body-text);
      font-size: 13px;
      font-weight: 400;
      @media (min-width: $breakpoint-fullHD) {
        font-size: 18px;
      }

      &-boolean {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-image {
          height: 12px;
          width: 12px;
          display: flex;
          justify-content: center;
          align-content: center;
          & img {
            width: 100%;
            height: 100%;
          }
        }
        &-text {
          margin-left: 10px;
          font-size: 12px;
          &.yes {
            color: var(--text-success)
          }
          &.no {
            color: var(--text-error)
          }
        }
      }
      &-colorText {
        &.green {
          color: var(--text-success)
        }
        &.yellow {
          color: var(--text-yellow);
        }
        &.orange {
          color: var(--text-orange);
        }
        &.blue {
          color: var(--text-blue);
        }
        &.pink {
          color: var(--text-purple);
        }
        &.default {
          color: var(--text-primary);
        }
      }

      &-actions {
        display: flex;
        align-items: center;

        &-item {
          cursor: pointer;
          position: relative;
          width: 35px;
          height: 35px;
          border: 1px solid var(--button-secondary-border);
          background-color: var(--button-secondary-backgroud);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          &.reActivate {
            & img {
              margin-left: 5px;
              margin-top: 3px;
            }
          }
          &.primary {
            border: 1px solid var(--button-secondary-border);
            background-color: var(--button-secondary-backgroud);
          }
          &.secondary {
            border: 1px solid var(--button-quadrary-border);
            background-color: var(--button-quadrary-backgroud);
          }
          &.ternary {
            border: 1px solid var(--button-delete-border);
            background-color: var(--button-delete-background);
          }
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }

      &-role {
        & .Role-icon {
          width: 15px;
          height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          & img {
            width: 100%;
            height: 100%;
          }
        }
      }

      &-image {
        margin-right: 10px;
        &-item {
          & img {
            width: 35px;
            height: 35px;
            @media (min-width: $breakpoint-fullHD) {
              width: 36px;
              height: 36px;
            }
          }
        }
      }

      &-dot {
        position: relative;
      }

      &-images {
        display: flex;
        justify-content: flex-start;
        &-item {
          position: relative;
          height: 35px;
          width: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--background-default-transparent);
          border-radius: 5px;
          &-image {
            height: 23px;
            width: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
            & img {
              width: 100%;
              height: 100%;
            }
          }
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }

      &-limits {
        display: flex;
        justify-content: flex-start;
        &-limit {
          position: relative;
          display: flex;
          align-items: center;
          &:not(:last-child) {
            margin-right: 10px;
          }
          &.min {
            & .first {
              background-color: var(--background-blue-transparenter);
            }
            & .last {
              color: var(--text-blue);
            }
          }
          &.max {
            & .first {
              background-color: var(--background-red-transparenter);
            }
            & .last {
              color: var(--text-red);
            }
          }
          &-first {
            width: 16px;
            border-radius: 4px;
            margin-right: 8px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (min-width: $breakpoint-fullHD) {
              height: 21px;
              width: 21px;
            }
            & img {
              width: 6px;
              height: 9px;
              @media (min-width: $breakpoint-fullHD) {
                width: 8px;
                height: 12px;
              }
            }
          }
          &-last {
            font-weight: 500;
            color: var(--text-blue);
            font-size: 13px;
            line-height: 15px;
            @media (min-width: $breakpoint-fullHD) {
              font-size: 18px;
              line-height: 21px;
            }
          }
        }
      }

      &-dateTime {
        text-align: left;
        &-date {
          font-size: 11px;
          color: var(--text-primary);
          @media (min-width: $breakpoint-fullHD) {
            font-size: 16px;
          }
        }
        &-time {
          font-size: 13px;
          margin-bottom: 5px;
          color: var(--text-secondary);
          @media (min-width: $breakpoint-fullHD) {
            font-size: 18px;
          }
        }
      }

      &-extremes {
        display: flex;
        justify-content: flex-start;
        &-extreme {
          position: relative;
          display: flex;
          align-items: center;
          border-radius: 6px;
          height: 24px;
          padding-left: 10px;
          padding-right: 10px;
          @media (min-width: $breakpoint-fullHD) {
            height: 35px;
            border-radius: 8px;
          }
          &:not(:last-child) {
            margin-right: 10px;
          }
          &.min {
            background-color: var(--background-blue-transparenter);
            & .first {
              background-color: var(--background-blue-transparent);
            }
            & .last {
              color: var(--text-blue);
            }
          }
          &.max {
            background-color: var(--background-red-transparenter);
            & .first {
              background-color: var(--background-red-transparent);
            }
            & .last {
              color: var(--text-red);
            }
          }
          &-first {
            margin-right: 10px;
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            @media (min-width: $breakpoint-fullHD) {
              width: 21px;
              height: 21px;
            }
            & img {
              width: 6px;
              height: 9px;
              @media (min-width: $breakpoint-fullHD) {
                width: 8px;
                height: 12px;
              }
            }
          }
          &-last {
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            @media (min-width: $breakpoint-fullHD) {
              font-size: 18px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
}
