@import "../../../styles/breakpoints";

.TableRow {
  &-first {
    display: block;
    @media (min-width: $breakpoint-laptop) {
      border-bottom: 1px solid var(--table-row-border);
      border-left: none;
      border-right: none;
      border-top: none;
      border-radius: initial;
      display: table-row;
      background-color: initial;
    }
    @media (min-width: $breakpoint-laptop) {
      height: 52px;
    }
    @media (min-width: $breakpoint-fullHD) {
      height: 60px;
    }
    &:hover {
      background-color: var(--table-selected-row);
    }
    &.link {
      cursor: pointer;
    }
    & .TableColumn {
      &.dot.first {
        & .TableColumn-column-container {
          @media (min-width: $breakpoint-fullHD) {
            padding-left: 25px;
          }
        }
      }
    }
  }
  &-second {
    display: block;
    @media (min-width: $breakpoint-laptop) {
      display: none;
    }
    &-column {
      display: block;
    }
  }
}
