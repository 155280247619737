@import "breakpoints";

.LetterImage {
  width: 100%;
  height: 100%;
  background-color: var(--letter-image-bkg);
  border-radius: 100px;
  &-content {
    padding: 15px;
    display: flex;
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    justify-content: center;
    align-items: center;
    &-letter {
      font-size: 31px;
      color: var(--letter-image-text);
      font-weight: 500;
      @media (min-width: $breakpoint-fullHD) {
        font-size: 50px;
      }
    }
  }
}