@import "../../../styles/modal.scss";

.GoogleAuthCodes {
  &-modal {
    @include ModalSecondary();
    &-content {
      &-codes {
        &-preamble {
          color: var(--text-primary);
          line-height: 1.67;
          letter-spacing: 0.36px;
          font-size: 14px;
          font-weight: 400;
          margin: 0 auto 30px;
          text-align: center;
          max-width: 80%;
          @media (min-width: $breakpoint-tablet) {
            font-size: 16px;
          }
          @media (min-width: $breakpoint-laptop) {
            font-size: 14px;
          }
          @media (min-width: $breakpoint-fullHD) {
            font-size: 18px;
          }
        }
        &-block {
          padding: 18px 24px;
          max-width: calc(80% - 24px - 24px);
          margin: 0 auto;
          background-color: #1c2635;
          color: var(--text-secondary);
          &-item {
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}