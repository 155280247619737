@import "../../../styles/cards";
@import "../../../styles/parameters";

.GoogleAuth {
  &-content {
    display: flex;
    flex-wrap: wrap;
    @media (min-width: $breakpoint-laptop) {
      flex-wrap: nowrap;
    }
    &-left {
      width: 100%;
      @media (min-width: $breakpoint-laptop) {
        width: 300px;
        margin-right: 46px;
      }
      @media (min-width: $breakpoint-fullHD) {
        width: 380px;
      }
      &-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 1.26;
        letter-spacing: 0.54px;
        margin-bottom: 20px;
        color: var(--text-secondary);
        text-align: center;
        @media (min-width: $breakpoint-tablet) {
          font-size: 24px;
        }
        @media (min-width: $breakpoint-laptop) {
          text-align: left;
          font-size: 24px;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 27px;
        }
      }
      &-subTitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.67;
        letter-spacing: 0.36px;
        color: var(--text-primary);
        text-align: center;
        margin: 0 0 20px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 18px;
          max-width: 520px;
          margin: 0 auto 20px;
        }
        @media (min-width: $breakpoint-laptop) {
          text-align: left;
          max-width: initial;
          margin: 0 0 20px;
        }
        @media (min-width: $breakpoint-laptop) {
          font-size: 14px;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 18px;
        }
      }
    }
    &-right {
      width: 100%;
      @media (min-width: $breakpoint-laptop) {
        width: calc(100% - 300px - 46px);
      }
      @media (min-width: $breakpoint-fullHD) {
        width: calc(100% - 375px - 46px);
      }
      &-manage {
        display: flex;
        justify-content: center;
        @media (min-width: $breakpoint-laptop) {
          justify-content: left;
        }
        &-button {
          &:not(:last-child) {
            margin-right: 20px;
          }
          & .Button .element {
            @media (max-width: $breakpoint-tablet) {
              min-width: 160px;
            }
          }
        }
      }
      &-preamble {
        color: var(--text-primary);
        line-height: 1.67;
        letter-spacing: 0.36px;
        font-size: 14px;
        font-weight: 400;
        margin: 0 0 50px;
        text-align: center;
        @media (min-width: $breakpoint-tablet) {
          max-width: 580px;
          margin: 0 auto 50px;
          font-size: 16px;
        }
        @media (min-width: $breakpoint-laptop) {
          margin: 0 0 25px;
          font-size: 14px;
          text-align: initial;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 18px;
          max-width: 815px;
          margin-bottom: 35px;
        }
        & a {
          color: var(--text-secondary);
          text-decoration: underline;
        }
      }
      &-qr {
        @media (min-width: $breakpoint-tablet) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          max-width: 650px;
          margin-bottom: 45px;
        }
        @media (min-width: $breakpoint-laptop) {
          display: initial;
          width: initial;
          max-width: initial;
          margin-bottom: 0;
        }
        @media (min-width: $breakpoint-fullHD) {
          margin-bottom: 45px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: 910px;
          width: 100%;
        }
        &-help {
          max-width: 380px;
          border: solid 1.5px #4a596e;
          padding: 11px 15px 11px 15px;
          border-radius: 8px;
          margin-bottom: 26px;
          @media (min-width: $breakpoint-tablet) {
            margin-bottom: 0;
          }
          @media (min-width: $breakpoint-laptop) {
            max-width: 495px;
            margin-bottom: 26px;
          }
          @media (min-width: $breakpoint-fullHD) {
            padding: 16px 22px 16px 22px;
            margin-bottom: 0;
            max-width: 533px;
          }
          & p {
            font-size: 14px;
            line-height: 1.67;
            letter-spacing: 0.36px;
            color: var(--text-primary);
            &:not(:last-child) {
              margin-bottom: 25px;
              @media (min-width: $breakpoint-fullHD) {
                margin-bottom: 30px;
              }
            }
            @media (min-width: $breakpoint-tablet) {
              font-size: 16px;
            }
            @media (min-width: $breakpoint-laptop) {
              font-size: 14px;
            }
            @media (min-width: $breakpoint-fullHD) {
              font-size: 18px;
            }
            & code {
              background-color: #4a596e;
              padding: 5px 8px;
              font-weight: 600;
              color: var(--text-secondary);
              font-size: 12px;
              @media (min-width: $breakpoint-tablet) {
                font-size: 12px;
              }
              @media (min-width: $breakpoint-laptop) {
                font-size: 12px;
              }
              @media (min-width: $breakpoint-fullHD) {
                font-size: 16px;
              }
            }
          }
        }
        &-code {
          width: 240px;
          height: 240px;
          margin: 0 auto 25px;
          @media (min-width: $breakpoint-tablet) {
            margin: 0;
            width: 200px;
            height: 200px;
          }
          @media (min-width: $breakpoint-laptop) {
            width: 220px;
            height: 220px;
            margin-bottom: 25px;
          }
          @media (min-width: $breakpoint-fullHD) {
            width: 280px;
            height: 280px;
            margin-bottom: 0;
          }

          & img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &-pin {
        @media (min-width: $breakpoint-laptop) {
          max-width: 783px;
        }
        &-form {
          &-inputs {
            margin-bottom: 20px;
            @media (min-width: $breakpoint-laptop) {
              margin-bottom: 0;
            }
            & .InputText-label {
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 20px;
              @media (min-width: $breakpoint-tablet) {
                font-size: 18px;
              }
              @media (min-width: $breakpoint-laptop) {
                font-size: 14px;
              }
              @media (min-width: $breakpoint-fullHD) {
                font-size: 18px;
              }
            }
            &-button {
              & .Button.primary {
                display: flex;
                justify-content: center;
                @media (min-width: $breakpoint-laptop) {
                  justify-content: flex-start;
                }
              }
            }
          }
        }
      }
    }
  }
}

