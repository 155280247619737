@import "../../../styles/breakpoints";
@import "../../../styles/modalNavigation";

.HeaderMobile {
  background-color: black;
  display: flex;
  align-items: center;
  margin: -31px -16px 31px;
  padding: 15px 16px;
  @media (min-width: $breakpoint-laptop) {
    display: none;
  }
  &-navigation {
    @include ModalNavigation();
    &-inside {
      & .ProfileMenu {
        &-content {
          &-top, &-middle, &-bottom {
            padding-left: 33px;
            padding-right: 33px;
          }
          &-middle {
            &-item {
              &-text {
                color: var(--text-secondary);
              }
              &-icon {
                filter: contrast(0.4) brightness(1.3);
              }
            }
          }
        }
      }
    }
  }
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &-left {
      &-item {
        cursor: pointer;
        padding: 5px;
        width: 24px;
        height: 24px;
        background-color: #2b3441;
        border: 2px solid #4a596e;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        @media (min-width: $breakpoint-tablet) {
          width: 39px;
          height: 39px;
        }
        & img {
          width: 16px;
          height: 16px;
          @media (min-width: $breakpoint-tablet) {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    &-right {
      &-profile {
        cursor: pointer;
        width: 38px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: $breakpoint-tablet) {
          width: 60px;
          height: 60px;
        }
        & img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}