@import "../../../styles/breakpoints";

.Graph {

  &-item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  & .highcharts-point {
    stroke: none;
  }

  & .highcharts-legend-navigation {
    fill: var(--text-secondary) !important;
    font-family: 'Roboto', sans-serif;
  }

  & .highcharts-legend.highcharts-no-tooltip {
    left: -12px !important;
  }

  & .highcharts-legend-item-hidden {
    & .legend-item-wrapper {
      opacity: 0.2;
    }
  }

  & .legend-item-wrapper {
    background-color: var(--background-default);
    padding: 7px 10px;
    border-radius: 8px;
    display: flex;
    border: 1px solid var(--background-default-border);
    align-items: center;
    &-square {
      height: 9px;
      width: 9px;
      margin-right: 6px;
      @media (min-width: $breakpoint-fullHD) {
        height: 10px;
        width: 10px;
      }
    }
    &-title {
      color: var(--text-primary);
      font-weight: 500;
      font-size: 8px;
      line-height: 11px;
      @media (min-width: $breakpoint-tablet) {
        font-size: 14px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 12px;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  & .highcharts-tooltip-box {
    fill: var(--background-default) !important;
    stroke: var(--background-default-border);
  }

  & .tooltip-item-wrapper {
    background-color: var(--background-default);
    padding: 8px 10px;

    &-name {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;
      &-square {
        height: 9px;
        width: 9px;
        margin-right: 6px;
        @media (min-width: $breakpoint-fullHD) {
          height: 10px;
          width: 10px;
        }
      }
      &-text {
        color: var(--text-secondary);
        font-size: 9px;
        font-family: 'Roboto', sans-serif;
        line-height: 11px;
        @media (min-width: $breakpoint-fullHD) {
          font-size: 12px;
          line-height: 10px;
        }
      }
    }
    &-value {
      color: var(--text-secondary);
      font-size: 22px;
      font-family: 'Roboto', sans-serif;
      line-height: 19px;
      margin-bottom: 10px;
      font-weight: 500;
      @media (min-width: $breakpoint-fullHD) {
        font-size: 36px;
        line-height: 29px;
      }
      & .unit {
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        line-height: 19px;
        @media (min-width: $breakpoint-fullHD) {
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
    &-date {
      color: var(--text-primary);
      font-size: 9px;
      font-family: 'Roboto', sans-serif;
      line-height: 11px;
      @media (min-width: $breakpoint-fullHD) {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}