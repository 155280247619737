@import "../../../styles/breakpoints";
@import "../../../styles/button";

.Button {
  position: relative;
  user-select: none;
  &-options {
    &-textCentered {
      & .element {
        justify-content: center !important;
      }
    }
    &-positionCentered {
      display: flex;
      justify-content: center;
    }
  }
  &.text {
    & .element {
      cursor: pointer;
      background-color: transparent;
      border: none;
    }
  }
  &.primary {
    @include Primary();
  }

  &.ternary {
    @include Primary();
    & .element {
      background-color: var(--button-ternary-backgroud);
      border: 1px solid var(--button-ternary-border);
      &:not(.disabled):active {
        background-color: var(--button-ternary-backgroud-active);
        border-color: var(--button-ternary-border-active);
      }
      &:not(.disabled):not(:active):hover {
        background-color: var(--button-ternary-backgroud-hover);
        border-color: var(--button-ternary-border-hover);
      }
    }
  }

  &.create {
    @include Create();
    & .element {
      background-color: var(--button-primary-backgroud);
      border: 1px solid var(--button-primary-border);
      &:not(.disabled):active {
        background-color: var(--button-primary-backgroud-active);
        border-color: var(--button-primary-border-active);
      }
      &:not(.disabled):not(:active):hover {
        background-color: var(--button-primary-backgroud-hover);
        border-color: var(--button-primary-border-hover);
      }
    }
  }

  &.imageEye {
    @include Image();
    & .element {
      background-color: var(--button-ternary-backgroud);
      border: 1px solid var(--button-ternary-border);
      &:not(.disabled):active {
        background-color: var(--button-ternary-backgroud-active);
        border-color: var(--button-ternary-border-active);
      }
      &:not(.disabled):not(:active):hover {
        background-color: var(--button-ternary-backgroud-hover);
        border-color: var(--button-ternary-border-hover);
      }
      & img {
        display: initial !important;
      }
    }
  }

  &.cancel {
    @include Primary();
    & .element {
      background-color: var(--button-cancel-background);
      border: 1px solid var(--button-cancel-border);
      &:not(.disabled):active {
        background-color: var(--button-cancel-background-active);
        border-color: var(--button-cancel-border-active);
        color: var(--text-inverted);
        & img {
          filter: invert(1);
        }
      }
      &:not(.disabled):not(:active):hover {
        background-color: var(--button-cancel-background-hover);
        border-color: var(--button-cancel-border-hover);
      }
    }
  }

  &.delete {
    @include Primary();
    & .element {
      background-color: var(--button-delete-background);
      border: 1px solid var(--button-delete-border);
      &:not(.disabled):active {
        background-color: var(--button-delete-background-active);
        border-color: var(--button-delete-border-active);
      }
      &:not(.disabled):not(:active):hover {
        background-color: var(--button-delete-background-hover);
        border-color: var(--button-delete-border-hover);
      }
    }
  }

  &.flatDelete {
    & .element {
      cursor: pointer;
      background-color: transparent;
      border: 1px solid var(--button-remove-flat-color);
      border-radius: 3px;
      padding: 5px 10px;
      height: fit-content;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: var(--button-remove-flat-color);
      @media (min-width: $breakpoint-tablet) {
        padding: 7px 10px;
        font-size: 18px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 14px;
        padding: 5px 10px;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 18px;
        padding: 7px 10px;
      }
      & .text {
        display: flex;
        align-items: center;
        height: 12px;
        @media (min-width: $breakpoint-tablet) {
          height: 18px;
        }
        @media (min-width: $breakpoint-laptop) {
          height: 14px;
        }
        @media (min-width: $breakpoint-fullHD) {
          height: 18px;
        }
      }
      & .buttonImage {
        margin-right: 10px;
        height: 12px;
        @media (min-width: $breakpoint-tablet) {
          height: 18px;
        }
        @media (min-width: $breakpoint-laptop) {
          height: 14px;
        }
        @media (min-width: $breakpoint-fullHD) {
          height: 18px;
        }
      }
      &:not(.disabled):active {
        filter: brightness(1.2);
      }
      &:not(.disabled):not(:active):hover {
        filter: brightness(1.1);
      }
    }
  }

  &.secondary {
    @include Secondary();
  }
  & .element {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    & .progress {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      @media (min-width: $breakpoint-laptop) {
        width: 30px;
        height: 30px;
      }
    }
  }
}
