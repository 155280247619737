@import "../../../styles/breakpoints";

.EasyFilter {
  &-laptop {
    display: none;
    @media (min-width: $breakpoint-laptop) {
      display: block;
    }
    &.dropdownMode {
      display: none !important;
    }
    &-content {
      background-color: var(--filter-background);
      border-radius: 10px;
      height: calc(53px - 10px);
      display: flex;
      align-items: center;
      padding: 5px 7px;
      @media (min-width: $breakpoint-fullHD) {
        padding: 6px 7px;
        height: calc(57px - 10px);
      }

      &-item {
        padding: 10px 20px;
        font-size: 12px;
        border-radius: 10px;
        color: var(--filter-text-placeholder);
        cursor: pointer;
        user-select: none;
        &:not(:last-child) {
          margin-right: 5px;
        }

        @media (min-width: $breakpoint-fullHD) {
          font-size: 16px;
          padding: 13px 30px;
        }
        &.yellow {
          color: var(--text-yellow);
        }
        &.blue {
          color: var(--text-blue);
        }
        &.pink {
          color: var(--text-purple);
        }
        &.orange {
          color: var(--text-orange);
        }
        &.green {
          color: var(--text-success);
        }
        &.red {
          color: var(--text-error);
        }
        &.default {
          color: var(--text-default);
        }
        &.active:not(.yellow):not(.blue):not(.pink) {
          color: var(--filter-text-active);
        }
        &.active {
          background-color: var(--filter-background-active);
        }
      }

      &-add {
        cursor: pointer;
        width: 36px;
        height: calc(34px - 2px);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--filter-border);
        border-radius: 7px;
        user-select: none;
        @media (min-width: $breakpoint-fullHD) {
          width: 47px;
          height: calc(45px - 2px);
          border-radius: 12px;
        }
        &-icon {
          width: 14px;
          height: 14px;
          @media (min-width: $breakpoint-fullHD) {
            width: 17px;
            height: 17px;
          }
        }
      }
    }
  }
  &-mobile {
    @media (min-width: $breakpoint-laptop) {
      display: none;
    }
    &.dropdownMode {
      display: block !important;
    }
    &.active {
      & .main {
        border-bottom: 2px solid var(--filter-background);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      & .content {
        display: block;
      }
    }
    &-main {
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: var(--input-background);
      border-radius: 15px;
      border: 2px solid var(--input-border);
      width: calc(180px - 46px);
      height: 34px;
      padding-left: 21px;
      padding-right: 21px;
      font-size: 12px;
      justify-content: space-between;
      color: var(--text-primary);
      @media (min-width: $breakpoint-tablet) {
        height: 49px;
        font-size: 14px;
        width: calc(265px - 46px);
      }
      &-icon {
        & img {
          width: 10px;
          height: 6px;
          @media (min-width: $breakpoint-tablet) {
            width: 14px;
            height: 8px;
          }
        }
      }
    }
    &-content {
      display: none;
      position: absolute;
      background-color: var(--filter-background);
      border-bottom: 2px solid var(--input-border);
      border-left: 2px solid var(--input-border);
      border-right: 2px solid var(--input-border);
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      padding-left: 21px;
      padding-right: 21px;
      margin-top: 0;
      z-index: 2;
      font-size: 12px;
      width: calc(180px - 46px);
      @media (min-width: $breakpoint-tablet) {
        font-size: 14px;
        width: calc(265px - 46px);
      }
      &-inside {
        border-top: 2px solid var(--input-border);
        padding-bottom: 39px;
        padding-top: 26px;
        border-top: 1px solid var(--input-border);
        border-top-left-radius: 0;
        &-item {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--text-secondary);
          &:not(:last-child) {
            margin-bottom: 28px;
          }
        }
      }
    }
  }
}