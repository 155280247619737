@import "../../../styles/modal.scss";

.BuildingPlanUpload {
  &-modal {
    @include ModalSecondary();
    &-content {
      @media (min-width: $breakpoint-tablet) {
        min-height: 200px;
      }
      &-form {
        &-inputs {
          &-button {
            & .Button {
              & .element {
                font-weight: 600;
              }
            }
          }
        }
      }
      &-hint {
        display: flex;
        justify-content: center;
      }
    }
  }
}