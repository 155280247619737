@import "../../../styles/breakpoints";
@import "../../../styles/button";

@keyframes HelpFadeIn {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Help {
  display: none;
  @media (min-width: $breakpoint-laptop) {
    display: block;
  }
  &-trigger {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    z-index: 3;
    opacity: 0;
    &:hover {
      opacity: 1;
      animation: HelpFadeIn 1s ease-in;
    }
    &-content {
      position: relative;
      bottom: -100%;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      &-triangle {
        z-index: 4;
        position: absolute;
        top: 1px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid var(--background-default-border);
        &-sub {
          position: absolute;
          left: -10px;
          width: 0;
          height: 0;
          top: 1.5px;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid var(--background-default);
        }
      }
      &-text {
        padding: 10px;
        position: relative;
        bottom: -10px;
        background-color: var(--background-default);
        border: 1px solid var(--background-default-border);
        min-width: 120px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        & span {
          &:not(:last-child) {
            margin-right: 4px;
          }
        }
        & .accent {
          color: var(--text-success);
        }
      }
    }
  }
}