@keyframes glowBlue {
  0%, 100% { box-shadow: 0 0 8px 2px var(--animation-glow-blue-color); }
  50% { box-shadow: 0 0 8px 5px var(--animation-glow-blue-color); }
}

@keyframes glowOrange {
  0%, 100% { box-shadow: 0 0 8px 2px var(--animation-glow-orange-color); }
  50% { box-shadow: 0 0 8px 5px var(--animation-glow-orange-color); }
}

@keyframes glowAlert {
  0%, 100% { box-shadow: 0 0 8px 2px var(--animation-glow-alert-color); }
  50% { box-shadow: 0 0 8px 5px var(--animation-glow-alert-color); }
}

@keyframes glowWarning {
  0%, 100% { box-shadow: 0 0 8px 2px var(--animation-glow-orange-color); }
  50% { box-shadow: 0 0 8px 5px var(--animation-glow-orange-color); }
}

@keyframes glowPink {
  0%, 100% { box-shadow: 0 0 8px 2px var(--animation-glow-pink-color); }
  50% { box-shadow: 0 0 8px 5px var(--animation-glow-pink-color); }
}

@keyframes glowPerson {
  0%, 100% { box-shadow: 0 0 8px 2px var(--animation-glow-person-color); }
  50% { box-shadow: 0 0 8px 5px var(--animation-glow-person-color); }
}