@import "../../../styles/breakpoints";

.BuildingManagerTemplate {
  & .HardwareLookup {
    margin-bottom: 32px;
  }
  & .layout-right-displayError {
    display: block;
    @media (min-width: $breakpoint-laptop) {
      display: none;
    }
  }
  & .layout-right-content.flex {
    display: none;
    @media (min-width: $breakpoint-laptop) {
      display: flex;
    }
  }
}