@import "../../../styles/cards";

.ListPatient {
  @include Card();
  position: relative;
  &-table {
    margin-bottom: 50px;
  }
  & .FunctionTable {
    & .TableColumn {
      &:nth-of-type(5) {
        display: none;
        @media (min-width: $breakpoint-fullHD) {
          display: table-cell;
        }
      }
      &:nth-of-type(8) {
        & .TableColumn-column-container {
          overflow: hidden;
          @media (min-width: $breakpoint-laptop) {
            width: 128px;
          }
          @media (min-width: $breakpoint-fullHD) {
            width: 175px;
          }
        }
      }
    }
    & .Table-head-row-column {
      &:nth-of-type(1) {
        width: 5%;
        @media (min-width: $breakpoint-fullHD) {
          width: 5%;
        }
      }

      &:nth-of-type(2) {
        width: 12%;
        @media (min-width: $breakpoint-fullHD) {
          width: 12%;
        }
      }

      &:nth-of-type(3) {
        width: 12%;
        @media (min-width: $breakpoint-fullHD) {
          width: 12%;
        }
      }

      &:nth-of-type(4) {
        display: none;
        @media (min-width: $breakpoint-fullHD) {
          display: table-cell;
          width: 12%;
        }
      }

      &:nth-of-type(5) {
        width: 12%;
        @media (min-width: $breakpoint-fullHD) {
          width: 12%;
        }
      }

      &:nth-of-type(6) {
        width: 12%;
        @media (min-width: $breakpoint-fullHD) {
          width: 12%;
        }
      }

      &:nth-of-type(7) {
        width: 8%;
        @media (min-width: $breakpoint-fullHD) {
          width: 10%;
        }
      }

      &:nth-of-type(8) {
        width: 20%;
        @media (min-width: $breakpoint-fullHD) {
          width: 20%;
        }
      }
    }
  }
}