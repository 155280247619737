@import "../../../styles/cards";

.ListHardware {
  @include Card();
  position: relative;
  &-table {
    margin-bottom: 50px;
  }
  & .EasyFilter {
    &-laptop {
      &-content {
        &-item {
          &:nth-child(6) {
            @media (min-width: $breakpoint-laptop) {
              display: none;
            }
            @media (min-width: $breakpoint-laptopHD) {
              display: block;
            }
          }
          &:nth-child(7), &:nth-child(8), &:nth-child(9) {
            @media (min-width: $breakpoint-laptop) {
              display: none;
            }
            @media (min-width: $breakpoint-laptopHDMore) {
              display: block;
            }
          }
        }
      }
    }
  }
  & .FunctionTable {
    & .TableColumn-column {
      &:nth-of-type(7) {
        & .TableColumn-column-container {
          overflow: hidden;
          @media (min-width: $breakpoint-laptop) {
            width: 128px;
          }
          @media (min-width: $breakpoint-fullHD) {
            width: 175px;
          }
        }
      }
    }
    & .Table-head-row-column {
      &:nth-of-type(1) {
        @media (min-width: $breakpoint-laptop) {
          width: 8%;
        }
        @media (min-width: $breakpoint-fullHD) {
          width: 8%;
        }
      }
      &:nth-of-type(2) {
        width: 14%;
        @media (min-width: $breakpoint-fullHD) {
          width: 14%;
        }
      }
      &:nth-of-type(3) {
        @media (min-width: $breakpoint-laptop) {
          width: 10%;
        }
        @media (min-width: $breakpoint-fullHD) {
          width: 12%;
        }
      }
      &:nth-of-type(4) {
        @media (min-width: $breakpoint-laptop) {
          width: 10%;
        }
        @media (min-width: $breakpoint-fullHD) {
          width: 12%;
        }
      }
      &:nth-of-type(5) {
        @media (min-width: $breakpoint-laptop) {
          width: 24%;
        }
        @media (min-width: $breakpoint-fullHD) {
         width: 22%;
        }
      }
    }
  }
}