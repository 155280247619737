@import "breakpoints";

:root {
  --toastify-toast-width: 338px !important;
}

.Toastify__toast {
  color: white !important;
  font-size: 15px !important;
  padding-top: 18px !important;
  padding-left: 23px !important;
  padding-bottom: 24px !important;
  border-radius: 0 !important;
  @media (min-width: $breakpoint-laptop) {
    padding-top: 21px !important;
    padding-bottom: 26px !important;
    padding-left: 27px !important;
    border-radius: 10px !important;
  }
}

.Toastify__toast-body {
  padding: 0 !important;
}

.Toastify__toast--success {
  background-color: var(--background-green-dark) !important;
  border-bottom: 2px solid var(--background-green) !important;
  @media (min-width: $breakpoint-laptop) {
    border-left: 2px solid var(--background-green) !important;
    border-top: 2px solid var(--background-green) !important;
    border-right: 2px solid var(--background-green) !important;
  }
}

.Toastify__toast--error {
  background-color: var(--background-red-dark) !important;
  border-bottom: 2px solid var(--background-red) !important;
  @media (min-width: $breakpoint-laptop) {
    border-left: 2px solid var(--background-red) !important;
    border-right: 2px solid var(--background-red) !important;
    border-top: 2px solid var(--background-red) !important;
  }
}

.Toastify__toast--info {
  background-color: var(--background-blue-dark) !important;
  border-bottom: 2px solid var(--background-blue) !important;
  @media (min-width: $breakpoint-laptop) {
    border-left: 2px solid var(--background-blue) !important;
    border-right: 2px solid var(--background-blue) !important;
    border-top: 2px solid var(--background-blue) !important;
  }
}

.Toastify__close-button {
  opacity: 1 !important;
  & svg {
    fill: white !important;
    height: 16px !important;
    width: 16px !important;
    margin-bottom: -12px !important;
    margin-right: 10px !important;
  }
}

.Toastify__close-button {
  margin-top: -10px !important;
}

.Toastify__toast-container {
  width: 100% !important;
  @media (min-width: $breakpoint-laptop) {
    width: 380px !important;
  }
  &--top-right {
    top: 0 !important;
    right: 0 !important;
    @media (min-width: $breakpoint-laptop) {
      top: initial !important;
      bottom: 1em !important;
      right: 1em !important;
    }
  }
}
