@import "../../../styles/cards.scss";
@import "../../../styles/modal.scss";
@import "../../../styles/labelSelects.scss";
@import "../../../styles/input.scss";


.AddOrEditHardware {
  &-content-form-inputs-flex {
    @include InputFlex();
  }
}

.AddOrEditHardware.modal {
  & .AddOrEditHardware {
    @include ModalSecondary();
  }
}

.AddOrEditHardware {
  &-sensors {
    @include LabelSelects();
    &-content {
      &-item {
        &-image {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          @media (min-width: $breakpoint-tablet) {
            width: 30px;
            height: 30px;
          }
          @media (min-width: $breakpoint-laptop) {
            width: 25px;
            height: 25px;
          }
          @media (min-width: $breakpoint-fullHD) {
            width: 30px;
            height: 30px;
          }
          & img {
            margin-right: 8px;
            width: 100%;
            height: 100%;
            @media (min-width: $breakpoint-tablet) {
              margin-right: 21px;
            }
            @media (min-width: $breakpoint-laptop) {
              margin-right: 12px;
            }
            @media (min-width: $breakpoint-fullHD) {
              margin-right: 21px;
            }
          }
        }
      }
    }
  }
}
