@import "../../../styles/breakpoints";

.Dropdown {
  position: absolute;
  left: -100%;
  z-index: 2;
  &-arrow {
    display: flex;
    justify-content: center;
    &-item {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 8px solid #1d2127;
    }
  }
  &-content {
    background-color: #1d2127;
    border-radius: 10px;
    &-item {
      display: flex;
      align-items: center;
      padding: 15px 16px 15px 18px;
      @media (min-width: $breakpoint-fullHD) {
        padding: 17px 18px 17px 16px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid rgba(141, 142, 154, 0.2);
      }
      &-icon {
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-title {
        white-space: nowrap;
        font-size: 14px;
        color: var(--text-secondary);
        @media (min-width: $breakpoint-fullHD) {
          font-size: 16px;
        }
      }
    }
  }
}