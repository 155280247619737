@mixin DraggableSmall {
  & .block {
    height: 31px;
    width: 32px;
    & .text {
      & > * {
        font-size: 12px;
      }
    }
    & .icon {
      width: 21px;
      height: 21px;
    }
  }
}

@mixin Draggable {
  margin: 8px 7px;
  position: relative;
  &.current.blue {
    .block {
      animation: glowBlue 2s infinite;
    }
  }
  &.current.orange {
    .block {
      animation: glowOrange 2s infinite;
    }
  }
  &.current.pink {
    .block {
      animation: glowPink 2s infinite;
    }
  }
  &.blue.active {
    .block {
      animation: glowBlue 2s infinite;
    }
  }
  &.pink.active {
    .block {
      animation: glowPink 2s infinite;
    }
  }
  &.orange.active {
    .block {
      animation: glowOrange 2s infinite;
    }
  }
  &.person.active {
    .icon {
      & img {
        animation: glowPerson 2s infinite;
        border-radius: 50%;
      }
    }
  }
  &.blue {
    .block {
      background-color: var(--draggable-blue-inactive-bkg);
      border: 1px solid var(--draggable-blue-border);
      &:hover {
        background-color: var(--draggable-blue-active-bkg);
      }
    }
  }
  &.pink {
    .block {
      background-color: var(--draggable-pink-inactive-bkg);
      border: 1px solid var(--draggable-pink-border);
      &:hover {
        background-color: var(--draggable-pink-active-bkg);
      }
    }
  }
  &.orange {
    .block {
      background-color: var(--draggable-orange-inactive-bkg);
      border: 1px solid var(--draggable-orange-border);
      &:hover {
        background-color: var(--draggable-orange-active-bkg);
      }
    }
  }
  &.clickable {
    cursor: pointer;
  }
  &.small {
    @include DraggableSmall();
  }
  &.dragged {
    margin: 0;
    & .placed {
      display: none;
    }
  }
  &.person {
    & .block {
      border-radius: 100px;
      & .icon {
        width: 32px;
        height: 32px;
      }
    }
    & .alert {
      top: -95px;
    }
    & .text {
      bottom: -25px;
    }
  }
  &-placed {
    position: absolute;
    top: 5px;
    right: 7px;
    & img {
      width: 14px;
      height: 9px;
    }
  }
  &-block {
    height: 83px;
    width: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin-bottom: 8px;
    user-select: none;
    &.alertGlow {
      animation: glowAlert 2s infinite;
    }
    &.warningGlow {
      animation: glowWarning 2s infinite;
    }
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 57px;
      height: 57px;
      & img {
        width: 100%;
        height: 100%;
      }
    }
    &-bubble {
      position: absolute;
      top: -96px;
      height: 90px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &.error {
        & .inside {
          border: solid 1px var(--draggable-bubble-alert);
        }
        & .title {
          background-color: var(--draggable-bubble-alert);
        }
        & .triangle {
          border-top-color: var(--draggable-bubble-alert);
        }
      }
      &.warning {
        & .inside {
          border: solid 1px var(--draggable-bubble-warning);
        }
        & .title {
          background-color: var(--draggable-bubble-warning);
        }
        & .triangle {
          border-top-color: var(--draggable-bubble-warning);
        }
      }
      &-inside {
        width: 200px;
        background-color: #202a39;
        border-radius: 8px;
        border: solid 1px #4a596e;
        &-triangle {
          position: absolute;
          left: calc((200px / 2) - 5px);
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #4a596e;
          &-sub {
            position: absolute;
            left: -3px;
            top: -5px;
            width: 0;
            height: 0;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-top: 3px solid #202a39;
          }
        }
        &-title {
          height: 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-top-right-radius: 7px;
          border-top-left-radius: 7px;
          background-color: #2b3441;
          &-text {
            font-size: 12px;
            font-weight: 500;
            color: var(--text-secondary);
          }
          &-icon {
            height: 10px;
            width: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
            & img {
              height: 100%;
            }
          }
        }
        &-content {
          display: flex;
          align-items: center;
          padding: 10px 15px 10px;

          &-inside {
            width: 100%;
            &-name {
              font-size: 12px;
              color: var(--text-secondary);
              margin-bottom: 8px;
            }
            &-locating {
              border-top: 1px solid var(--draggable-divider);
              padding-top: 7px;
              margin-top: 10px;
              display: flex;
              align-items: center;
              &.ACTIVE {
                color: var(--text-orange);
              }
              &.FAILED {
                color: var(--text-error);
              }
              &-text {
                font-size: 12px;
                font-weight: 500;
                margin-right: 5px;
              }
              &-tick {
                display: flex;
                align-items: center;
                margin-right: 5px;
                & img {
                  height: 6px;
                  width: 10px;
                }
              }
            }
            &-empty {
              width: 100%;
              &-sensors {
                font-size: 12px;
                color: var(--text-secondary);
              }
              &-lastCommunication {
                margin-top: 3px;
                font-size: 12px;
                color: var(--text-primary);
              }
            }
            &-first {
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              &-item {
                width: 50%;
                &-values {

                  display:flex;
                  align-items: center;

                  &-value {
                    font-size: 12px;
                    font-weight: 500;
                    margin-right: 3px;
                    color: var(--text-secondary);
                  }

                  &-icon {
                    height: 14px;
                    display: flex;
                    margin-right: 5px;
                    justify-content: flex-start;
                    align-items: center;

                    & img  {
                      height: 100%;
                    }
                  }

                  &-unit {
                    font-size: 12px;
                    font-weight: 500;
                    color: var(--text-secondary);
                  }
                }
                &-updated {
                  margin-top: 3px;
                  font-size: 12px;
                  color: var(--text-primary);
                }
              }
            }
          }
        }
      }
    }
    &-controls {
      position: absolute;
      top: -105px;
      height: 90px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &-item {
        &:not(:last-child) {
          margin-right: 10px;
        }
        &.remove {
          & .Button {
            & .element {
              background-color: var(--draggable-remove-color);
            }
          }
        }
        &.detail {
          & .Button {
            & .element {
              background-color: var(--draggable-detail-color);
            }
          }
        }
      }
    }
    &-text {
      width: max-content;
      position: absolute;
      bottom: -35px;
      height: 30px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      &-item {
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: var(--text-secondary);
      }
    }
  }
  &-title {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: var(--text-secondary);
  }
}
