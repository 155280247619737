@import "breakpoints";

@mixin parameters() {
  display: flex;
  flex-wrap: wrap;
  @media (min-width: $breakpoint-laptop) {
    flex-wrap: nowrap;
  }
  &-left {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 56px;
    @media (min-width: $breakpoint-laptop) {
      text-align: left;
      width: initial;
      margin-bottom: 0;
      margin-left: 70px;
      margin-right: 111px;
    }
    @media (min-width: $breakpoint-fullHD) {
      margin-right: 191px;
      margin-left: 144px;
    }
    &-image {
      width: 120px;
      height: 144px;
      @media (min-width: $breakpoint-tablet) {
        width: 224px;
        height: 269px;
      }
      @media (min-width: $breakpoint-laptop) {
        width: 205px;
        height: 246px;
      }
      @media (min-width: $breakpoint-fullHD) {
        width: 245px;
        height: 293px;
      }
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &-right {
    display: flex;
    justify-content: center;
    width: 100%;
    @media (min-width: $breakpoint-laptop) {
      justify-content: flex-start;
      width: initial;
    }
    &-item {
      height: 70px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      &:not(:last-child) {
        @media (min-width: $breakpoint-laptop) {
          border-bottom: 1px solid var(--parameters-divider);
        }
      }
      &-content {
        &-name {
          width: 100%;
          font-size: 12px;
          color: var(--parameters-placeholder);
          margin-bottom: 5px;
          @media (min-width: $breakpoint-tablet) {
            font-size: 14px;
          }
          @media (min-width: $breakpoint-laptop) {
            font-size: 12px;
          }
          @media (min-width: $breakpoint-fullHD) {
            font-size: 12px;
          }
        }
        &-value {
          font-size: 14px;
          font-weight: 500;
          @media (min-width: $breakpoint-tablet) {
            font-size: 18px;
          }
          @media (min-width: $breakpoint-laptop) {
            font-size: 13px;
          }
          @media (min-width: $breakpoint-fullHD) {
            font-size: 16px;
          }
          &.link {
            cursor: pointer;
            text-decoration: underline;
          }
          &.orange {
            color: var(--text-orange);
          }
          &.blue {
            color: var(--text-blue);
          }
          &.green {
            color: var(--text-success);
          }
          &.red {
            color: var(--text-error);
          }
          &.pink {
            color: var(--text-pink);
          }
          &.default {
            color: var(--text-secondary);
          }
          &.flex {
            display: flex;
          }
          & button {
            appearance: none;
            line-height: 1;
            display: inline;
            background-color: transparent;
            border: none;
            color: var(--text-secondary);
            padding: 0;
            margin: 0;
          }
          & .Role {
            &-icon {
              width: 14px;
              height: 14px;
              @media (min-width: $breakpoint-tablet) {
                width: 18px;
                height: 18px;
              }
              @media (min-width: $breakpoint-laptop) {
                width: 13px;
                height: 13px;
              }
              @media (min-width: $breakpoint-fullHD) {
                width: 16px;
                height: 16px;
              }
            }
            &-title {
              font-size: 14px;
              font-weight: 500;
              @media (min-width: $breakpoint-tablet) {
                font-size: 18px;
              }
              @media (min-width: $breakpoint-laptop) {
                font-size: 13px;
              }
              @media (min-width: $breakpoint-fullHD) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    &-first {
      @media (min-width: $breakpoint-tablet) {
        min-width: 200px;
      }
      & .item {
        padding-right: 20px;
        @media (min-width: $breakpoint-tablet) {
          padding-right: 80px;
        }
        @media (min-width: $breakpoint-laptop) {
          padding-right: 40px;
          border-right: 1px solid var(--parameters-divider);
        }
      }
    }
    &-second {
      @media (min-width: $breakpoint-tablet) {
        min-width: 200px;
      }
      & .item {
        padding-left: 20px;
        @media (min-width: $breakpoint-tablet) {
          padding-left: 78px;
        }
      }
    }
  }
}
