@import "../../../styles/modal";

.HardwareQRGen {
  @include ModalSecondary();
  &-content {
    &-gen {
      display: flex;
      justify-content: center;
      align-content: center;
      width: 100%;
      @media (min-width: $breakpoint-tablet) {
        height: 100%;
        padding-top: 60px;
      }
      &-inside {
        &-download {
          padding-top: 20px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

