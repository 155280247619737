/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-primary);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-primary-active);
}

::-webkit-scrollbar-corner {
  background: rgba(0,0,0,0);
}

@mixin ScrollBarBlue {
  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-blue);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-blue-active);
  }
}

@mixin ScrollBarGrey {
  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-grey);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-grey-active);
  }
}

@mixin ScrollBarOrange {
  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-orange);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-orange-active);
  }
}

@mixin ScrollBarPink {
  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-pink);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-pink-active);
  }
}

@mixin FilterBottomSpace {
  &::-webkit-scrollbar-track {
    margin-bottom: 8px;
  }
}

@mixin FilterTopSpace {
  &::-webkit-scrollbar-track {
    margin-top: 8px;
  }
}