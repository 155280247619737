@import "../../../styles/calendar";
@import "../../../styles/filter";
@import "../../../styles/breakpoints";

.EasyCalendar {
  @include Filter();
  & .FilterMain {
    @media (min-width: $breakpoint-laptop) {
      width: calc(280px - 46px);
    }
    @media (min-width: $breakpoint-fullHD) {
      width: calc(300px - 46px);
    }
    &-mobileSearch {
      width: calc(100% - 34px - 30px);
      padding-left: 34px;
      padding-right: 30px;
    }
  }
  &-content {
    @media (min-width: $breakpoint-laptop) {
      width: calc(280px - 46px);;
    }
    @media (min-width: $breakpoint-fullHD) {
      width: calc(300px - 46px);
    }
    &-inside {
      &-calendar {
        &-intervals, &-range, &-includedCalendar {
          padding: 20px 10px 10px;
          @media (min-width: $breakpoint-tablet) {
            padding: 20px 23px 10px;
          }
          @media (min-width: $breakpoint-laptop) {
            padding: 0;
          }
        }

        &-intervals {
          margin-bottom: 20px;
          border-bottom: 1px solid var(--easyCalendar-interval-border);

          &-item {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            opacity: 0.7;
            padding-left: 30px;
            cursor: pointer;
            @media (min-width: $breakpoint-laptop) {
              padding-left: 0;
            }
            @media (min-width: $breakpoint-fullHD) {
              font-size: 16px;
            }

            &.withTick {
              padding-left: 0;
              opacity: 1;

              & .tick {
                display: block;
              }
            }

            &:hover {
              opacity: 1;
            }

            &-tick {
              display: none;
              margin-right: 12px;

              & img {
                width: 18px;
                height: 14px;
              }
            }

            &-text {
              font-size: 16px;
              @media (min-width: $breakpoint-tablet) {
                font-size: 16px;
              }
              @media (min-width: $breakpoint-laptop) {
                font-size: 12px;
              }
              @media (min-width: $breakpoint-fullHD) {
                font-size: 16px;
              }
            }
          }
        }

        &-range {
          margin-bottom: 20px;

          &-title {
            display: flex;
            align-items: center;
            padding-left: 32px;
            margin-bottom: 10px;
            opacity: 0.7;
            @media (min-width: $breakpoint-tablet) {
              padding-left: 30px;
            }
            @media (min-width: $breakpoint-laptop) {
              padding-left: 0;
            }

            &.withTick {
              padding-left: 11px;
              opacity: 1;

              & .tick {
                display: block;
              }
            }

            &-tick {
              display: none;
              margin-right: 12px;

              & img {
                width: 18px;
                height: 14px;
              }
            }

            &-text {
              font-size: 16px;
              @media (min-width: $breakpoint-tablet) {
                font-size: 16px;
              }
              @media (min-width: $breakpoint-laptop) {
                font-size: 12px;
              }
              @media (min-width: $breakpoint-fullHD) {
                font-size: 16px;
              }
            }
          }

          &-items {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 30px;
            @media (min-width: $breakpoint-laptop) {
              padding: 0;
            }

            &-item {
              width: 149px;
              @media (min-width: $breakpoint-tablet) {
                width: 320px;
              }
              &:not(:last-child) {
                margin-right: 10px;
              }

              & input {
                height: 26px;
                width: calc(100% - 10px);
                background-color: var(--easyCalendar-input-bkg);
                border: solid 1px var(--easyCalendar-input-border);
                outline: none;
                color: white;
                padding-left: 10px;
                &.active {
                  background-color: var(--easyCalendar-input-active);
                }
              }
            }
          }
        }

        &-includedCalendar {
          @include Calendar();
          padding-bottom: 20px;

          & .react-calendar {
            border: none;
            border-radius: 10px;
            width: 100% !important;
            padding-left: 11px;
            @media (min-width: $breakpoint-tablet) {
              width: 257px;
            }
            @media (min-width: $breakpoint-laptop) {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}
