@import "../../../styles/breakpoints";

.InputCheckbox {
  height: 20px;
  &-content {
    &-label {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &:hover {
        & input {
          & ~ .mark {
            filter: brightness(1.2);
          }
        }
      }
      &-text {
        font-size: 12px;
        color: var(--checkbox-label);
        position: absolute;
        top: 5px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 16px;
          top: 3px;
        }
        @media (min-width: $breakpoint-laptop) {
          font-size: 14px;
          top: 4px;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 16px;
          top: 3px;
        }
      }
      &-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        z-index: 1;
        &:checked {
          & ~ .mark:after {
            display: block;
          }
        }
      }

      &-mark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: var(--checkbox-bkg);
        border: 1px solid var(--checkbox-border);
        border-radius: 4px;
        &:after {
          content: "";
          position: absolute;
          display: none;
          left: 6px;
          top: 2px;
          width: 5px;
          height: 10px;
          border: solid var(--checkbox-tick);
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}
