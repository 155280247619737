@import "../../../styles/breakpoints";
@import "../../../styles/input";
@import "../../../styles/calendar";

.CustomDate {
  position: relative;
  &.nok {
    & .react-date-picker__wrapper {
      border: 2px solid var(--background-red);
    }
    & .error {
      opacity: 1;
    }
  }
  &.ok {
    & .react-date-picker__wrapper {
      border: 2px solid var(--background-green);
    }
  }
  & .image {
    @include InputImage();
  }
  & .error {
    margin-top: 10px;
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    @media (min-width: $breakpoint-tablet) {
      font-size: 15px;
    }
    @media (min-width: $breakpoint-laptop) {
      font-size: 12px;
    }
    @media (min-width: $breakpoint-fullHD) {
      font-size: 16px;
    }
    color: var(--text-error);
  }
  &-label {
    &-block {
      margin-bottom: 10px;
      color: var(--input-label);
      font-weight: 400;
      font-size: 12px;
      @media (min-width: $breakpoint-tablet) {
        font-size: 15px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 12px;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 16px;
      }
    }
  }
  @include Calendar();
}
