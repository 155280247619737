@import "../../../styles/cards";

.ListZones {
  @include Card();
  position: relative;
  &-table {
    margin-bottom: 50px;
  }
  & .FunctionTable {
    & .Table-head-row-column {
      // State
      &:nth-of-type(1) {
        @media (min-width: $breakpoint-laptop) {
          width: 8%;
        }
        @media (min-width: $breakpoint-fullHD) {
          width: 8%;
        }
      }
      // Alarm
      &:nth-of-type(2) {
        width: 17%;
        @media (min-width: $breakpoint-fullHD) {
          width: 17%;
        }
      }
    }
  }
}
