@import "../../../styles/modal.scss";
@import "../../../styles/labelSelects.scss";

.AddOrEditUser {
    @include ModalSecondary();
    &-roles {
        @include LabelSelects();
    }
}

