@import "../../../styles/modal.scss";

.UserNotifyOptions {
    @include ModalSecondary();
    &-content {
        &-form {
            &-inputs {
                margin-bottom: 40px;
                &-flex {
                    margin-bottom: 10px;
                    margin-top: 10px;

                    .InputCheckbox {
                        width: 50%;
                    }
                }
                &-type {
                        margin-bottom: 40px;

                    &-title {
                        color: var(--text-secondary);
                        font-size: 16px;
                        font-weight: 500;
                        @media (min-width: $breakpoint-tablet) {
                            font-size: 21px;
                        }
                        @media (min-width: $breakpoint-laptop) {
                            font-size: 16px;
                        }
                        @media (min-width: $breakpoint-fullHD) {
                            font-size: 16px;
                        }
                    }
                    &-hint {
                        color: var(--text-default);
                        font-size: 14px;
                        margin-top: 8px;
                        @media (min-width: $breakpoint-tablet) {
                            font-size: 21px;
                        }
                        @media (min-width: $breakpoint-laptop) {
                            font-size: 14px;
                        }
                        @media (min-width: $breakpoint-fullHD) {
                            font-size: 14px;
                        }
                    }
                    &-escalating {
                        margin-top: 24px;
                    }
                }
            }
        }
    }
}

