@import "cards";
@import "breakpoints";

@mixin CardInfo {
  @include Card();
  min-height: 397px;
  @media (min-width: $breakpoint-laptop) {
    min-height: 550px;
  }
  @media (min-width: $breakpoint-fullHD) {
    min-height: 612px;
  }
  &-content {
    &-info {
      display: flex;
      flex-wrap: wrap;
      margin-left: -8px;
      margin-right: -8px;
      &-block {
        width: calc(50% - 16px);
        margin-bottom: 46px;
        padding-right: 8px;
        padding-left: 8px;
        &-title {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 500;
          color: var(--card-patient-title);
          margin-bottom: 7px;
          line-height: 11px;
          letter-spacing: 1.44px;
          @media (min-width: $breakpoint-tablet) {
            font-size: 14px;
            margin-bottom: 11px;
          }
          @media (min-width: $breakpoint-laptop) {
            font-size: 9px;
            margin-bottom: 7px;
          }
          @media (min-width: $breakpoint-fullHD) {
            font-size: 12px;
            margin-bottom: 11px;
          }
        }
        &-value {
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          @media (min-width: $breakpoint-tablet) {
            font-size: 21px;
          }
          @media (min-width: $breakpoint-laptop) {
            font-size: 14px;
          }
          @media (min-width: $breakpoint-fullHD) {
            font-size: 20px;
          }
          &.yes {
            color: var(--text-success);
          }
          &.no {
            color: var(--text-error);
          }
        }
      }
    }
  }
}
