@import "../../../styles/breakpoints";

.WelcomeTemplate {
  height: 100%;
  &-content {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    @media (min-width: $breakpoint-laptop) {
      flex-wrap: initial;
    }
    &-left {
      width: calc(100% - 32px - 32px);
      background-color: var(--background-inverted);
      padding: 30px 32px 60px;
      @media (min-width: $breakpoint-tablet) {
        padding: 54px 72px;
        width: calc(100% - 72px - 72px);
      }
      @media (min-width: $breakpoint-laptop) {
        width: calc(50% - 72px - 72px);
      }
      &-logo {
        display: flex;
        align-items: center;
        &-image {
          width: 63px;
          height: 40px;
          margin-right: 5px;
          & img {
            width: 100%;
            height: 100%;
          }
        }
        &-text {
          font-weight: 600;
          font-size: 23px;
          color: var(--text-inverted);
          text-transform: uppercase;
        }
      }
    }
    &-right {
      display: none;
      position: relative;
      overflow: hidden;
      width: 50%;
      background-image: var(--background-welcome-gradient);
      @media (min-width: $breakpoint-laptop) {
        display: block;
      }
      &-image {
        position: absolute;
        overflow: hidden;
        display: flex;
        align-items: center;
        height: 100%;
        left: 149px;
        & img {
          height: 777px;
        }
      }
    }
  }
}
