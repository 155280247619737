@import "breakpoints";

@mixin PrimaryElement {
  cursor: pointer;
  transition: opacity 0.3s ease-in-out, background-color 300ms ease-in-out;
  background-color: var(--button-primary-backgroud);
  height: 43px;
  min-width: 140px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  color: var(--button-primary-text);
  font-size: 12px;
  border: 2px solid var(--button-primary-border);
  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
    height: 65px;
    min-width: 293px;
  }
  @media (min-width: $breakpoint-laptop) {
    font-size: 12px;
    justify-content: center;
    height: 47px;
    min-width: 204px;
  }
  @media (min-width: $breakpoint-fullHD) {
    font-size: 16px;
    min-width: 249px;
    height: 57px;
  }
  &:not(.disabled):active {
    background-color: var(--button-primary-backgroud-active);
    border-color: var(--button-primary-border-active);
  }
  &:not(.disabled):not(:active):hover {
    background-color: var(--button-primary-backgroud-hover);
    border-color: var(--button-primary-border-hover);
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    & .buttonImage {
      filter: initial;
    }
  }
  & .buttonImage {
    display: none;
    margin-right: 10px;
    filter: brightness(100);
    @media (min-width: $breakpoint-tablet) {
      display: block;
      height: 20px;
      width: 20px;
    }
    @media (min-width: $breakpoint-laptop) {
      height: 16px;
      width: 16px;
    }
    @media (min-width: $breakpoint-fullHD) {
      height: 20px;
      width: 20px;
    }
  }
}

@mixin SecondaryElement {
  font-size: 12px;
  cursor: pointer;
  border: 1px solid var(--button-secondary-border);
  background-color: var(--button-secondary-backgroud);
  color: var(--button-secondary-text);
  border-radius: 5px;
  padding: 9px 23px 9px 23px;
  min-height: 30px;
  transition: background-color 300ms ease-in-out;
  @media (min-width: $breakpoint-tablet) {
    min-width: 88px;
  }
  @media (min-width: $breakpoint-tablet) {
    font-size: 14px;
  }
  @media (min-width: $breakpoint-laptop) {
    font-size: 12px;
  }
  @media (min-width: $breakpoint-fullHD) {
    font-size: 14px;
  }
  &:not(.disabled):active {
    background-color: var(--button-secondary-backgroud-active);
    border-color: var(--button-secondary-border-active);
    color: var(--text-inverted);
    & img {
      filter: invert(1);
    }
  }
  &:not(.disabled):not(:active):hover {
    background-color: var(--button-secondary-backgroud-hover);
    border-color: var(--button-secondary-border-hover);
  }
  & img {
    margin-right: 10px;
    filter: brightness(100);
    display: none;
    @media (min-width: $breakpoint-tablet) {
      height: 14px;
      width: 14px;
      display: block;
    }
    @media (min-width: $breakpoint-laptop) {
      height: 12px;
      width: 12px;
    }
    @media (min-width: $breakpoint-fullHD) {
      height: 14px;
      width: 14px;
    }
  }
}

@mixin TernaryElement {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: solid 1px rgba(141, 142, 154, 0.4);
  color: var(--button-secondary-text);
  border-radius: 10px;
  height: 44px;
  width: 188px;
  & img {
    height: 20px;
    width: 20px;
  }
}

@mixin Create {
  & .element {
    @include PrimaryElement();
    width: 38px;
    height: 38px;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 38px;
    @media (min-width: $breakpoint-tablet) {
      width: 53px;
      height: 53px;
      padding: 12px;
      min-width: 53px;
    }
    @media (min-width: $breakpoint-laptop) {
      width: initial;
      padding-left: 20px;
      padding-right: 20px;
    }
    & .buttonImage {
      width: 100%;
      height: 100%;
      margin-right: 0;
      display: inline;
      @media (min-width: $breakpoint-laptop) {
        height: 12px;
        width: 12px;
        margin-right: 10px;
      }
      @media (min-width: $breakpoint-laptop) {
        height: 14px;
        width: 14px;
      }
    }
    & .createImage {
      position: absolute;
      right: 5px;
      bottom: 5px;
      height: 11px;
      width: 11px;
      @media (min-width: $breakpoint-tablet) {
        right: 10px;
        bottom: 10px;
        height: 15px;
        width: 15px;
      }
      @media (min-width: $breakpoint-laptop) {
        display: none;
      }
    }
    & .text {
      display: none;
      @media (min-width: $breakpoint-laptop) {
        display: initial;
      }
    }
  }
}

@mixin Image {
  & .element {
    @include PrimaryElement();
    padding: 10px;
    max-width: 43px;
    min-width: initial;
    @media (min-width: $breakpoint-tablet) {
      max-width: 65px;
      min-width: initial;
    }
    @media (min-width: $breakpoint-laptop) {
      max-width: 47px;
      min-width: initial;
    }
    @media (min-width: $breakpoint-fullHD) {
      max-width: 57px;
      min-width: initial;
    }
    & .buttonImage {
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
    & .text {
      display: none;
    }
  }
}

@mixin Primary {
  & .element {
    @include PrimaryElement();
  }
}

@mixin Secondary {
  & .element {
    @include SecondaryElement();
  }
}

@mixin Ternary {
  & .element {
    @include TernaryElement();
  }
}