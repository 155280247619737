@import "breakpoints";

@mixin LabelSelects {
  &-title {
    margin-bottom: 20px;
    margin-top: 10px;
    color: var(--text-secondary);
    font-size: 14px;
    @media (min-width: $breakpoint-tablet) {
      font-size: 21px;
      margin-bottom: 28px;
      margin-top: 14px;
    }
    @media (min-width: $breakpoint-laptop) {
      font-size: 14px;
      margin-bottom: 28px;
      margin-top: 10px;
    }
    @media (min-width: $breakpoint-fullHD) {
      font-size: 14px;
      margin-bottom: 33px;
    }
  }
  &-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &-item {
      display: flex;
      justify-content: center;
      height: calc(30px - 12px);
      min-width: calc(90px - 20px);
      align-items: center;
      border-radius: 6px;
      padding: 6px 10px;
      margin-bottom: 10px;
      opacity: 0.5;
      user-select: none;
      background-color: var(--background-default-transparent);
      border: 2px solid transparent;
      @media (min-width: $breakpoint-tablet) {
        padding: 10px 16px;
        min-width: calc(154px - 32px);
        height: calc(45px - 20px);
      }
      @media (min-width: $breakpoint-laptop) {
        padding: 6px 10px;
        min-width: calc(154px - 20px);
        height: calc(45px - 12px);
      }
      @media (min-width: $breakpoint-fullHD) {
        padding: 6px 16px;
        min-width: calc(154px - 32px);
        height: calc(45px - 12px);
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
      &:hover, &.active {
        opacity: 1;
      }
      &.clickable {
        cursor: pointer;
      }
      &.yellow {
        & .text {
          color: var(--text-yellow);
        }
        background-color: var(--labelSelects-orange);
        border-color: var(--labelSelects-border-orange);
      }
      &.blue {
        & .text {
          color: var(--text-blue);
        }
        background-color: var(--labelSelects-blue);
        border-color: var(--labelSelects-border-blue);
      }
      &.pink {
        & .text {
          color: var(--text-purple);
        }
        background-color: var(--labelSelects-pink);
        border-color: var(--labelSelects-border-pink);
      }
      &.default {
        & .text {
          color: var(--text-default);
        }
      }
      & img {
        margin-right: 8px;
        height: 15px;
        width: 15px;
        @media (min-width: $breakpoint-tablet) {
          height: 17px;
          width: 17px;
          margin-right: 13px;
        }
        @media (min-width: $breakpoint-laptop) {
          margin-right: 12px;
          height: 17px;
          width: 17px;
        }
        @media (min-width: $breakpoint-fullHD) {
          height: 17px;
          width: 17px;
          margin-right: 13px;
        }
      }
      &-text {
        font-size: 9px;
        font-weight: 500;
        text-transform: uppercase;
        color: var(--text-secondary);
        @media (min-width: $breakpoint-tablet) {
          font-size: 14px;
        }
        @media (min-width: $breakpoint-laptop) {
          font-size: 12px;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 16px;
        }
      }
    }
  }
}