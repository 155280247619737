@import "../../../styles/breakpoints";

@mixin truncate($width: number) {
  width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin left() {
  display: flex;
  align-items: center;
  &.empty {
    & .value {
      font-weight: 400;
      text-transform: uppercase;
    }
  }
  &.accent {
    & .value {
      font-weight: 600;
    }
  }
  &.normal {
    & .value {
      font-weight: 400;
    }
  }
  &-icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    filter: brightness(10);
    justify-content: center;
    width: 22px;
    height: 22px;
    @media (min-width: $breakpoint-tablet) {
      width: 28px;
      height: 28px;
    }
    @media (min-width: $breakpoint-laptop) {
      width: 24px;
      height: 24px;
    }
    @media (min-width: $breakpoint-fullHD) {
      width: 28px;
      height: 28px;
    }
    & img {
      width: 100%;
      height: 100%;
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
  opacity: 0.9;
  & .trend {
    height: 14px;
    @media (min-width: $breakpoint-tablet) {
      height: 20px;
    }
    @media (min-width: $breakpoint-laptop) {
      height: 16px;
    }
    @media (min-width: $breakpoint-fullHD) {
      height: 20px;
    }
  }
  & .value {
    font-size: 14px;
    margin-right: 5px;
    line-height: 1;
    @media (min-width: $breakpoint-tablet) {
      font-size: 20px;
    }
    @media (min-width: $breakpoint-laptop) {
      font-size: 16px;
    }
    @media (min-width: $breakpoint-fullHD) {
      font-size: 20px;
    }
  }
  & .message {
    font-size: 14px;
    margin-right: 10px;
    font-weight: 500;
    @media (min-width: $breakpoint-tablet) {
      font-size: 20px;
    }
    @media (min-width: $breakpoint-laptop) {
      font-size: 14px;
    }
    @media (min-width: $breakpoint-fullHD) {
      font-size: 19px;
    }
  }
  & .unit {
    font-size: 9px;
    @media (min-width: $breakpoint-tablet) {
      font-size: 12px;
    }
    @media (min-width: $breakpoint-laptop) {
      font-size: 9px;
    }
    @media (min-width: $breakpoint-fullHD) {
      font-size: 12px;
    }
  }
}

@mixin trend {
  opacity: 20%;
  justify-content: center;
  align-items: center;
  display: none;
  @media (min-width: $breakpoint-tablet) {
    display: flex;
  }
  & img {
    filter: brightness(100);
    width: 100%;
    height: 100%;
  }
}

@mixin measured {
  font-size: 12px;
  font-weight: 300;
}

.Hive {
  border-radius: 10px;
  padding: 15px;
  height: calc(100% - 30px);
  position: relative;
  @media (min-width: $breakpoint-tablet) {
    padding: 26px;
    height: calc(100% - 52px);
  }
  @media (min-width: $breakpoint-laptop) {
    padding: 10px 10px;
    height: calc(100% - 20px);
  }
  @media (min-width: $breakpoint-fullHD) {
    padding: 20px;
    height: calc(100% - 40px);
  }
  &.link {
    cursor: pointer;
  }
  &.error {
    border: 1px solid var(--background-red-border);
    background: linear-gradient(151deg, var(--background-red-from) 0%, var(--background-red-to) 100%);
  }
  &.warning {
    border: 1px solid var(--background-orange-border);
    background: linear-gradient(151deg, var(--background-orange-from) 0%, var(--background-orange-to) 100%);
  }
  &.success {
    border: 1px solid var(--background-green-border);
    background: linear-gradient(151deg, var(--background-green-from) 0%, var(--background-green-to) 100%);
  }
  &.silver {
    border: 1px solid var(--background-silver-border);
    background-image: var(--background-silver-gradient)
  }
  &-empty {
    display: flex;
    align-items: center;
    padding-top: 8px;
    justify-content: flex-start;
    &-left {
      @include left();
    }
  }
  &-first {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 15px;
    }
    @media (min-width: $breakpoint-laptop) {
      margin-bottom: 10px;
    }
    @media (min-width: $breakpoint-fullHD) {
      margin-bottom: 15px;
    }
    &-left {
      @include left();
      &-inside {
        &-measured {
          @include measured();
        }
      }
    }
    &-right {
      &-trend {
        @include trend();
      }
    }
  }
  &-second {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 45px;
    }
    @media (min-width: $breakpoint-laptop) {
      margin-bottom: 20px;
    }
    @media (min-width: $breakpoint-fullHD) {
      margin-bottom: 40px;
    }
    &-left {
      @include left();
      &-inside {
        &-measured {
          margin-top: 2px;
          @include measured();
        }
      }
    }
    &-right {
      &-trend {
        @include trend();
      }
    }
  }
  &-name {
    position: absolute;
    bottom: 15px;
    @media (min-width: $breakpoint-tablet) {
      bottom: 20px;
    }
    @media (min-width: $breakpoint-laptop) {
      bottom: 15px;
    }
    @media (min-width: $breakpoint-fullHD) {
      bottom: 20px;
    }
    &-sub {
      font-size: 12px;
      margin-bottom: 5px;
      @media (min-width: $breakpoint-tablet) {
        font-size: 16px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 11px;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 12px;
      }
    }
    &-accent {
      font-size: 14px;
      font-weight: 600;
      @include truncate(122px);
      @media (min-width: $breakpoint-tablet) {
        font-size: 18px;
        @include truncate(160px);
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 14px;
        @include truncate(118px);
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 18px;
        @include truncate(146px);
      }
    }
  }
  &-single {
    @include left();
  }
}
