@import "../../../styles/breakpoints";

@mixin HeaderDropDown {
  position: absolute;
  right: 0;
  bottom: 2px;
  top: 54px;
  z-index: 1;
  @media (min-width: $breakpoint-fullHD) {
    top: 63px;
  }
  &-triangle {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &-inside {
      position: absolute;
      z-index: 4;
      top: -9px;
      right: 10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid var(--profile-border);

      &-sub {
        position: absolute;
        left: -10px;
        width: 0;
        height: 0;
        top: 1.5px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid var(--profile-backgroud);
      }
    }
  }

  &-content {
    background-color: var(--profile-backgroud);
    border: 1px solid var(--profile-border);
    border-radius: 15px;
    padding: 22px 25px;
  }
}

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 28px;
  @media (min-width: $breakpoint-laptop) {
    align-items: flex-start;
  }
  &-left {
    &-welcome {
      margin-bottom: 15px;
      color: var(--background-ternary-text);
      font-size: 16px;
      font-weight: 600;
      text-align: initial;
      @media (min-width: $breakpoint-tablet) {
        font-size: 27px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 20px;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 26px;
      }
    }

    &-date {
      display: flex;
      justify-content: initial;
      color: var(--text-primary);
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 17px;
      @media (min-width: $breakpoint-tablet) {
        font-size: 18px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 14px;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 18px;
      }

      &-image {
        display: flex;
        align-items: center;
        margin-right: 10px;

        & img {
          width: 11px;
          height: 12px;
          @media (min-width: $breakpoint-tablet) {
            width: 16px;
            height: 18px
          }
          @media (min-width: $breakpoint-laptop) {
            width: 14px;
            height: 15px
          }
          @media (min-width: $breakpoint-fullHD) {
            width: 18px;
            height: 20px
          }
        }
      }

      &-today {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }

      &-date {
        display: flex;
        align-items: center;
        margin-right: 15px;
        @media (min-width: $breakpoint-tablet) {
          margin-right: 30px;
        }
      }

      &-icon {
        display: flex;
        align-items: center;
        margin-right: 10px;

        & img {
          width: 12px;
          height: 12px;
          @media (min-width: $breakpoint-tablet) {
            width: 18px;
            height: 18px
          }
          @media (min-width: $breakpoint-laptop) {
            width: 15px;
            height: 15px
          }
          @media (min-width: $breakpoint-fullHD) {
            width: 20px;
            height: 20px
          }
        }
      }

      &-time {
        display: flex;
        align-items: center;
      }
    }
  }

  &-mobileRight {
    display: flex;
    @media (min-width: $breakpoint-laptop) {
      display: none;
    }
    &-sos, &-sms {
      margin-left: 5px;
      margin-right: 5px;
      & .Button {
        & .element {
          height: 38px;
          min-width: 40px;
          background-color: var(--background-orange-transparent-second);
          @media (min-width: $breakpoint-tablet) {
            height: 48px;
            min-width: 108px;
          }
          & .text {
            font-weight: 600;
            font-size: 14px;
            @media (min-width: $breakpoint-tablet) {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  &-right {
    align-items: center;
    display: none;
    @media (min-width: $breakpoint-laptop) {
      display: flex;
    }
    &-item {
      margin-right: 20px;
      display: flex;
      align-items: center;

      &-profile {
        position: relative;
        &-main {
          cursor: pointer;
          width: 45px;
          height: 45px;
          @media (min-width: $breakpoint-fullHD) {
            width: 62px;
            height: 62px;
          }
          & img {
            width: 100%;
            height: 100%;
          }
        }

        &-dropdown {
          @include HeaderDropDown();
        }
      }

      &-dial, &-sms {
        position: relative;
        background-color: var(--background-orange-transparent-second);
        border-radius: 15px;
        margin-left: 5px;
        margin-right: 5px;
        border: 2px solid var(--background-orange);
        width: 45px;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @media (min-width: $breakpoint-fullHD) {
          width: 55px;
          height: 52px;
        }

        &-main {
          width: 21px;
          height: 21px;
          display: flex;
          justify-content: center;
          align-content: center;

          & img {
            width: 100%;
            height: 100%;
          }
        }

        &-dropdown {
          @include HeaderDropDown();
          &-content {
            & .AddDial, &.AddSMS {
              min-width: 350px;
            }
            & .DialMenu, & .SMSMenu {
              min-width: 350px;
              &-content {
                &-form {
                  &-inputs {
                    &-button {
                      display: flex;
                      justify-content: center;
                    }
                  }
                }
                &-list {
                  margin-bottom: 10px;
                  max-height: 280px;
                  padding-right: 10px;
                  overflow: scroll;
                  padding-left: 10px;
                  margin-left: -10px;
                  margin-right: -10px;
                }
                &-bottom {
                  display: flex;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
