@import "../../../styles/breakpoints";
@import "../../../styles/detailLink";

.Toastify {
  &.success {
    .Toastify-title-text {
      color: var(--text-success);
    }
  }
  &.error {
    .Toastify-title-text {
      color: var(--text-error);
    }
  }
  &.notify {
    .Toastify-title-text {
      color: var(--text-blue);
    }
  }
  &-title {
    display: flex;
    align-items: center;
    &-image {
      margin-right: 10px;
      width: 14px;
      height: 14px;
      display: flex;
      justify-content: center;
      align-content: center;
      & img {
        width: 100%;
        height: 100%;
      }
    }
    &-text {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
      @media (min-width: $breakpoint-tablet) {
        font-size: 16px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 14px;
      }
    }
  }
  &-subtitle {
    margin-top: 5px;
    &-text {
      opacity: 0.6;
      font-size: 12px;
      @media (min-width: $breakpoint-tablet) {
        font-size: 12px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 11px;
      }
    }
  }
  &-description {
    margin-top: 14px;
    font-size: 14px;
    line-height: 20px;
    @media (min-width: $breakpoint-tablet) {
      font-size: 16px;
    }
    @media (min-width: $breakpoint-laptop) {
      font-size: 14px;
    }
    &-link {
      @include DetailLink();
      margin-top: 14px;
      &-text {
        font-weight: 600;
        font-size: 12px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 14px;
        }
        @media (min-width: $breakpoint-laptop) {
          font-size: 12px;
        }
      }
      &-icon {
        width: 12px;
        height: 12px;
        @media (min-width: $breakpoint-tablet) {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
