@import "../../../styles/breakpoints";
@import "../../../styles/scrollbar";

.Building {
  position: relative;
  background-color: #00000012;
  &.initialized {
    & .content {
      opacity: 1;
    }
    & .loader {
      display: none;
    }
  }
  &-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  &-content {
    opacity: 0;
    &-main {
      @include ScrollBarGrey();
      overflow: auto;
      height: 320px;
      width: 320px;
      @media (min-width: $breakpoint-tablet) {
        width: 666px;
        height: 666px;
      }
      @media (min-width: $breakpoint-laptop) {
        width: 532px;
        height: 655px;
      }
      @media (min-width: $breakpoint-fullHD) {
        height: 700px;
      }
      &-building {
        width: 10000px;
        height: 10000px;
        display: flex;
        justify-content: center;
        align-items: center;
        &-container {
          position: relative;
          & .building-img {
            transition: min-width 1ms ease-in-out;
            user-select: none;
            max-width: 99%;
            max-height: 99%;
            filter: var(--building-img-filter);
          }
        }
        &-hardware {
          & .DraggablePlanElement {
            position: absolute;
            margin: 0;
            & .block {
              & .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                user-select: none;
                & img {
                  width: 100%;
                  height: 100%;
                  user-select: none;
                }
              }
            }
          }
        }
      }
    }
  }
}