@import "../../../styles/modal";

.HardwareScanRequest {
  @include ModalSecondary();
    &-content {
      &-scan {
        &-intro {
          margin: 40px auto 40px;
          color: var(--text-success);
          font-size: 12px;
          max-width: 80%;
          text-align: center;
        }
        &-error {
          margin: 40px auto 40px;
          color: var(--text-error);
          font-size: 12px;
          max-width: 80%;
          text-align: center;
        }
        &-camera {
          width: 80%;
          margin: 0 auto;
          & .QRScan {
            section {
              div {
                background-color: #1c2635;
              }
            }
          }
        }
      }
  }
}

