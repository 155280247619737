@import "../../../styles/cards";
@import "../../../styles/breakpoints";

@mixin CardAlertLabel {
  font-size: 14px;
  font-weight: 500;
  color: var(--parameters-placeholder);
}

.CardAlert {
  @include Card();
  @media (min-width: $breakpoint-laptop) {
    height: 500px;
  }
  &-content {
    &-mobile {
      display: block;
      @media (min-width: $breakpoint-tablet) {
        display: none;
      }
      &-accent {
        margin-bottom: 21px;
        &-label {
          @include CardAlertLabel;
          margin-bottom: 11px;
        }
        &-value {
          &-accent {
            display: flex;
            align-items: center;
            &-trend {
              margin-right: 12px;
              height: 28px;
              width: 29px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 6px;
              &.up {
                background-color: var(--background-red-transparenter);
              }
              &.down {
                background-color: var(--background-blue-transparenter);
              }
              &-image {
                height: 18px;
                width: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                & img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            &-value {
              display: flex;
              align-items: flex-end;
              &.up {
                color: var(--text-error);
              }
              &.down {
                color: var(--text-blue);
              }
              &-item {
                font-size: 24px;
                margin-right: 10px;
              }
              &-unit {
                font-size: 21px;
              }
            }
          }
        }
      }
      &-info {
        display: flex;
        max-width: 290px;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        &-left {
          width: 50%;
          &-label {
            @include CardAlertLabel();
            margin-bottom: 10px;
          }
          &-simpleValue {
            font-size: 14px;
            margin-bottom: 2px;
          }
          &-value {
            display: flex;
            align-items: flex-end;
            &-item {
              font-size: 24px;
              margin-right: 5px;
            }
            &-unit {
              font-size: 14px;
              margin-bottom: 2px;
            }
          }
        }
        &-right {
          width: 50%;
          &-label {
            @include CardAlertLabel();
            margin-bottom: 10px;
          }
          &-simpleValue {
            font-size: 14px;
            margin-bottom: 2px;
          }
          &-time {
            font-size: 24px;
          }
        }
      }
    }
    &-desktop {
      display: none;
      @media (min-width: $breakpoint-tablet) {
        display: block;
      }
      &-detail {
        & table {
          width: 100%;
          text-align: center;
          border-collapse: collapse;
          &:not(:last-child) {
            & td {
              padding-bottom: 28px;
            }
          }
        }
        & th {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: var(--text-primary);
          border-bottom: 1px solid var(--cardAlert-head-border);
          padding-bottom: 19px;
          @media (min-width: $breakpoint-fullHD) {
            font-size: 18px;
          }
          &:not(:last-child) {
            border-right: 1px solid var(--cardAlert-head-border);
          }
          &:nth-child(1) {
            width: 25%;
          }
          &:nth-child(2) {
            width: 30%;
          }
        }
        & td {
          padding-top: 14px;
          &:not(:last-child) {
            border-right: 1px solid var(--cardAlert-head-border);
          }
          & .column {
            display: flex;
            justify-content: center;
            align-items: center;
            & .subDate {
              margin-top: 5px;
              color: var(--text-primary);
            }
            & .trend {
              height: 30px;
              width: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 6px;
              margin-right: 12px;
              @media (min-width: $breakpoint-fullHD) {
                height: 35px;
                width: 35px;
              }
              &.up {
                background-color: var(--background-red-transparenter);
              }
              &.down {
                background-color: var(--background-blue-transparenter);
              }
              & img {
                width: 11px;
                height: 17px;
                @media (min-width: $breakpoint-fullHD) {
                  width: 13px;
                  height: 20px;
                }
              }
            }
            & .valueContainer {
              display: flex;
              align-items: flex-end;
              &.up {
                color: var(--text-error);
              }
              &.down {
                color: var(--text-blue);
              }
            }
            & .value {
              margin-right: 5px;
              font-size: 24px;
              @media (min-width: $breakpoint-laptop) {
                font-size: 18px;
              }
              @media (min-width: $breakpoint-fullHD) {
                font-size: 24px;
              }
            }
            & .unit {
              font-size: 18px;
              @media (min-width: $breakpoint-laptop) {
                font-size: 14px;
              }
              @media (min-width: $breakpoint-fullHD) {
                font-size: 18px;
              }
            }
            & .time {
              font-size: 21px;
              @media (min-width: $breakpoint-laptop) {
                font-size: 18px;
              }
              @media (min-width: $breakpoint-fullHD) {
                font-size: 24px;
              }
            }
          }
        }
      }
    }
  }
}