@import "../../../styles/cards";
@import "../../../styles/parameters";


.HardwareTypes {
  @include Card();
  &-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: -14px;
    margin-right: -14px;
    margin-bottom: 72px;
    @media (min-width: $breakpoint-laptop) {
      margin-top: 30px;
    }
    &-item {
      width: calc(100% - 4px - 28px);
      border-radius: 18px;
      cursor: pointer;
      border: 2px solid transparent;
      margin: 14px;
      @media (min-width: $breakpoint-tablet) {
        width: calc(50% - 28px - 4px);
      }
      @media (min-width: $breakpoint-laptop) {
        width: calc(25% - 28px - 4px);
      }
      @media (min-width: $breakpoint-fullHD) {
        width: calc(25% - 28px - 4px);
        max-width: 426px;
      }

      &.blue {
        background-color: var(--hwTypes-blue-bkg);

        &:hover {
          border: 2px solid var(--hwTypes-blue-border);
        }

        & .title {
          color: var(--hwTypes-blue-text);
        }
      }

      &.pink {
        background-color: var(--hwTypes-pink-bkg);

        &:hover {
          border: 2px solid var(--hwTypes-pink-border);
        }

        & .title {
          color: var(--hwTypes-pink-text);
        }
      }

      &.orange {
        background-color: var(--hwTypes-orange-bkg);

        &:hover {
          border: 2px solid var(--hwTypes-orange-border);
        }

        & .title {
          color: var(--hwTypes-orange-text);
        }
      }

      &.green {
        background-color: var(--hwTypes-green-bkg);

        &:hover {
          border: 2px solid var(--hwTypes-green-border);
        }

        & .title {
          color: var(--hwTypes-green-text);
        }
      }

      &-inside {
        padding-top: 100%;
        position: relative;
        &-in {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          padding: 27px 21px;
          @media (min-width: $breakpoint-tablet) {
            padding: 40px 32px;
          }
          @media (min-width: $breakpoint-laptop) {
            padding: 20px 22px;
          }
          &-top {
            margin-bottom: 31px;
            @media (min-width: $breakpoint-tablet) {
              margin-bottom: 42px;
            }
            @media (min-width: $breakpoint-laptop) {
              margin-bottom: 30px;
            }
            @media (min-width: $breakpoint-fullHD) {
              margin-bottom: 40px;
            }

            &-left {
              font-weight: 600;
              font-size: 12px;
              @media (min-width: $breakpoint-tablet) {
                font-size: 21px;
              }
              @media (min-width: $breakpoint-laptop) {
                font-size: 16px;
              }
              @media (min-width: $breakpoint-fullHD) {
                font-size: 20px;
              }
            }
          }

          &-image {
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(100% - 14px - 31px - 31px);
            margin: 0 auto;
            @media (min-width: $breakpoint-tablet) {
              margin-bottom: 42px;
              height: calc(100% - 25px - 42px - 42px);
            }
            @media (min-width: $breakpoint-laptop) {
              margin-bottom: 30px;
              height: calc(100% - 19px - 30px - 30px);
            }
            @media (min-width: $breakpoint-fullHD) {
              height: calc(100% - 24px - 40px - 24px - 24px);
              margin-bottom: 40px;
            }

            & img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  &-bulk {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

