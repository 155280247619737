@import "breakpoints";

@mixin FontCalendar {
  color: white;
  font-size: 12px;
  font-family: 'HelveticaNeue', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
}

@mixin Calendar {
  & abbr[title] {
    text-decoration: none;
    @include FontCalendar();
    font-weight: 500;
  }
  & .react-date-picker {
    height: 40px !important;
    width: 100%;
    @media (min-width: $breakpoint-tablet) {
      height: 59px !important;
    }
    @media (min-width: $breakpoint-laptop) {
      height: 46px !important;
    }
    @media (min-width: $breakpoint-fullHD) {
      height: 67px !important;
    }
    &__inputGroup__input {
      outline: none;
    }
    &__button__icon {
      stroke: var(--input-placeholder) !important;
    }
    &__inputGroup__month, &__inputGroup__day, &__inputGroup__year, &__inputGroup__leadingZero {
      color: var(--input-text);
    }
    &__inputGroup__divider {
      color: var(--input-placeholder);
    }
    &__inputGroup__month, &__inputGroup__day, &__inputGroup__year, &__inputGroup__divider, &__inputGroup__leadingZero {
      font-size: 12px;
      @media (min-width: $breakpoint-tablet) {
        font-size: 15px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 12px;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 16px;
      }
      &::placeholder {
        color: var(--input-placeholder);
        @media (min-width: $breakpoint-tablet) {
          font-size: 15px;
        }
        @media (min-width: $breakpoint-laptop) {
          font-size: 12px;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 16px;
        }
      }
    }
    &__clear-button {
      & svg {
        height: 27px;
        width: 27px;
      }
    }
    &__wrapper {
      transition: border-bottom-color 0.3s ease-in-out, border-left-color 0.3s ease-in-out,
      border-right-color 0.3s ease-in-out, border-top-color 0.3s ease-in-out;
      border-radius: 5px;
      border: 2px solid var(--input-border);
      padding-left: 20px;
      padding-right: 20px;
      background-color: var(--input-background);
      @media (min-width: $breakpoint-laptop) {
        font-size: 12px;
        border-radius: 8px;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 16px;
        border-radius: 10px;
      }
    }
  }
  & .react-calendar {
    background-color: var(--calendar-background);
    border: solid 2px var(--calendar-border);
    width: 257px;
    @include FontCalendar();
    &__tile {
      @include FontCalendar();
    }
    &__month-view__days__day--weekend {
      background-color: var(--calendar-weekend-backgroud);
    }
    &__tile:disabled {
      @include FontCalendar();
      background-color: var(--calendar-background);
      color: var(--calendar-disabled-text);
    }
    &__tile--now {
      background-color: var(--calendar-current-date-background);
    }
    &__tile--active {
      background-color: var(--calendar-selected-backgroud);
    }
    &__tile:enabled:hover, &__tile:enabled:focus {
      background-color: var(--calendar-hover-backgroud);
    }
    &__navigation__label {
      @include FontCalendar();
      font-weight: 500;
      &:hover {
        background-color: var(--calendar-background);
      }
    }
    &__navigation__arrow {
      @include FontCalendar();
    }
    &__navigation button:enabled:hover, &__navigation button:enabled:focus {
      background-color: var(--calendar-background);
    }
    &__navigation button:disabled {
      background-color: var(--calendar-background);
    }
  }
}