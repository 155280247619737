@import "../../../styles/breakpoints";

.BuildingPlanBuilding {
  &-content {
    &-placeholder {
      display: flex;
      justify-content: center;
      filter: var(--building-img-filter);
      & img {
        width: 100%;
        @media (min-width: $breakpoint-tablet) {
          width: initial;
        }
      }
    }
  }
  & .InputRange {
    margin-bottom: 50px;
    @media (min-width: $breakpoint-tablet) {
      width: 447px;
    }
    &-content {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
      width: initial;
    }
    &-title {
      &-text {
        font-size: 16px;
        font-weight: 400;
        color: var(--range-hint);
      }
    }
  }
}

