@import "../../../styles/breakpoints";
@import "../../../styles/modalNavigation";

.DialModal {
  background-color: black;
  display: flex;
  align-items: center;
  @media (min-width: $breakpoint-laptop) {
    display: none;
  }
  &-navigation {
    @include ModalNavigation();
    &-inside {
      padding-left: 33px;
      padding-right: 33px;
      & .AddDial {
        &-content {
          &-form {
            &-inputs {
              margin-bottom: 40px;
            }
          }
        }
      }
      & .DialMenu {
        &-content {
          &-list {
            margin-bottom: 10px;
            height: calc(100vh - 320px);
            padding-right: 10px;
            overflow: scroll;
            padding-left: 10px;
            margin-left: -10px;
            margin-right: -10px;
          }
          &-bottom {
            position: absolute;
            bottom: 61px;
            left: 0;
            right: 0;
            width: 100%;
            display: flex;
            justify-content: center;

            & .Button {
              & .element {
                width: 194px;
                border-radius: 10px;
                font-weight: 500;
                font-size: 14px;

                & img {
                  display: block !important;
                  width: 14px;
                  height: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}