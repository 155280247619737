@import "../../../styles/cards";
@import "../../../styles/letterImage";
@import "../../../styles/breakpoints";
@import "../../../styles/parameters";

.CardUser {
  @include Card();
  &-content {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    &-firstLine {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 32px;
      flex-wrap: wrap;
      @media (min-width: $breakpoint-tablet) {
        margin-bottom: 64px;
      }
      &-left {
        display: flex;
        align-items: center;
        margin-bottom: 34px;
        &-image {
          margin-right: 30px;
        }
        &-name {
          &-fullName {
            margin-bottom: 7px;
            font-size: 16px;
            font-weight: 500;
            @media (min-width: $breakpoint-tablet) {
              font-size: 21px;
            }
            @media (min-width: $breakpoint-laptop) {
              font-size: 20px;
            }
            @media (min-width: $breakpoint-fullHD) {
              font-size: 27px;
            }
          }
          &-role {
            & .Role-title {
              font-size: 12px;
              @media (min-width: $breakpoint-tablet) {
                font-size: 18px;
              }
              @media (min-width: $breakpoint-laptop) {
                font-size: 14px;
              }
              @media (min-width: $breakpoint-fullHD) {
                font-size: 20px;
              }
            }
            & .Role-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 10px;
              height: 10px;
              @media (min-width: $breakpoint-tablet) {
                width: 16px;
                height: 16px;
              }
              @media (min-width: $breakpoint-laptop) {
                width: 12px;
                height: 12px;
              }
              @media (min-width: $breakpoint-fullHD) {
                width: 18px;
                height: 18px;
              }
              & img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      &-parameters {
        @include parameters();
      }
    }
    &-secondLine {
      &-title {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 30px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 21px;
        }
        @media (min-width: $breakpoint-laptop) {
          font-size: 16px;
        }
      }
      &-form {
        max-width: 1000px;
        & .InputText {
          width: 100%;
          @media (min-width: $breakpoint-tablet) {
            width: 235px;
          }
        }
        & .CustomSelect {
          width: 100%;
          @media (min-width: $breakpoint-tablet) {
            width: 235px;
          }
        }
        &-button {
          display: flex;
          & .Button {
            &:not(:last-child) {
              margin-right: 10px;
            }
            & .element {
              margin: 0 auto;
              @media (min-width: $breakpoint-tablet) {
                margin: initial;
              }
            }
          }
        }
        &-inputs {
          margin-bottom: 62px;
          &-title {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 30px;
            @media (min-width: $breakpoint-tablet) {
              font-size: 21px;
            }
            @media (min-width: $breakpoint-laptop) {
              font-size: 16px;
            }
          }
          &-flex {
            display: flex;
            flex-wrap: wrap;
            & .InputText:not(:last-child), & .CustomSelect {
              @media (min-width: $breakpoint-tablet) {
                margin-right: 36px;
              }
            }
          }
        }
      }
    }
  }
}