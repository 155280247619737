@import "../../../styles/breakpoints";

.InputRange {
  margin-bottom: 15px;
  @media (min-width: $breakpoint-laptop) {
    margin-bottom: 30px;
  }
  &.disabled {
    & .current {
      color: var(--range-disabled);
    }
    & .element {
      &::-webkit-slider-thumb {
        background: var(--range-disabled);
      }

      &::-moz-range-thumb {
        background: var(--range-disabled);
      }
    }
  }
  &.hideValue {
    & .current {
      display: none;
    }
    & .element {
      &::-webkit-slider-thumb {
        display: none;
      }
    }
  }
  &-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 27px;

    &-image {
      width: 31px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin-right: 10px;
      @media (min-width: $breakpoint-tablet) {
        width: 35px;
        height: 34px;
      }
      @media (min-width: $breakpoint-laptop) {
        width: 31px;
        height: 30px;
      }
      @media (min-width: $breakpoint-fullHD) {
        width: 35px;
        height: 34px;
      }
      &.growing {
        background-color: var(--background-red-transparent);
      }
      &.lowering {
        background-color: var(--background-blue-transparent);
      }
      & img {
        width: 10px;
        height: 17px;
        @media (min-width: $breakpoint-tablet) {
          width: 13px;
          height: 20px;
        }
        @media (min-width: $breakpoint-laptop) {
          width: 10px;
          height: 17px;
        }
        @media (min-width: $breakpoint-fullHD) {
          width: 13px;
          height: 20px;
        }
      }
    }

    &-text {
      font-size: 12px;
      color: var(--background-primary-text);
      @media (min-width: $breakpoint-tablet) {
        font-size: 16px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 12px;
      }
      @media (min-width: $breakpoint-tablet) {
        font-size: 16px;
      }
    }
  }

  &-content {
    width: calc(100% - 52px);
    height: 70px;
    background-color: var(--range-bkg);
    padding-top: 10px;
    padding-left: 26px;
    padding-right: 26px;
    border-radius: 12px;

    &-label {
      width: 100%;
      position: relative;

      &-element {
        -webkit-appearance: none;
        width: 100%;
        height: 2px;
        background: var(--range-slider);
        outline: none;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 6px;
          height: 16px;
          border-radius: 16px;
          background: var(--range-thumb);
          cursor: pointer;
        }

        &::-moz-range-thumb {
          width: 25px;
          height: 25px;
          background: var(--range-thumb);
          cursor: pointer;
        }
      }

      &-current {
        bottom: -45px;
        position: absolute;
        font-size: 15px;
        width: 40px;
        display: flex;
        justify-content: center;
        color: var(--range-current);
      }
    }

    &-hint {
      font-size: 12px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      color: var(--range-hint);
    }
  }
}