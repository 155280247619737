@import "../../../styles/breakpoints";

.CustomDraggable {
  &.onDrag {
    & .handle {
      opacity: 0.5;
    }

    & .draggedHandle {
      display: block;
      opacity: 1;
      z-index: 4;
      & .icon {
        animation: glow 2s infinite;
      }
      & .block {
        height: 31px;
        width: 32px;
        & .text {
          & > * {
            font-size: 12px;
          }
        }
        & .icon {
          width: 21px;
          height: 21px;
        }
      }
    }
    & .controls {
      opacity: 0;
    }
  }
  &-content {
    user-select: none;
    & .handle {
      cursor: pointer;
      user-select: none;
    }
    & .draggedHandle {
      opacity: 0;
      cursor: pointer;
      position: fixed;
      z-index: -1;
      user-select: none;
    }
  }
}