.swal2-popup {
  background-color: var(--background-primary) !important;
  color: var(--background-primary-text) !important;
  font-family: 'Roboto', sans-serif !important;
}

.swal2-container {
  z-index: 2060;
}

body.swal2-height-auto, html.swal2-height-auto {
  height: 100% !important;
}