@import "../../../styles/cards";
@import "../../../styles/parameters";


.HardwareCreateDialog {
  @include Card();
  &-content {
    &-inside {
      &-bulk {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 80px;
        @media (min-width: $breakpoint-laptop) {
          height: calc(100vh - 395px);
          margin-top: initial;
        }
        @media (min-width: $breakpoint-fullHD) {
          height: calc(100vh - 442px);
        }
        &-container {
          text-align: center;
          &-pic {
            display: flex;
            justify-content: center;
            margin: 0 auto 49px;
            width: 112px;
            height: 112px;
            align-items: center;
            @media (min-width: $breakpoint-tablet) {
              width: 176px;
              height: 176px;
              margin: 0 auto 47px;
            }
            @media (min-width: $breakpoint-laptop) {
              margin: 0 auto 64px;
              width: 176px;
              height: 176px;
            }
            & img {
              width: 100%;
              height: 100%;
            }
          }
          &-text {
            max-width: 250px;
            margin: 0 auto 50px;
            font-size: 12px;
            @media (min-width: $breakpoint-tablet) {
              font-size: 14px;
              max-width: 300px;
            }
            @media (min-width: $breakpoint-laptop) {
              font-size: 18px;
              max-width: 332px;
            }
            @media (min-width: $breakpoint-fullHD) {
              font-size: 20px;
              max-width: 332px;
            }
          }
          &-button {
            display: flex;
            justify-content: center;
          }
        }
      }
      &-manual {
        & .AddOrEditHardware {
          @media (min-width: $breakpoint-laptop) {
            max-width: 680px;
            padding-top: 30px;
          }
          @media (min-width: $breakpoint-fullHD) {
            max-width: 720px;
          }
        }
      }
      &-scan {
        & .AddOrEditHardware {
          @media (min-width: $breakpoint-laptop) {
            max-width: 510px;
          }
          @media (min-width: $breakpoint-fullHD) {
            max-width: 530px;
          }
          &-content-form-inputs-button {
            justify-content: center;
            @media (min-width: $breakpoint-laptop) {
              justify-content: flex-start;
            }
          }
        }
      }
      &-manual {
        & .AddOrEditHardware {
          &-content-form-inputs-button {
            justify-content: center;
          }
        }
      }
      &-scan, &-manual {
        & .AddOrEditHardware {
          margin: 0 auto;
          &-sensors {
            margin-bottom: 0;
          }
          &-content-form-inputs-button {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            @media (min-width: $breakpoint-tablet) {
              flex-wrap: nowrap;
            }
            @media (min-width: $breakpoint-laptop) {
            }
          }
          & .Button {
            display: flex;
            justify-content: center;
            width: 100%;
            @media (min-width: $breakpoint-laptop) {
              width: initial;
            }
            &:not(:last-child) {
              margin-bottom: 18px;
              @media (min-width: $breakpoint-tablet) {
                margin-right: 35px;
                margin-bottom: initial;
              }
            }
          }
        }
      }
    }
  }
  & .HardwareCreateDialogScan {
    width: 100%;
    @media (min-width: $breakpoint-laptop) {
      margin: 0 auto;
      max-width: 850px;
    }
    @media (min-width: $breakpoint-fullHD) {
      max-width: 940px;
    }
  }
}

