@import "breakpoints";

@mixin DetailLink {
  margin-top: 21px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.SECONDARY {
    & .text {
      color: var(--text-default);
    }
    & .icon {
      filter: var(--filter-default);
    }
  }

  &.DELETE {
    & .text {
      color: var(--text-red);
    }
    & .icon {
      filter: var(--filter-error);
    }
  }

  &.PRIMARY {
    & .text {
      color: var(--text-blue);
    }
    & .icon {
      filter: var(--filter-blue);
    }
  }

  &-text {
    color: var(--text-blue);
    font-weight: 500;
    font-size: 14px;
    @media (min-width: $breakpoint-tablet) {
      font-size: 18px;
    }
  }
  &-icon {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 14px;
    width: 14px;
    margin-right: 10px;
    &.arrow {
      transform: rotate(180deg);
    }
    & img {
      filter: brightness(100) invert(1);
    }
  }
}
