@import "../../../styles/breakpoints";
@import "../../../styles/input";
@import "../../../styles/button";

.InputText {
  position: relative;
  margin-bottom: 13px;
  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 13px;
  }
  @media (min-width: $breakpoint-laptop) {
    margin-bottom: 16px;
  }
  @media (min-width: $breakpoint-fullHD) {
    margin-bottom: 16px;
  }
  &.fullWidth {
    width: 100% !important;
  }
  &.flat {
    & .image {
      right: 0;
      height: 40px;
    }
    & .element {
      background-color: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid rgba(125, 156, 181, 0.2);
      border-radius: 0;
      padding-left: 0;
      height: 40px;
      font-size: 12px;
      font-weight: 500;
      width: calc(100% - 40px);
      @media (min-width: $breakpoint-tablet) {
        font-size: 18px;
      }
      &::placeholder {
        font-size: 12px;
        font-weight: 500;
        @media (min-width: $breakpoint-tablet) {
          font-size: 18px;
        }
        @media (min-width: $breakpoint-laptop) {
          font-size: 14px;
        }
      }
    }
  }
  &.autofill {
    visibility: hidden;
    position: absolute;
    z-index: -2;
    height: 1px;
    padding: 0;
    margin: 0;
  }
  &.nok {
    & .element {
      border: 2px solid var(--background-red);
    }
    & .error {
      opacity: 1;
    }
  }
  &.ok {
    & .element {
      border: 2px solid var(--background-green);
    }
  }
  & .image {
    @include InputImage();
  }
  & .error {
    margin-top: 10px;
    color: var(--text-error);
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    @media (min-width: $breakpoint-tablet) {
      font-size: 15px;
    }
    @media (min-width: $breakpoint-laptop) {
      font-size: 12px;
    }
    @media (min-width: $breakpoint-fullHD) {
      font-size: 16px;
    }
  }
  & .element {
    border: 2px solid;
    border-color: var(--input-border);
    transition: border-bottom-color 0.3s ease-in-out, border-left-color 0.3s ease-in-out,
    border-right-color 0.3s ease-in-out, border-top-color 0.3s ease-in-out;
  }
  &-label {
    margin-bottom: 10px;
    color: var(--input-label);
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    @media (min-width: $breakpoint-tablet) {
      font-size: 15px;
    }
    @media (min-width: $breakpoint-laptop) {
      font-size: 12px;
    }
    @media (min-width: $breakpoint-fullHD) {
      font-size: 16px;
    }
  }
  & input {
    outline: none;
    height: 34px;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 40px;
    border: 1px solid var(--input-border);
    background-color: var(--input-background);
    color: var(--input-text);
    -moz-appearance: textfield;
    font-size: 12px;
    width: calc(100% - 60px);
    @media (min-width: $breakpoint-tablet) {
      font-size: 15px;
      height: 53px;
    }
    @media (min-width: $breakpoint-laptop) {
      font-size: 12px;
      height: 40px;
      border-radius: 8px;
    }
    @media (min-width: $breakpoint-fullHD) {
      font-size: 16px;
      height: 59px;
      border-radius: 10px;
    }
    &::placeholder {
      font-size: 12px;
      font-weight: 400;
      color: var(--input-placeholder);
      @media (min-width: $breakpoint-tablet) {
        font-size: 15px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 12px;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 16px;
      }
    }
    &:disabled {
      cursor: not-allowed;
      color: var(--input-placeholder);
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--input-background) inset !important;
    }

    &:-webkit-autofill{
      -webkit-text-fill-color: var(--input-text) !important;
    }
  }
  & textarea {
    height:200px;
    outline: none;
    border-radius: 5px;
    border: 1px solid var(--input-border);
    background-color: var(--input-background);
    font-size: 12px;
    padding-right: 40px;
    padding-left: 20px;
    padding-top: 15px;
    color: var(--input-text);
    width: calc(100% - 60px);
    @media (min-width: $breakpoint-tablet) {
      font-size: 15px;
    }
    @media (min-width: $breakpoint-laptop) {
      font-size: 12px;
    }
    @media (min-width: $breakpoint-fullHD) {
      font-size: 16px;
    }
    &::placeholder {
      font-size: 12px;
      color: var(--input-placeholder);
      font-weight: 400;
      @media (min-width: $breakpoint-tablet) {
        font-size: 15px;
      }
      @media (min-width: $breakpoint-laptop) {
        font-size: 12px;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 16px;
      }
    }
  }
}

.FileUpload {
  &.nok {
    & .FileUpload-content-button {
      background-color: var(--background-red-transparent);
      border: 2px solid var(--background-red);
    }
  }
  &.ok {
    & .FileUpload-content-button {
      background-color: var(--background-green-transparent);
      border: 2px solid var(--background-green);
    }
  }
  &-content {
    background-color: var(--range-bkg);
    height: 80px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px;
    &-input {
      display: none;
    }
    &-button {
      @include TernaryElement();
      margin-right: 25px;
    }
    &-fileName {
      color: var(--text-primary);
    }
  }
}
