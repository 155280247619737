@import "../../../styles/modal";
@import "../../../styles/breakpoints";
@import "../../../styles/draggable";

.BuildingFullScreen {
  &-modal {
    @include ModalSecondary();
    &-content {
      max-width: initial;
      width: fit-content;
      &-title {
        margin-bottom: 0;
      }
      & .Building-content-main {
        width: 90vw;
        height: calc(100vh - 10vh - 60px - 10vh);
      }
    }
  }
}

