@import "../../../styles/breakpoints";

.EasySearch {
  .InputText {
    margin-bottom: 0;
    &-element {
      width: calc(309px - 64px);
      height: calc(47px - 6px);
      border: 2px solid var(--input-border);
      border-radius: 15px;
      @media (min-width: $breakpoint-fullHD) {
        height: calc(57px - 6px);
        width: 380px;
      }
    }
  }
}