@import "breakpoints";

@mixin contentBlock {
  text-align: center;
  max-width: 500px;
  &-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    &.full {
      width: 100px;
      height: 100px;
      & img {
        width: 100%;
        height: 100%;
      }
    }
    &.card {
      width: 100px;
      height: 100px;
      & img {
        width: 100%;
        height: 100%;
      }
    }
    &.modal {
      width: 100px;
      height: 100px;
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &-title {
    color: var(--background-primary-text);
    font-size: 21px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 15px;
    text-align: center;
    max-width: 500px;
    @media (min-width: $breakpoint-fullHD) {
      font-size: 26px;
    }
  }
  &-subTitle {
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    max-width: 500px;
    @media (min-width: $breakpoint-fullHD) {
      font-size: 18px;
    }
  }
}