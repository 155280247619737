@import "../../../styles/cards";
@import "../../../styles/breakpoints"
;
.ViewHive {
  @include Card();
  &-content {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    &-hive {
      margin: 5px;
      width: 155px;
      height: 155px;

      @media (min-width: $breakpoint-iphone8) {
        width: 147px;
        height: 147px;
      }
      @media (min-width: $breakpoint-tablet) {
        width: 215px;
        height: 215px;
      }
      @media (min-width: $breakpoint-laptop) {
        width: 140px;
        height: 140px;
      }
      @media (min-width: $breakpoint-laptopHD) {
        width: 156px;
        height: 156px;
      }
      @media (min-width: $breakpoint-laptopHDPlus) {
        width: 144px;
        height: 144px;
      }
      @media (min-width: $breakpoint-laptopXL) {
        width: 153px;
        height: 153px;
      }
      @media (min-width: $breakpoint-fullHD) {
        width: 191px;
        height: 191px;
      }
      & .Hive {
        width: initial;
      }
    }
  }
}
