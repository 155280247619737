@import "../../../styles/breakpoints";

.ProfileMenu {
  &-content {

    &-top {
      display: flex;
      align-items: center;
      padding-bottom: 21px;
      border-bottom: 1px solid var(--profile-separator);

      &-left {
        margin-right: 14px;
      }

      &-right {
        &-name {
          font-size: 18px;
          color: var(--text-secondary);
          margin-bottom: 7px;
          @media (min-width: $breakpoint-laptop) {
            font-size: 16px;
          }
        }

        &-role {
          & .Role {
            &-icon {
              width: 13px;
              height: 13px;
            }

            &-title {
              font-size: 14px;
              font-weight: 400;
              @media (min-width: $breakpoint-laptop) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    &-middle {
      padding-top: 22px;
      padding-bottom: 22px;
      border-bottom: 1px solid var(--profile-separator);

      &-item {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 25px;
        }

        &:hover {
          & .text {
            color: var(--text-secondary);
          }

          & img {
            filter: brightness(300);
          }
        }

        &-icon {
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 12px;
          @media (min-width: $breakpoint-laptop) {
            width: 18px;
            height: 18px;
          }
          & img {
            width: 100%;
            height: 100%;
          }
        }

        &-text {
          color: var(--text-primary);
          font-size: 16px;
          @media (min-width: $breakpoint-laptop) {
            font-size: 14px;
          }
        }
      }
    }

    &-bottom {
      padding-top: 23px;
      padding-bottom: 27px;
    }
  }
}