@import "../../../styles/breakpoints";

@keyframes DotAnimationSub {
  from {
    transform: scale(0.5);
    opacity: 0.5;
  }
  to {
    transform: scale(1.2);
    opacity: 1;
  }
}

@keyframes DotAnimation {
  from {
    transform: scale(1);
    opacity: 0.75;
  }
  to {
    transform: scale(1.2);
    opacity: 1;
  }
}

.Dot {
  width: 11px;
  height: 11px;
  background-color: var(--background-default);
  border-radius: 30px;
  &.parent {
    width: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15px;
    position: relative;
    @media (min-width: $breakpoint-laptop) {
      width: 21px;
      height: 21px;
    }
    &.highlight {
      animation: DotAnimation 1s ease-in-out infinite alternate;
    }
    &.success {
      background-color: var(--background-green-light);
    }
    &.error {
      background-color: var(--background-red-light);
    }
    &.primary {
      background-color: var(--background-grey-light);
    }
    &.warning {
      background-color: var(--background-orange-light);
    }
  }
  &.child {
    width: 9px;
    height: 9px;
    @media (min-width: $breakpoint-laptop) {
      width: 11px;
      height: 11px;
    }
    &.highlight {
      animation: DotAnimationSub 1s ease-in-out infinite alternate;
    }
  }
  &.success {
    background-color: var(--background-green);
  }
  &.error {
    background-color: var(--background-red);
  }
  &.primary {
    background-color: var(--background-grey);
  }
  &.warning {
    background-color: var(--background-orange);
  }
}