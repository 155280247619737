@import "../../../styles/breakpoints";

.Role {
  display: flex;
  align-items: center;
  &.yellow {
    & .title {
      color: var(--cardUser-role-yellow);
    }
  }
  &.blue {
    & .title {
      color: var(--cardUser-role-blue);
    }
  }
  &.pink {
    & .title {
      color: var(--cardUser-role-pink);
    }
  }
  &-icon {
    margin-right: 5px;
    width: 23px;
    height: 23px;
    @media (min-width: $breakpoint-tablet) {
      margin-right: 10px;
    }
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-title {
    font-weight: 500;
  }
}