@import "../../../styles/breakpoints";

@mixin loginBlock {
  width: 100%;
  height: 100%;
  padding-top: 100px;
  @media (min-width: $breakpoint-tablet) {
    width: 450px;
    padding-top: 10px;
    height: initial;
  }
  @media (min-width: $breakpoint-laptop) {
    width: 406px;
  }
  @media (min-width: $breakpoint-fullHD) {
    width: 406px;
  }
  &-header {
    text-align: left;
    color: var(--text-inverted);
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 40px;
  }
  &-description {
    text-align: left;
    color: var(--text-inverted-light);
    margin-bottom: 47px;
    font-weight: 400;
    font-size: 16px;
    @media (min-width: $breakpoint-laptop) {
      font-size: 18px;
    }
  }
  &-form {
    .InputText {
      & .element {
        border-color: var(--input-border-inverted);
      }
      & input {
        background-color: var(--input-background-inverted);
        &:not(:disabled) {
          color: var(--text-inverted);
        }

        &:-webkit-autofill {
          -webkit-text-fill-color: var(--text-inverted) !important;
          -webkit-box-shadow: 0 0 0 30px white inset !important;

          &:hover {
            -webkit-box-shadow: 0 0 0 30px white inset !important
          }

          &:focus {
            -webkit-box-shadow: 0 0 0 30px white inset !important
          }

          &:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important
          }
        }
      }
      &-label {
        color: var(--text-inverted);
      }
    }
    &-inputs {
      margin-bottom: 40px;
    }
    &-button {
      & .element {
        width: 100%;
        border-radius: 5px !important;
      }
    }
    margin-bottom: 20px;
  }

  &-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 80px;
    width: 80px;
    @media (min-width: $breakpoint-tablet) {
      height: 80px;
      width: 80px;
    }
    @media (min-width: $breakpoint-laptop) {
      height: 100px;
      width: 100px;
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
}

.LoginBlock {
  @include loginBlock();
  &-options {
    display: flex;
    align-items: center;
    justify-content: center;
    &-item {
      text-align: center;
      cursor: pointer;
      color: var(--text-inverted);
      font-size: 14px;
      font-weight: 300;
    }
    &-divider {
      height: 14px;
      margin-left: 5px;
      margin-right: 5px;
      width: 1px;
      background-color: black;
      opacity: 0.4;
    }
  }
}
