@import "../../../styles/modal.scss";

.AssignZone {
    @include ModalSecondary();
    &-content {
        &-form {
            &-inputs {
                &-selected {
                    margin-top: -10px;
                    display: block;
                    text-align: center;
                    color: var(--text-success);
                    font-size: 14px;
                }
            }
        }
    }
}

