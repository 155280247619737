@import "breakpoints";
@import "input";

@mixin CardTitle {
  color: var(--background-primary-text);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 22px;
  @media (min-width: $breakpoint-tablet) {
    font-size: 21px;
    margin-bottom: 40px;
  }
  @media (min-width: $breakpoint-laptop) {
    font-size: 16px;
    margin-bottom: 41px;
  }
  @media (min-width: $breakpoint-fullHD) {
    font-size: 20px;
    margin-bottom: 50px;
  }
  &.weak {
    font-weight: 400;
  }
}

@mixin Card {
  position: relative;
  user-select: none;
  background-color: var(--background-primary);
  color: var(--background-primary-text);
  -webkit-box-shadow: 0 0 18px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0 18px 0 rgba(0,0,0,0.1);
  min-height: calc(100% - 30px - 48px);
  border-radius: 10px;
  padding: 22px 13px 48px;
  @media (min-width: $breakpoint-tablet) {
    padding: 31px 29px 70px;
  }
  @media (min-width: $breakpoint-laptop) {
    padding: 20px 29px 28px;
    min-height: calc(100% - 50px);
    margin-bottom: 0;
  }
  @media (min-width: $breakpoint-fullHD) {
    padding: 39px 29px 40px;
    min-height: calc(100% - 50px);
  }
  &-functions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    @media (min-width: $breakpoint-fullHD) {
      margin-bottom: 40px;
    }
    &-left {
      &-title {
        @include CardTitle();
      }
    }
    &-right {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      @media (min-width: $breakpoint-laptop) {
        margin-bottom: 0;
        flex-direction: row;
        justify-content: initial;
        width: initial;
      }
      &-item {
        &:not(:last-child) {
          margin-right: 16px;
          @media (min-width: $breakpoint-fullHD) {
            margin-right: 24px;
          }
        }
        &-button {
          & button {
            height: 38px !important;
            @media (min-width: $breakpoint-tablet) {
              height: 53px !important;
            }
            @media (min-width: $breakpoint-laptop) {
              height: 47px !important;
            }
            @media (min-width: $breakpoint-fullHD) {
              height: 57px !important;
            }
            &.primary {
              min-width: 140px !important;
              @media (min-width: $breakpoint-tablet) {
                min-width: 180px !important;
              }
              @media (min-width: $breakpoint-laptop) {
                min-width: fit-content !important;
              }
              @media (min-width: $breakpoint-fullHD) {
                min-width: fit-content !important;
              }
            }
          }
        }
      }
    }
  }
  &-content {
    &-title {
      @include CardTitle();
    }
    &-form {
      &-inputs {
        margin-bottom: 48px;
        @media (min-width: $breakpoint-tablet) {
          margin-bottom: 55px;
        }
        @media (min-width: $breakpoint-laptop) {
          margin-bottom: 44px;
        }
        @media (min-width: $breakpoint-fullHD) {
          margin-bottom: 55px;
        }
        &-flex {
          @include InputFlex();
        }
        &-button {
          display: flex;
          justify-content: center;
          @media (min-width: $breakpoint-tablet) {
            justify-content: flex-start;
          }
          & .Button {
            & .element {
              justify-content: center;
            }
          }
        }
      }
    }
    &.placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
  & .FunctionTable.paginated {
    padding-bottom: 0;
  }
  & .Empty-flat {
    margin-top: 50px;
  }
  & .Pagination {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 30px;
    @media (min-width: $breakpoint-tablet) {
      left: 30px;
      right: 30px;
    }
    @media (min-width: $breakpoint-laptop) {
      bottom: 40px;
    }
    &-left {
      left: 18px;
      @media (min-width: $breakpoint-tablet) {
        left: 29px;
      }
      @media (min-width: $breakpoint-laptop) {
        left: 34px;
      }
      @media (min-width: $breakpoint-fullHD) {
        left: 34px;
      }
    }
  }
}

@mixin CardFluid {
  position: relative;
  background-color: var(--background-primary);
  border-radius: 10px;
  color: var(--background-primary-text);
  -webkit-box-shadow: 0 0 18px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0 18px 0 rgba(0,0,0,0.1);
  min-height: calc(120px - 21px - 23px);
  padding: 19px 13px 30px;
  @media (min-width: $breakpoint-tablet) {
    padding: 21px 29px 30px;
  }
  @media (min-width: $breakpoint-laptop) {
    padding: 21px 29px 22px 34px;
  }
  @media (min-width: $breakpoint-fullHD) {
    padding: 22px 29px 22px 34px;
  }
  &-content {
    &-title {
      @include CardTitle();
      &-left {
        color: var(--background-primary-text);
        font-size: 14px;
        font-weight: 500;
        @media (min-width: $breakpoint-tablet) {
          font-size: 21px;
        }
        @media (min-width: $breakpoint-laptop) {
          font-size: 16px;
        }
        @media (min-width: $breakpoint-fullHD) {
          font-size: 20px;
        }

        &.weak {
          font-weight: 400;
        }
      }
      &-right {
        & .Button {
          & .element {
            height: 38px !important;
            @media (min-width: $breakpoint-tablet) {
              height: 53px !important;
            }
            @media (min-width: $breakpoint-laptop) {
              height: 47px !important;
            }
            @media (min-width: $breakpoint-fullHD) {
              height: 57px !important;
            }
            min-width: fit-content !important;
          }
        }
      }
    }
  }
  & .FunctionTable.paginated {
    padding-bottom: 30px;
  }
  & .Pagination {
    position: absolute;
    left: 0;
    bottom: 60px;
    @media (min-width: $breakpoint-laptop) {
      bottom: 40px;
    }
    right: 0;
    width: 100%;
    &-left {
      left: 29px;
      @media (min-width: $breakpoint-tablet) {
        left: 29px;
      }
      @media (min-width: $breakpoint-laptop) {
        left: 34px;
      }
      @media (min-width: $breakpoint-fullHD) {
        left: 34px;
      }
    }
  }
}
