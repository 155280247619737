@import "breakpoints";

@mixin sensorStates {
  margin-bottom: 10px;
  &-cards {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right:-15px;
    margin-bottom: -25px;
    @media (min-width: $breakpoint-tablet) {
      margin-right:-25px;
    }
    @media (min-width: $breakpoint-laptop) {
      margin-right:-30px;
    }
    @media (min-width: $breakpoint-fullHD) {
      margin-right:-40px;
    }
    &-item {
      width: calc(100% - 30px - 8px);
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 25px;
      @media (min-width: $breakpoint-laptop) {
        width: calc(50% - 30px - 8px);
      }
      @media (min-width: $breakpoint-fullHD) {
        width: calc(33.333% - 30px - 8px);
      }
    }
  }
}