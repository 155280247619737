@import "../../../styles/cardInfo";
@import "../../../styles/letterImage";
@import "../../../styles/breakpoints";

.CardPatient {
  @include CardInfo();
  &-content {
    &-identity {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 38px;
      @media (min-width: $breakpoint-fullHD) {
        margin-bottom: 63px;
      }
      &-left {
        margin-right: 32px;
        & .LetterImage {
          height: 63px;
          width: 63px;
          @media (min-width: $breakpoint-fullHD) {
            height: 98px;
            width: 98px;
          }
        }
      }
      &-right {
        &-name {
          font-weight: 500;
          font-size: 18px;
          color: var(--background-primary-text);
          margin-bottom: 8px;
          line-height: 24px;
          @media (min-width: $breakpoint-tablet) {
            font-size: 21px;
          }
          @media (min-width: $breakpoint-laptop) {
            font-size: 20px;
          }
          @media (min-width: $breakpoint-fullHD) {
            font-size: 30px;
          }
        }
        &-location {
          font-weight: 500;
          font-size: 12px;
          color: var(--text-blue);
          line-height: 16px;
          @media (min-width: $breakpoint-tablet) {
            font-size: 18px;
          }
          @media (min-width: $breakpoint-laptop) {
            font-size: 14px;
          }
          @media (min-width: $breakpoint-fullHD) {
            font-size: 20px;
          }
        }
      }
    }
  }
}
