.Spinner {
  margin: 6px 6px;
  font-size: 2px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  &.warning {
    animation: SpinnerAnimationWarning 1.1s infinite ease;
  }
}

@keyframes SpinnerAnimationWarning {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em var(--spinner-warning-base), 1.8em -1.8em 0 0em
    var(--spinner-warning-20), 2.5em 0em 0 0em
    var(--spinner-warning-20), 1.75em 1.75em 0 0em
    var(--spinner-warning-20), 0em 2.5em 0 0em
    var(--spinner-warning-20), -1.8em 1.8em 0 0em
    var(--spinner-warning-20), -2.6em 0em 0 0em
    var(--spinner-warning-50), -1.8em -1.8em 0 0em
    var(--spinner-warning-70);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em
    var(--spinner-warning-70), 1.8em -1.8em 0 0em var(--spinner-warning-base), 2.5em 0em 0 0em
    var(--spinner-warning-20), 1.75em 1.75em 0 0em
    var(--spinner-warning-20), 0em 2.5em 0 0em
    var(--spinner-warning-20), -1.8em 1.8em 0 0em
    var(--spinner-warning-20), -2.6em 0em 0 0em
    var(--spinner-warning-20), -1.8em -1.8em 0 0em
    var(--spinner-warning-50);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em
    var(--spinner-warning-50), 1.8em -1.8em 0 0em
    var(--spinner-warning-70), 2.5em 0em 0 0em var(--spinner-warning-base), 1.75em 1.75em 0 0em
    var(--spinner-warning-20), 0em 2.5em 0 0em
    var(--spinner-warning-20), -1.8em 1.8em 0 0em
    var(--spinner-warning-20), -2.6em 0em 0 0em
    var(--spinner-warning-20), -1.8em -1.8em 0 0em
    var(--spinner-warning-20);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em
    var(--spinner-warning-20), 1.8em -1.8em 0 0em
    var(--spinner-warning-50), 2.5em 0em 0 0em
    var(--spinner-warning-70), 1.75em 1.75em 0 0em var(--spinner-warning-base), 0em 2.5em 0 0em
    var(--spinner-warning-20), -1.8em 1.8em 0 0em
    var(--spinner-warning-20), -2.6em 0em 0 0em
    var(--spinner-warning-20), -1.8em -1.8em 0 0em
    var(--spinner-warning-20);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em
    var(--spinner-warning-20), 1.8em -1.8em 0 0em
    var(--spinner-warning-20), 2.5em 0em 0 0em
    var(--spinner-warning-50), 1.75em 1.75em 0 0em
    var(--spinner-warning-70), 0em 2.5em 0 0em var(--spinner-warning-base), -1.8em 1.8em 0 0em
    var(--spinner-warning-20), -2.6em 0em 0 0em
    var(--spinner-warning-20), -1.8em -1.8em 0 0em
    var(--spinner-warning-20);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em
    var(--spinner-warning-20), 1.8em -1.8em 0 0em
    var(--spinner-warning-20), 2.5em 0em 0 0em
    var(--spinner-warning-20), 1.75em 1.75em 0 0em
    var(--spinner-warning-20), 0em 2.5em 0 0em
    var(--spinner-warning-70), -1.8em 1.8em 0 0em var(--spinner-warning-base), -2.6em 0em 0 0em
    var(--spinner-warning-20), -1.8em -1.8em 0 0em
    var(--spinner-warning-20);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em
    var(--spinner-warning-20), 1.8em -1.8em 0 0em
    var(--spinner-warning-20), 2.5em 0em 0 0em
    var(--spinner-warning-20), 1.75em 1.75em 0 0em
    var(--spinner-warning-20), 0em 2.5em 0 0em
    var(--spinner-warning-50), -1.8em 1.8em 0 0em
    var(--spinner-warning-70), -2.6em 0em 0 0em var(--spinner-warning-base), -1.8em -1.8em 0 0em
    var(--spinner-warning-20);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em
    var(--spinner-warning-20), 1.8em -1.8em 0 0em
    var(--spinner-warning-20), 2.5em 0em 0 0em
    var(--spinner-warning-20), 1.75em 1.75em 0 0em
    var(--spinner-warning-20), 0em 2.5em 0 0em
    var(--spinner-warning-20), -1.8em 1.8em 0 0em
    var(--spinner-warning-50), -2.6em 0em 0 0em
    var(--spinner-warning-70), -1.8em -1.8em 0 0em var(--spinner-warning-base);
  }
}
