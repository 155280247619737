@import "../../../styles/cards";
@import "../../../styles/breakpoints";

.BulkList {
  @include Card();
  position: relative;
  min-height: fit-content;
  padding-bottom: 85px;
  @media (min-width: $breakpoint-tablet) {
    min-height: fit-content;
    padding-bottom: 140px;
  }
  @media (min-width: $breakpoint-laptop) {
    min-height: 525px;
    padding-bottom: 55px;
  }
  @media (min-width: $breakpoint-fullHD) {
    min-height: 523px;
    padding-bottom: 130px;
  }

  & .Pagination {
    position: initial;
    margin-top: 20px;
    @media (min-width: $breakpoint-tablet) {
      margin-top: 40px;
    }
    @media (min-width: $breakpoint-laptop) {
      margin-top: 20px;
    }
    @media (min-width: $breakpoint-fullHD) {
      margin-top: 30px;
    }
  }
  &-functions {
    &-left {
      &-title {
        margin-bottom: 0;
      }
    }
    &-right {
      &-add {
        display: none;
      }
    }
  }
  &-content {
    &-action {
      position: absolute;
      bottom: 20px;
      width: 100%;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      @media (min-width: $breakpoint-tablet) {
        bottom: 40px;
      }
      @media (min-width: $breakpoint-laptop) {
        bottom: 20px;
      }
      @media (min-width: $breakpoint-fullHD) {
        bottom: 40px;
      }
    }
    &-list {
      &-items {
        display: flex;
        flex-wrap: wrap;
        margin: -5px -5px;
        @media (min-width: $breakpoint-tablet) {
          margin: -10px -10px;
        }
        @media (min-width: $breakpoint-laptop) {
          margin: -5px -5px;
        }
        @media (min-width: $breakpoint-fullHD) {
          margin: -10px -10px;
        }
        &-item {
          width: 94px;
          margin: 5px 5px;
          cursor: pointer;
          @media (min-width: $breakpoint-tablet) {
            width: calc(174px - 20px);
            margin: 10px 10px;
          }
          @media (min-width: $breakpoint-laptop) {
            width: calc(25% - 10px);
            margin: 5px 5px;
          }
          @media (min-width: $breakpoint-fullHD) {
            width: calc(25% - 20px);
            margin: 10px 10px;
          }
          &-image {
            background-color: var(--background-green-dark);
            border-radius: 15px;
            position: relative;
            margin-bottom: 15px;
            height: 87px;
            @media (min-width: $breakpoint-tablet) {
              height: 142px;
            }
            @media (min-width: $breakpoint-laptop) {
              padding-top: 100%;
              height: initial;
            }
            @media (min-width: 2222px) {
              height: 180px;
              padding-top: initial;
            }
            &-inside {
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              &-icon {
                & img {
                  height: 46px;
                  width: 39px;
                  @media (min-width: $breakpoint-tablet) {
                    height: 72px;
                    width: 87px;
                  }
                  @media (min-width: $breakpoint-laptop) {
                    height: 42px;
                    width: 38px;
                  }
                  @media (min-width: $breakpoint-fullHD) {
                    height: 67px;
                    width: 56px;
                  }
                }
              }
              &-remove {
                position: absolute;
                top: 5px;
                right: 5px;
                border: solid 1px rgba(255, 255, 255, 0.2);
                background-color: rgba(0, 0, 0, 0.2);
                width: 15px;
                height: 15px;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                @media (min-width: $breakpoint-tablet) {
                  top: 10px;
                  right: 10px;
                  width: 34px;
                  height: 34px;
                }
                @media (min-width: $breakpoint-laptop) {
                  top: 5px;
                  right: 5px;
                  width: 24px;
                  height: 24px;
                }
                @media (min-width: $breakpoint-fullHD) {
                  top: 10px;
                  right: 10px;
                  width: 34px;
                  height: 34px;
                }
                &.single {
                  display: none;
                  @media (min-width: $breakpoint-laptop) {
                    display: flex;
                  }
                }
                &-icon {
                  height: 11px;
                  width: 11px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  @media (min-width: $breakpoint-tablet) {
                    height: 21px;
                    width: 21px;
                  }
                  @media (min-width: $breakpoint-laptop) {
                    height: 11px;
                    width: 11px;
                  }
                  @media (min-width: $breakpoint-fullHD) {
                    height: 21px;
                    width: 21px;
                  }
                  & img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
          &-title {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: var(--text-success);
            @media (min-width: $breakpoint-tablet) {
              font-size: 18px;
            }
            @media (min-width: $breakpoint-laptop) {
              font-size: 12px;
            }
            @media (min-width: $breakpoint-fullHD) {
              font-size: 15px;
            }
          }
        }
      }
    }
    &-title {
      margin-bottom: 30px;
    }
  }
}
