@import "../../../styles/breakpoints";

@keyframes OrderingTriangleFade {
  0% { transform: scale(1); }
  100% { transform: scale(1.8); }
}

.Table {
  width: 100%;
  border-collapse: separate;
  table-layout: fixed;
  @media (min-width: $breakpoint-laptop) {
    border-collapse: collapse;
  }
  &-head {
    text-align: left;
    display: none;
    @media (min-width: $breakpoint-laptop) {
      display: table-header-group;
    }
    &-row {
      height: 32px;
      @media (min-width: $breakpoint-fullHD) {
        height: 44px;
      }
      &-column {
        color: var(--table-head-text);
        font-weight: 500;
        font-size: 12px;
        @media (min-width: $breakpoint-fullHD) {
          font-size: 16px;
        }
        &.action {
          min-width: 100px;
        }
        &.state {
          min-width: 80px;
          text-align: left;
          padding-left: 25px;
          @media (min-width: $breakpoint-fullHD) {
            min-width: 140px;
            padding-left: 50px;
          }
        }
        &-inside {
          display: flex;
          &-order {
            margin-left: 10px;
            &-item {
              &.active {
                & .triangle {
                  filter: brightness(10);
                }
              }
              &-triangle {
                opacity: 1;
                cursor: pointer;
                &:hover {
                  filter: brightness(1.2);
                  animation: OrderingTriangleFade 400ms ease-in-out;
                  transform: scale(1.8);
                }
                &.up {
                  width: 0;
                  height: 0;
                  border-left: 4px solid transparent;
                  border-right: 4px solid transparent;
                  border-bottom: 4px solid var(--table-order-bkg);
                  margin-bottom: 4px;
                }
                &.down {
                  width: 0;
                  height: 0;
                  border-left: 4px solid transparent;
                  border-right: 4px solid transparent;
                  border-top: 4px solid var(--table-order-bkg);
                }
              }
            }
          }
        }
      }
    }
  }
  &-body {
    text-align: left;
  }
  & tbody {
    background-color: var(--table-background);
    border: 1px solid var(--table-divider);
    margin-bottom: 10px;
    display: block;
    border-radius: 6px;
    padding-left: 20px;
    padding-right: 16px;
    @media (min-width: $breakpoint-laptop) {
      display: table-row-group;
      border: initial;
      background-color: initial;
      padding-left: initial;
      padding-right: initial;
    }
    &.opened {
      & .TableColumn-title {
        border-bottom: 1px solid var(--table-divider);
        @media (min-width: $breakpoint-laptop) {
          border-bottom: none;
        }
      }
    }
  }
}
