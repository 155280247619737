@import "breakpoints";

@keyframes ModalOpenAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin ModalBase {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  animation: ModalOpenAnimation 0.3s ease-in-out;
  backdrop-filter: blur(6px) brightness(0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  &-content {
    overflow: auto;
    &-form {
      &-inputs {
        margin-bottom: 40px;
        &-flex {
          @media (min-width: $breakpoint-tablet) {
            display: flex;
            justify-content: space-between;
          }
          & .InputText, & .CustomSelect, & .CustomDate {
            @media (min-width: $breakpoint-tablet) {
              width: calc(50% - 15px);
            }
            &:not(:last-child) {
              @media (min-width: $breakpoint-tablet) {
                margin-right: 30px;
              }
            }
          }
        }
      }
    }
  }
}

@mixin ModalSecondary {
  @include ModalBase();
  &-content {
    z-index: 1000;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--background-primary);
    @media (min-width: $breakpoint-laptop) {
      left: auto;
      right: auto;
      width: 572px;
      min-height: 600px;
      height: fit-content;
      top: 10%;
      border-radius: 10px;
      margin: 0 auto;
    }
    &-form {
      margin-bottom: 48px;
      @media (min-width: $breakpoint-tablet) {
        margin-bottom: 55px;
      }
      @media (min-width: $breakpoint-laptop) {
        margin-bottom: 44px;
      }
      @media (min-width: $breakpoint-laptop) {
        margin-bottom: 55px;
      }
      &-inputs {
        padding-left: 35px;
        padding-right: 35px;
        margin-bottom: 48px;
        @media (min-width: $breakpoint-tablet) {
          padding-left: 35px;
          padding-right: 35px;
          margin-bottom: 55px;
        }
        @media (min-width: $breakpoint-laptop) {
          padding-left: 28px;
          padding-right: 28px;
          margin-bottom: 44px;
        }
        @media (min-width: $breakpoint-fullHD) {
          padding-left: 35px;
          padding-right: 35px;
          margin-bottom: 55px;
        }
        &-button {
          display: flex;
          justify-content: center;
          & .Button {
            & .element {
              justify-content: center;
              font-weight: 500;
              font-size: 14px;
              @media (min-width: $breakpoint-tablet) {
                font-size: 14px;
              }
              @media (min-width: $breakpoint-laptop) {
                font-size: 12px;
              }
              @media (min-width: $breakpoint-fullHD) {
                font-size: 16px;
              }
            }
          }
        }
        &-hint {
          margin: -20px auto 42px;
          color: var(--text-success);
          font-size: 12px;
          max-width: 60%;
          text-align: center;
          & a {
            color: var(--text-success);
            font-weight: bold;
          }
        }
      }
    }
    &-title {
      background-color: var(--background-ternary);
      width: calc(100% - 70px);
      height: 60px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 35px;
      padding-right: 35px;
      color: var(--background-primary-text);
      font-size: 18px;
      text-align: center;
      margin-bottom: 33px;
      @media (min-width: $breakpoint-laptop) {
        font-size: 16px;
      }
      @media (min-width: $breakpoint-fullHD) {
        font-size: 18px;
      }
      &-close {
        cursor: pointer;
        display: flex;
        align-items: center;
        & img {
          width: 18px;
          height: 18px;
          @media (min-width: $breakpoint-laptop) {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }
}