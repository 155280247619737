@import "../../../styles/cards";

.ListAlerts {
  @include Card();
  position: relative;
  &-table {
    margin-bottom: 50px;
  }
  & .FunctionTable {
    & .Table-head-row-column {
      // State
      &:nth-of-type(1) {
        width: 5%;
        @media (min-width: $breakpoint-fullHD) {
          width: 5%;
        }
      }
      // Id
      &:nth-of-type(2) {
        width: 6%;
        @media (min-width: $breakpoint-fullHD) {
          width: 5%;
        }
      }
      // Time
      &:nth-of-type(3) {
        width: 10%;
        @media (min-width: $breakpoint-fullHD) {
          width: 9%;
        }
      }
      // Template
      &:nth-of-type(4) {
        width: 14%;
        @media (min-width: $breakpoint-fullHD) {
          width: 16%;
        }
      }
      // Duration
      &:nth-of-type(5) {
        width: 12%;
        @media (min-width: $breakpoint-fullHD) {
          width: 12%;
        }
      }
      // Limit
      &:nth-of-type(6) {
        width: 12%;
        @media (min-width: $breakpoint-fullHD) {
          width: 14%;
        }
      }
      // Extreme
      &:nth-of-type(7) {
        width: 12%;
        @media (min-width: $breakpoint-fullHD) {
          width: 14%;
        }
      }
      // Sensor
      &:nth-of-type(8) {
        width: 12%;
        @media (min-width: $breakpoint-fullHD) {
          width: 14%;
        }
      }
    }
  }
}
