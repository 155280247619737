@import "cards";
@import "breakpoints";
@import "scrollbar";

@mixin Building {
  @include Card();
  position: relative;
  @media (max-width: $breakpoint-laptop - 1) {
    min-height: initial;
  }
  @media (min-width: $breakpoint-fullHD) {
    min-height: calc(100% - 79px) !important;
  }
  &-content {
    &-fullScreen {
      margin-top: 31px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      @media (min-width: $breakpoint-tablet) {
        display: none;
      }
      &-inside {
        display: flex;
        justify-content: center;
        align-items: center;
        &-icon {
          margin-right: 16px;
        }
        &-text {
          font-size: 16px;
          text-decoration: underline;
        }
      }
    }
    &-menu {
      display: flex;
      margin-bottom: 33px;
      justify-content: space-between;
      @media (min-width: $breakpoint-laptop) {
        margin-bottom: 42px;
      }
      &-section {
        display: flex;
        &:nth-child(1) {
          justify-content: flex-start;
        }
        &:nth-child(2) {
          justify-content: center;
        }
        &:nth-child(3) {
          justify-content: flex-end;
          display: none;
          @media (min-width: $breakpoint-tablet) {
            display: flex;
          }
        }
        &-floor {
          display: flex;
          align-items: center;
          & .EasyFilter-mobile-main {
            width: 94px;
            height: 47px;
            @media (min-width: $breakpoint-tablet) {
              width: 164px;
            }
            @media (min-width: $breakpoint-laptop) {
              width: 130px;
            }
            @media (min-width: $breakpoint-fullHD) {
              width: 178px;
            }
          }
          & .EasyFilter-mobile-content {
            width: 94px;
            @media (min-width: $breakpoint-tablet) {
              width: 164px;
            }
            @media (min-width: $breakpoint-laptop) {
              width: 130px;
            }
            @media (min-width: $breakpoint-fullHD) {
              width: 178px;
            }
          }
        }
        &-zoom {
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #202a39;
          border: 2px solid #4a596e;
          border-radius: 15px;
          padding-left: 15px;
          padding-right: 15px;
          width: 106px;
          height: 47px;
          @media (min-width: $breakpoint-laptop) {
            padding: 15px;
            height: 16px;
            width: initial;
          }
          &-value {
            font-size: 12px;
            @media (min-width: $breakpoint-tablet) {
              font-size: 14px;
            }
            @media (min-width: $breakpoint-laptop) {
              font-size: 14px;
            }
            @media (min-width: $breakpoint-fullHD) {
              font-size: 18px;
            }
          }

          &-control {
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 100%;

            &:first-child {
              margin-right: 17px;
            }

            &:last-child {
              margin-left: 17px;
            }
          }
        }
        &-controls {
          display: flex;
          align-items: center;

          &-item {
            cursor: pointer;

            &:not(:last-child) {
              margin-right: 18px;
            }

            & .Button {
              & .element {
                min-width: 145px;
                height: 50px;
                justify-content: center;
                @media (min-width: $breakpoint-fullHD) {
                  height: 57px;
                }
              }
            }


            &-delete {
              width: 48px;
              height: 48px;
              border-radius: 10px;
              border: 1px solid #4a596e;
              background-color: #202a39;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              @media (min-width: $breakpoint-fullHD) {
                width: 55px;
                height: 55px;
              }

              & img {
                width: 16px;
                height: 20px;
                @media (min-width: $breakpoint-fullHD) {
                  width: 18px;
                  height: 22px;
                }
              }

              & .Dropdown {
                top: 50px;
                left: calc(-100% + (-4px));
                @media (min-width: $breakpoint-fullHD) {
                  left: calc(-100% + (-6px));
                }
              }
            }

            &-save {
              width: 34px;
              height: 34px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #202a39;
              border-radius: 12px;
              border: 2px solid #4a596e;
              @media (min-width: $breakpoint-tablet) {
                width: 49px;
                height: 49px;
              }
              @media (min-width: $breakpoint-laptop) {
                width: 48px;
                height: 46px;
              }
              @media (min-width: $breakpoint-fullHD) {
                width: 55px;
                height: 53px;
                border-radius: 14px;
              }

              & img {
                width: 20px;
                height: 20px;
                @media (min-width: $breakpoint-fullHD) {
                  width: 22px;
                  height: 22px;
                }
              }
            }
          }
        }
        &-floor {
          & .FilterMain-main {
            height: 46px;
            @media (min-width: $breakpoint-fullHD) {
              height: 53px;
            }
          }
        }
      }
    }
  }
}
